import React from 'react'
import styles from './FullPageSpinner.module.css'

interface IProps {
  message?: string
}

export const FullPageSpinner: React.FC<IProps> = ({message = 'Loading...'}) => {
  return <div className={styles.container}>{message}</div>
}
