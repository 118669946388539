import React from 'react'
import {LinkButton} from '../../../../components/buttons/LinkButton'
import {IBaseComponentProps} from '../../../../types/questionTypes/BaseQuestionProps'
import {textActions} from '../store/textActions'

export const AddTextRowButton: React.FC<IBaseComponentProps> = ({
  questionId,
}) => {
  const addRow = textActions.useAddRow()
  const handleAddRow = (): void => {
    addRow(questionId)
  }

  return (
    <div>
      <LinkButton onClick={handleAddRow}>Add Text Box</LinkButton>
    </div>
  )
}
