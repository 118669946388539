import React from 'react'
import {IBaseComponentProps} from '../../../../../types/questionTypes/BaseQuestionProps'
import {SmileyActions} from '../../store/smileyActions'
import {SmileySelectors} from '../../store/smileySelector'
import {SmileyPointScaleEnum} from 'src/survey-type-defs'
import {NumericInput} from '../../../../../components/input/NumericInput'

export const SmileyPointScaleSetting: React.FC<IBaseComponentProps> = ({
  questionId,
}) => {
  const pointScale = SmileySelectors.usePointScale(questionId)
  const changePointScaleAction = SmileyActions.useChangePointScaleAction()

  const handleChangePointScale = (newPointScale: number): void => {
    const isInvalidValue =
      newPointScale < SmileyPointScaleEnum.MIN ||
      newPointScale > SmileyPointScaleEnum.MAX
    if (isInvalidValue) return

    changePointScaleAction(questionId, newPointScale)
  }

  return (
    <div>
      <label htmlFor="smiley-points-scale">Point Scale</label>
      <NumericInput
        id="smiley-points-scale"
        value={pointScale}
        minValue={SmileyPointScaleEnum.MIN}
        maxValue={SmileyPointScaleEnum.MAX}
        onChange={handleChangePointScale}
      />
    </div>
  )
}
