import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {matrixSelectors} from '../store/matrixSelectors'
import {MatrixDropdownOption} from './MatrixDropdownOption'

interface IProps {
  questionId: IUid
}

export const MatrixMultiSelectDropdownAnswerList: React.FC<IProps> = ({
  questionId,
}) => {
  const columnOrder = matrixSelectors.useColumnOrder(questionId)

  return (
    <td>
      <select multiple={true} data-testid="MatrixMultiSelectList">
        <option value="-1">--select--</option>
        {columnOrder.map(columnId => (
          <MatrixDropdownOption
            questionId={questionId}
            columnId={columnId}
            key={columnId}
          />
        ))}
      </select>
    </td>
  )
}
