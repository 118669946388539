import {useMyAppStore} from '../../providers/AppStoreProvider'
import {IUiStore} from './uiSlice'

export const useUiSliceSelector = <ReturnType,>(
  selector: (uiState: IUiStore) => ReturnType,
): ReturnType => {
  return useMyAppStore()(state => {
    return selector(state)
  })
}
