import {ChoiceQuestion} from '../ChoiceQuestion'
import {IQuestionConfig} from '../../../../types/questionTypes/questionConfig'
import {
  AnswerDisplayOrderEnum,
  ChoiceAnswerTextPositionEnum,
  ChoiceQuestionLayoutEnum,
  ChoiceSubtypeEnum,
  ChoiceVerticalColumnDisplayEnum,
  IAnswer,
  IChoiceAnswer,
  IChoiceQuestion,
  QuestionDisplayEnum,
} from 'src/survey-type-defs'
import {IUid} from 'src/survey-type-defs'
import {uuid} from '../../../../utils/uuidUtil'
import {QuestionTypeEnum} from 'src/survey-type-defs'
import {defaultQuestionConfig} from '../../../../types/questionTypes/common/defaultQuestionConfig'
import {AlternateColors} from '../../../alternateColors/AlternateColors'
import {AnswerDisplayOrder} from '../../../answerDisplayOrder/AnswerDisplayOrder'
import {QuestionDisplay} from '../../../questionDisplay/QuestionDisplay'
import {QuestionTip} from '../../../questionTip/QuestionTip'
import {VideoSetting} from '../../../videoSetting/VideoSetting'
import {ChoiceLayout} from '../settings/components/ChoiceLayout'
import {ChoiceSubTypes} from '../settings/components/ChoiceSubTypes'
import {sentence, short_description} from 'casual-browserify'

export const choiceQuestionConfig: IQuestionConfig<IChoiceQuestion> = {
  ...defaultQuestionConfig,
  dynamicCommentLabelsEnabled: true,
  component: ChoiceQuestion,
  settingComponents: [
    ChoiceSubTypes,
    ChoiceLayout,
    AnswerDisplayOrder,
    AlternateColors,
    QuestionDisplay,
    QuestionTip,
    VideoSetting,
  ],
  factory: (blockId: IUid, orderNum: string, sequenceNum: number) =>
    createChoiceQuestion(blockId, orderNum, sequenceNum),
} as const

const createChoiceQuestion = (
  blockId: string,
  orderNum: string,
  sequenceNum: number,
): IChoiceQuestion => {
  const answers = []
  for (let i = 0; i < 10; i++) {
    answers.push({
      id: uuid(),
      text: `${i + 1} ${sentence}`,
      isDeleted: false,
    })
  }

  const answersMap: Record<IUid, IChoiceAnswer> = {}
  const defaultAnswersMap: Record<IUid, boolean> = {}
  const exclusiveAnswersMap: Record<IUid, boolean> = {}
  const dynamicCommentsMap: Record<IUid, string> = {}
  answers.forEach(a => {
    answersMap[a.id] = a
    defaultAnswersMap[a.id] = false
    exclusiveAnswersMap[a.id] = false
    dynamicCommentsMap[a.id] = ''
  })

  return {
    id: uuid(),
    blockId,
    text: `${sequenceNum} ${short_description}`,
    code: `Q${orderNum}`,
    type: QuestionTypeEnum.CHOICE,
    orderNum,
    answers: answersMap,
    defaultAnswers: defaultAnswersMap,
    exclusiveAnswers: exclusiveAnswersMap,
    dynamicCommentLabels: dynamicCommentsMap,
    answerOrder: answers.map(a => a.id),
    deleted: false,
    answerDisplayOrder: {
      type: AnswerDisplayOrderEnum.DEFAULT,
      randomAnswerCount: 1,
    },
    alternateColors: {enabled: false},
    questionDisplay: {type: QuestionDisplayEnum.SHOW_QUESTION},
    subtype: ChoiceSubtypeEnum.RADIO,
    layout: {
      type: ChoiceQuestionLayoutEnum.VERTICAL,
      verticalColumnCount: 1,
      verticalColumnDisplayType: ChoiceVerticalColumnDisplayEnum.ACROSS,
      answerTextPosition: ChoiceAnswerTextPositionEnum.RIGHT_DEFAULT,
    },
  }
}
