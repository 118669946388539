import {IAddQuestionServerPayload} from '../../socket/types/IAddQuestionServerPayload'
import {IDeleteQuestionServerPayload} from '../../socket/types/IDeleteQuestionServerPayload'
import {IReorderQuestionServerPayload} from '../../socket/types/IReorderQuestionServerPayload'
import {IServerUpdate} from '../../socket/types/IServerUpdate'
import {IServerUpdateProcessor} from '../../socket/types/IServerUpdateProcessor'
import {IUseAppStore} from '../../types/store/UseAppStore'
import {IUid} from 'src/survey-type-defs'
import {loggerUtil} from '../../test/utils/loggerUtil'

class SharedSliceServerUpdateProcessor implements IServerUpdateProcessor {
  readonly unAckServerUpdates: Record<
    IUid,
    | IAddQuestionServerPayload
    | IReorderQuestionServerPayload
    | IDeleteQuestionServerPayload
  > = {}

  processServerMessages(message: IServerUpdate, appStore: IUseAppStore): void {
    loggerUtil.logOnlyDev('shared slice updates received over socket', message)
    const sharedActions = appStore.getState().actions.shared
    if (message.type === 'ADD_QUESTION_TO_BLOCK') {
      const payload = message.payload as IAddQuestionServerPayload
      sharedActions.addQuestionServerUpdate(payload)
    }

    if (message.type === 'REORDER_QUESTION_IN_BLOCK') {
      const payload = message.payload as IReorderQuestionServerPayload
      sharedActions.reorderQuestionServerUpdate(payload)
    }

    if (message.type === 'DELETE_QUESTION') {
      const payload = message.payload as IDeleteQuestionServerPayload
      sharedActions.deleteQuestionServerUpdate(payload)
    }
  }

  hasConflicts(
    unAckMessage: IServerUpdate,
    messageFromServer: IServerUpdate,
  ): boolean {
    loggerUtil.logOnlyDev('checking for conflicts')

    //TODO: check how to handle conflicts while making changes to block slice

    return false
  }
}

export const sharedSliceServerUpdateProcessor =
  new SharedSliceServerUpdateProcessor()
