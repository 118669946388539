import {rest} from 'msw'
import {BACKEND_URL} from '../../../constants/appConstants'

export const mswSurveyHandlers = [
  rest.get(`${BACKEND_URL}/api/survey/:surveyId`, async (req, res, ctx) => {
    const dummySurvey = {
      id: 1,
      name: 'Dummy Survey',
    }
    const response = {
      data: dummySurvey,
    }
    return res(ctx.status(200), ctx.json(response))
  }),

  /*  rest.post<Dashboard>(`${API_URL}/api/dashboards`, async (req, res, ctx) => {
    const {name} = req.body
    const response: ApiResponse<Dashboard> = {
      data: {
        id: 1,
        name,
        creationDate: new Date(),
      },
    }
    return res(ctx.status(204), ctx.json(response))
  }), */
]
