import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {choiceSelectors} from '../store/choiceSelectors'

interface IProps {
  questionId: IUid
  answerId: IUid
}

export const AnswerDropDownOption: React.FC<IProps> = ({
  questionId,
  answerId,
}) => {
  const answerText = choiceSelectors.useAnswerText(questionId, answerId)

  return <option value={answerText}>{answerText}</option>
}
