import React from 'react'

import {IUid} from 'src/survey-type-defs'
import {TextInput} from '../../../components/input/TextInput'
import {questionTipActions} from '../store/questionTipActions'
import {questionTipSelectors} from '../store/questionTipSelectors'

interface IProps {
  questionId: IUid
}
export const QuestionTipHeader: React.FC<IProps> = ({questionId}) => {
  const selectedTipHeader =
    questionTipSelectors.useQuestionTipHeader(questionId)

  const updateQuestionTipHeader = questionTipActions.useUpdateHeader()
  const handleOnQuestionTipHeaderChange = (header: string): void => {
    updateQuestionTipHeader(questionId, {
      header: header,
    })
  }
  return (
    <TextInput
      value={selectedTipHeader}
      placeholder="Header"
      onChange={handleOnQuestionTipHeaderChange}
    />
  )
}
