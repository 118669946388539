import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {SmileySelectors} from '../store/smileySelector'
import {SmileyAnswer} from './SmileyAnswer'
import {ISmileyAnswer} from 'src/survey-type-defs'
import {SmileyAnswerOrderEnum} from 'src/survey-type-defs'

interface IProps {
  questionId: IUid
}

const useAnswersSorted = (questionId: IUid): ISmileyAnswer[] => {
  const answerOrder = SmileySelectors.useSelectAnswerOrder(questionId)
  const answers = SmileySelectors.useSelectAnswers(questionId)
  const answersSorted = React.useMemo(() => {
    const answersSorted = [...answers]
    if (SmileyAnswerOrderEnum.POSITIVE_TO_NEGATIVE === answerOrder) {
      answersSorted.sort((a, z) => z.scale - a.scale)
    } else {
      answersSorted.sort((a, z) => a.scale - z.scale)
    }

    return answersSorted
  }, [answers, answerOrder])
  return answersSorted
}

export const SmileyAnswerList: React.FC<IProps> = ({questionId}) => {
  const answersSorted = useAnswersSorted(questionId)

  return (
    <div>
      {answersSorted.map(answer => (
        <SmileyAnswer
          key={answer.id}
          questionId={questionId}
          answerId={answer.id}
        />
      ))}
    </div>
  )
}
