import {IUid} from 'src/survey-type-defs'
import {WysiwygTextInput} from '../../../../components/input/wysiwygTextInput/WysiwygTextInput'
import {IBaseComponentProps} from '../../../../types/questionTypes/BaseQuestionProps'
import {matrixActions} from '../store/matrixActions'
import {matrixSelectors} from '../store/matrixSelectors'

interface IProps extends IBaseComponentProps {
  columnId: IUid
}

export const MatrixColumnHeader: React.FC<IProps> = ({
  questionId,
  columnId,
}) => {
  const columnText = matrixSelectors.useColumnText(questionId, columnId)
  const updateColumnText = matrixActions.useUpdateColumnText()

  const handleUpdateColumnText = (newText: string): void => {
    updateColumnText(questionId, {columnId, text: newText})
  }

  return (
    <td>
      <WysiwygTextInput text={columnText} onChange={handleUpdateColumnText} />
    </td>
  )
}
