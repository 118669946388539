import {IBlockUpdatePayload} from './../../types/serverUpdates/block/BlockUpdatePayload'
import {IServerUpdateProcessor} from '../../socket/types/IServerUpdateProcessor'
import {IServerUpdate} from '../../socket/types/IServerUpdate'
import {IUid} from 'src/survey-type-defs'
import {IBlockServerUpdate} from '../../types/serverUpdates/block/QuestionServerUpdate'
import {IUseAppStore} from '../../types/store/UseAppStore'
import {loggerUtil} from '../../test/utils/loggerUtil'

class BlockServerUpdateProcessor implements IServerUpdateProcessor {
  readonly unAckServerUpdates: Record<IUid, IBlockServerUpdate> = {}

  processServerMessages(message: IServerUpdate, appStore: IUseAppStore): void {
    loggerUtil.logOnlyDev('block updates received over socket', message)
    //useApplyServerBlockPath(message.payload.blockId, message.payload.update)
    const blockUpdatePayload = message.payload as IBlockUpdatePayload
    appStore
      .getState()
      .blocksActions.applyServerUpdates(
        blockUpdatePayload.blockId,
        blockUpdatePayload.update,
      )
  }

  hasConflicts(
    unAckMessage: IServerUpdate,
    messageFromServer: IServerUpdate,
  ): boolean {
    loggerUtil.logOnlyDev('checking for conflicts')
    const unAckPayload = unAckMessage.payload as IBlockUpdatePayload
    const serverPayload = messageFromServer.payload as IBlockUpdatePayload
    const isSameBlock = unAckPayload.blockId === serverPayload.blockId

    if (!isSameBlock) return false

    for (const receivedPayloadProperty in serverPayload.update) {
      if (unAckPayload.update?.hasOwnProperty(receivedPayloadProperty)) {
        // this property is already updated, this is conflicting update
        loggerUtil.logOnlyDev(
          'Conflicting updates',
          serverPayload,
          unAckMessage,
          receivedPayloadProperty,
        )
        return true
      }
    }

    return false
  }
}

export const blockServerUpdatesProcessor = new BlockServerUpdateProcessor()
