import {ImageQuestion} from '../ImageQuestion'
import {IQuestionConfig} from '../../../../types/questionTypes/questionConfig'
import {
  AnswerDisplayOrderEnum,
  IImageQuestion,
  ImageSizeEnum,
  ImageSubtypeEnum,
  IUid,
  QuestionDisplayEnum,
} from 'src/survey-type-defs'
import {uuid} from '../../../../utils/uuidUtil'
import {QuestionTypeEnum} from 'src/survey-type-defs'
import {defaultQuestionConfig} from '../../../../types/questionTypes/common/defaultQuestionConfig'
import {AnswerDisplayOrder} from '../../../answerDisplayOrder/AnswerDisplayOrder'
import {QuestionDisplay} from '../../../questionDisplay/QuestionDisplay'
import {QuestionTip} from '../../../questionTip/QuestionTip'
import {VideoSetting} from '../../../videoSetting/VideoSetting'
import {ImageSubTypes} from '../components/ImageSubTypes'
import {ImageLayout} from '../components/ImageLayout'
import {ImageSize} from '../components/ImageSize'

export const imageQuestionConfig: IQuestionConfig<IImageQuestion> = {
  ...defaultQuestionConfig,
  dynamicCommentLabelsEnabled: true,
  component: ImageQuestion,
  settingComponents: [
    ImageSubTypes,
    ImageSize,
    ImageLayout,
    AnswerDisplayOrder,
    QuestionDisplay,
    QuestionTip,
    VideoSetting,
  ],
  factory: (blockId: IUid, orderNum: string, sequenceNum: number) =>
    createImageQuestion(blockId, orderNum, sequenceNum),
} as const

const createImageQuestion = (
  blockId: string,
  orderNum: string,
  sequenceNum: number,
): IImageQuestion => {
  const newAnswer1 = {
    id: uuid(),
    text: 'Option 1',
    isDeleted: false,
  }
  const newAnswer2 = {
    id: uuid(),
    text: 'Option 2',
    isDeleted: false,
  }

  return {
    id: uuid(),
    blockId,
    text: `Question ${sequenceNum}`,
    code: `Q${orderNum}`,
    type: QuestionTypeEnum.IMAGE,
    orderNum,
    answers: {[newAnswer1.id]: newAnswer1, [newAnswer2.id]: newAnswer2},
    defaultAnswers: {[newAnswer1.id]: false, [newAnswer2.id]: false},
    exclusiveAnswers: {[newAnswer1.id]: false, [newAnswer2.id]: false},
    dynamicCommentLabels: {[newAnswer1.id]: '', [newAnswer2.id]: ''},
    answerOrder: [newAnswer1.id, newAnswer2.id],
    deleted: false,
    imageSize: {
      type: ImageSizeEnum.AUTO_SIZE_TO_WINDOW,
    },
    answerDisplayOrder: {
      type: AnswerDisplayOrderEnum.DEFAULT,
      randomAnswerCount: 1,
    },
    questionDisplay: {type: QuestionDisplayEnum.SHOW_QUESTION},
    subtype: ImageSubtypeEnum.RADIO,
    layout: 2,
  }
}
