import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import {App} from './App'
import reportWebVitals from './reportWebVitals'
import {AppProviders} from './providers/AppProviders'
import {devServer} from './test/server/devServer'

// Start the mocking conditionally.
if (process.env.NODE_ENV === 'development') {
  devServer.start({onUnhandledRequest: 'bypass'})
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
