import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {choiceActions} from '../../store/choiceActions'
import {choiceSelectors} from '../../store/choiceSelectors'
import {ChoiceQuestionLayoutEnum} from 'src/survey-type-defs'
import {ChoiceVerticalColumnDisplayType} from './ChoiceVerticalColumnDisplayType'
import {SettingSectionWithoutHeader} from '../../../../../components/questionSettingSection/SettingSectionWithoutHeader'
import {Label} from '../../../../../components/buttons/label/Label'

interface IProps {
  questionId: IUid
  choiceQuestionLayoutType: ChoiceQuestionLayoutEnum
}

export const ChoiceVerticalLayout: React.FC<IProps> = ({
  questionId,
  choiceQuestionLayoutType,
}) => {
  const verticalColumnCount = choiceSelectors.useVerticalColumnCount(questionId)
  const answerCount = choiceSelectors.useAnswerCount(questionId)
  const updateVerticalColumnCount = choiceActions.useUpdateVerticalColumCount()

  const handleIncrementColumnCount = (): void => {
    updateVerticalColumnCount(questionId, {
      verticalColumnCount: verticalColumnCount + 1,
    })
  }
  const handleDecrementColumnCount = (): void => {
    updateVerticalColumnCount(questionId, {
      verticalColumnCount: verticalColumnCount - 1,
    })
  }
  const canDisplayVerticalLayout =
    choiceQuestionLayoutType === ChoiceQuestionLayoutEnum.VERTICAL
  const canDisableDecrementButton = verticalColumnCount === 1
  const canDisableIncrementButton = verticalColumnCount === answerCount

  return (
    <>
      {canDisplayVerticalLayout && (
        <SettingSectionWithoutHeader>
          <Label text="Columns" />
          <button
            style={{margin: '5px', padding: '2px 6px'}}
            onClick={handleIncrementColumnCount}
            disabled={canDisableIncrementButton}
          >
            +
          </button>
          <label aria-label="Column Count">{verticalColumnCount}</label>
          <button
            style={{margin: '5px', padding: '2px 6px'}}
            onClick={handleDecrementColumnCount}
            disabled={canDisableDecrementButton}
          >
            -
          </button>
          <ChoiceVerticalColumnDisplayType
            questionId={questionId}
            verticalColumnCount={verticalColumnCount}
          />
        </SettingSectionWithoutHeader>
      )}
    </>
  )
}
