import {
  IMatrixColumn,
  IMatrixQuestion,
  IMatrixRow,
  IUid,
  MatrixSubtypeEnum,
  QuestionTypeEnum,
} from 'src/survey-type-defs'
import {defaultQuestionConfig} from '../../../../types/questionTypes/common/defaultQuestionConfig'
import {IQuestionConfig} from '../../../../types/questionTypes/questionConfig'
import {uuid} from '../../../../utils/uuidUtil'
import {MatrixQuestion} from '../MatrixQuestion'
import {MatrixSubtypes} from '../settings/MatrixSubtypes'
import {sentence, short_description} from 'casual-browserify'

export const matrixQuestionConfig: IQuestionConfig<IMatrixQuestion> = {
  ...defaultQuestionConfig,
  dynamicCommentLabelsEnabled: false,
  component: MatrixQuestion,
  settingComponents: [MatrixSubtypes],
  factory: (blockId: IUid, orderNum: string, sequenceNum: number) =>
    createMatrixQuestion(blockId, orderNum, sequenceNum),
} as const

const createMatrixQuestion = (
  blockId: string,
  orderNum: string,
  sequenceNum: number,
): IMatrixQuestion => {
  const rows = []
  for (let i = 0; i < 10; i++) {
    rows.push({
      id: uuid(),
      text: `${i + 1} ${sentence}`,
    })
  }
  const rowsMap: Record<IUid, IMatrixRow> = {}
  rows.forEach(row => (rowsMap[row.id] = row))

  const columns = []
  for (let i = 0; i < 10; i++) {
    columns.push({
      id: uuid(),
      text: `${i + 1} ${sentence}`,
    })
  }
  const columnMap: Record<IUid, IMatrixColumn> = {}
  columns.forEach(column => (columnMap[column.id] = column))

  return {
    id: uuid(),
    blockId,
    text: `${sequenceNum} ${short_description}`,
    code: `Q${orderNum}`,
    type: QuestionTypeEnum.MATRIX,
    subtype: MatrixSubtypeEnum.RADIO,
    orderNum,
    rows: rowsMap,
    rowOrder: rows.map(r => r.id),
    columns: columnMap,
    columnOrder: columns.map(c => c.id),
    deleted: false,
  }
}
