import React from 'react'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {ChoiceCheckboxAnswerList} from './components/ChoiceCheckboxAnswerList'
import {ChoiceDropDownAnswerList} from './components/ChoiceDropDownAnswerList'
import {ChoiceMultiSelectDropDownAnswerList} from './components/ChoiceMultiSelectDropDownAnswerList'
import {ChoiceRadioAnswerList} from './components/ChoiceRadioAnswerList'
import {choiceSelectors} from './store/choiceSelectors'
import {ChoiceSubtypeEnum} from 'src/survey-type-defs'

export const ChoiceQuestion: React.FC<IBaseComponentProps> = ({questionId}) => {
  const answerOrder = choiceSelectors.useAnswerOrder(questionId)
  const choiceSubType = choiceSelectors.useQuestionSubType(questionId)

  switch (choiceSubType) {
    case ChoiceSubtypeEnum.RADIO: {
      return (
        <div>
          <ChoiceRadioAnswerList
            questionId={questionId}
            answerOrder={answerOrder}
          />
        </div>
      )
    }
    case ChoiceSubtypeEnum.CHECKBOX: {
      return (
        <div>
          <ChoiceCheckboxAnswerList
            questionId={questionId}
            answerOrder={answerOrder}
          />
        </div>
      )
    }
    case ChoiceSubtypeEnum.DROPDOWN: {
      return (
        <div>
          <ChoiceDropDownAnswerList
            questionId={questionId}
            answerOrder={answerOrder}
          />
        </div>
      )
    }
    case ChoiceSubtypeEnum.SELECT_LIST: {
      return (
        <div>
          <ChoiceMultiSelectDropDownAnswerList
            questionId={questionId}
            answerOrder={answerOrder}
          />
        </div>
      )
    }
    default: {
      throw new Error('Invalid subtype for choice question')
    }
  }
}
