import {uuid} from './../../utils/uuidUtil'
import {IBlock} from 'src/survey-type-defs'
import {IQuestion} from 'src/survey-type-defs'
import {IUid} from 'src/survey-type-defs'
import {IServerUpdatePayload} from './IServerUpdatePayload'
import {IServerUpdateType} from './IServerUpdateType'
import {IBlocksPatch} from '../../store/blocks/useBlocksDispatchHook'

export interface IServerUpdate {
  id: IUid
  type: IServerUpdateType
  payload: IServerUpdatePayload
}

//TODO: This should not be here
export const fromBlockUpdates = (
  {id}: IBlock,
  update: Partial<IBlock>,
): IServerUpdate => {
  return {
    id: uuid(),
    type: 'BLOCK_UPDATE',
    payload: {blockId: id, update},
  }
}

export const fromQuestionUpdates = (
  {blockId, id, type}: IQuestion,
  update: Partial<IQuestion>,
): IServerUpdate => {
  return {
    id: uuid(),
    type: 'QUESTION_UPDATE',
    payload: {blockId, questionId: id, questionType: type, update},
  }
}

export const fromBlockSliceUpdates = (update: IBlocksPatch): IServerUpdate => {
  return {
    id: uuid(),
    type: 'BLOCK_SLICE_UPDATE',
    payload: update,
  }
}

//TODO: next three  exports, names do not make any sense, also, move the functionality else where?
export const fromAddQuestionPayload = (
  blockId: string,
  newQuestion: IQuestion,
): IServerUpdate => {
  return {
    id: uuid(),
    type: 'ADD_QUESTION_TO_BLOCK',
    payload: {
      blockId: blockId,
      update: newQuestion,
    },
  }
}

export const fromReorderQuestionPayload = (
  blockId: string,
  questionId: string,
  orderNum: string,
): IServerUpdate => {
  return {
    id: uuid(),
    type: 'REORDER_QUESTION_IN_BLOCK',
    payload: {
      blockId,
      questionId,
      orderNum,
    },
  }
}

export const fromDeleteQuestionPayload = (
  blockId: string,
  questionId: string,
): IServerUpdate => {
  return {
    id: uuid(),
    type: 'DELETE_QUESTION',
    payload: {
      blockId,
      questionId,
    },
  }
}
