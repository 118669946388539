import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {TextInput} from '../../components/input/TextInput'
import {commonQuestionActions} from '../question/store/commonQuestionActions'
import {commonQuestionSelectors} from '../question/store/commonQuestionSelectors'

interface IProps {
  questionId: IUid
  answerId: IUid
}

export const AnswerDynamicComment: React.FC<IProps> = ({
  questionId,
  answerId,
}) => {
  const dynamicComment = commonQuestionSelectors.useAnswerDynamicComment(
    questionId,
    answerId,
  )
  const updateAnswerDynamicComment =
    commonQuestionActions.useUpdateDynamicCommentLabels()
  const canDisplayDynamicComment =
    commonQuestionSelectors.useCanDisplayDynamicComment(questionId)

  const handleUpdateAnswerDynamicComment = (
    dynamicCommentLabel: string,
  ): void => {
    updateAnswerDynamicComment(questionId, {
      answerId,
      dynamicCommentLabel: dynamicCommentLabel,
    })
  }

  if (!canDisplayDynamicComment) {
    return null
  }

  return (
    <TextInput
      ariaLabel="dynamic comment text"
      value={dynamicComment}
      placeholder="Dynamic Comment Label"
      onChange={handleUpdateAnswerDynamicComment}
    />
  )
}
