import {ImageSizeEnum, ImageSubtypeEnum} from 'src/survey-type-defs'

export const getImageSubtypeLabel = (type: ImageSubtypeEnum): string => {
  switch (type) {
    case ImageSubtypeEnum.RADIO:
      return 'Radio'
    case ImageSubtypeEnum.CHECKBOX:
      return 'Checkbox'
  }
}

export const getImageSizeTypeLabel = (type: ImageSizeEnum): string => {
  switch (type) {
    case ImageSizeEnum.AUTO_SIZE_TO_WINDOW:
      return 'Auto size to window'
    case ImageSizeEnum.SMALL:
      return 'Small'
    case ImageSizeEnum.MEDIUM:
      return 'Medium'
    case ImageSizeEnum.LARGE:
      return 'Large'
    case ImageSizeEnum.CUSTOM:
      return 'Custom'
  }
}
