import React from 'react'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {choiceSelectors} from '../../questionTypes/choice/store/choiceSelectors'
import {AnswerDisplayOrderEnum} from 'src/survey-type-defs'
import {answerDisplayOrderActions} from '../store/answerDisplayOrderActions'
import {answerDisplayOrderSelectors} from '../store/answerDisplayOrderSelectors'
import {SingleSelect} from '../../../components/dropdown/SingleSelect'
import {QuestionSettingSection} from '../../../components/questionSettingSection/QuestionSettingSection'

export const RandomAnswerDisplay: React.FC<IBaseComponentProps> = ({
  questionId,
}) => {
  const answerCount = choiceSelectors.useAnswerCount(questionId)
  const randomAnswerCount =
    answerDisplayOrderSelectors.useSelectRandomAnswerCount(questionId)
  const updateRandomAnswerCount =
    answerDisplayOrderActions.useUpdateRandomAnswerCount()
  const answerDisplayOrder =
    answerDisplayOrderSelectors.useSelectAnswerDisplayOrder(questionId)

  const canDisplayRandomAnswerCount =
    answerDisplayOrder === AnswerDisplayOrderEnum.RANDOM

  const handleUpdateRandomAnswerCount = (value: string): void => {
    const randomAnswerCount: number = +value as number
    updateRandomAnswerCount(questionId, {
      randomAnswerCount: randomAnswerCount,
    })
  }

  return (
    <>
      {canDisplayRandomAnswerCount && (
        <QuestionSettingSection title="Number of Answers to Randomize">
          <SingleSelect
            ariaLabel="randomAnswerCountSelect"
            onChange={handleUpdateRandomAnswerCount}
            value={randomAnswerCount}
          >
            {Array.from({length: answerCount}, (_, index) => {
              const answerIndex = index + 1
              const answerIndexDisplayValue =
                answerIndex === answerCount ? 'All' : answerIndex
              return (
                <option key={answerIndex} value={answerIndex}>
                  {answerIndexDisplayValue}
                </option>
              )
            })}
          </SingleSelect>
        </QuestionSettingSection>
      )}
    </>
  )
}
