import React from 'react'
import {ImageSubtypeEnum} from 'src/survey-type-defs'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {ImageCheckboxAnswerList} from './components/ImageCheckboxAnswerList'
import {ImageRadioAnswerList} from './components/ImageRadioAnswerList'
import {imageSelectors} from './store/imageSelectors'

export const ImageQuestion: React.FC<IBaseComponentProps> = ({questionId}) => {
  const answerOrder = imageSelectors.useAnswerOrder(questionId)
  const imageSubType = imageSelectors.useQuestionSubType(questionId)

  switch (imageSubType) {
    case ImageSubtypeEnum.RADIO: {
      return (
        <div>
          <ImageRadioAnswerList
            questionId={questionId}
            answerOrder={answerOrder}
          />
        </div>
      )
    }
    case ImageSubtypeEnum.CHECKBOX: {
      return (
        <div>
          <ImageCheckboxAnswerList
            questionId={questionId}
            answerOrder={answerOrder}
          />
        </div>
      )
    }
    default: {
      throw new Error('Invalid subtype for image question')
    }
  }
}
