import {useQuestionSelector} from '../../../store/questions/useQuestionSelectorHook'
import {IQuestion, IUid, QuestionTypeEnum} from 'src/survey-type-defs'
import {useUiSliceSelector} from '../../../store/ui/useUiSliceSelector'
import {questionConfig} from '../../../types/questionTypes/questionConfig'

export const commonQuestionSelectors = {
  useOrderNum: (questionId: IUid): string => {
    return useQuestionSelector(questionId, q => q.orderNum)
  },
  useType: (questionId: IUid): QuestionTypeEnum => {
    return useQuestionSelector(questionId, q => q.type)
  },
  useOptType: (questionId?: IUid): QuestionTypeEnum | undefined => {
    // hack to not pass undefined questionId
    return useQuestionSelector(questionId || '', q => q?.type, true)
  },
  useText: (questionId: IUid): string => {
    return useQuestionSelector(questionId, q => q.text)
  },
  useIsDeleted: (questionId: IUid | undefined): boolean => {
    return useQuestionSelector(questionId || '', q => !!q?.deleted, true)
  },
  useIsActiveQuestion: (questionId: IUid): boolean => {
    return useUiSliceSelector(state => state.activeQuestionId === questionId)
  },
  useAnswerDynamicComment: (questionId: IUid, answerId: IUid): string => {
    return useQuestionSelector<IQuestion, string>(
      questionId,
      q => q.dynamicCommentLabels![answerId],
    )
  },
  useCanDisplayDynamicComment: (questionId: IUid): boolean => {
    const type = commonQuestionSelectors.useType(questionId)
    return questionConfig.isDynamicCommentLabelsEnabled(type)
  },
} as const
