import React from 'react'
import {SingleSelect} from '../../../../components/dropdown/SingleSelect'
import {IBaseComponentProps} from '../../../../types/questionTypes/BaseQuestionProps'
import {textSelectors} from '../store/TextSelectors'
import {TextBoxDisplay} from './TextBoxDisplay'
import {TextCharacterLimit} from './TextCharacterLimit'
import {TextInputSize} from './TextInputSize'
import {TextPrefixSuffix} from './TextPrefixSuffix'
import {TextSubtypes} from './TextSubtypes'

export const TextRowSetting: React.FC<IBaseComponentProps> = ({questionId}) => {
  const rowOrder = textSelectors.useSelectRowOrder(questionId)
  const [activeRowId, setActiveRowId] = React.useState(rowOrder[0])
  const rows = textSelectors.useRows(questionId)

  const isActiveRowDeleted = !rowOrder.some(rowId => activeRowId === rowId)
  const checkedActiveRowId = isActiveRowDeleted ? rowOrder[0] : activeRowId
  if (isActiveRowDeleted) {
    setActiveRowId(checkedActiveRowId)
  }

  const handleChangeActiveRow = (value: string): void => {
    setActiveRowId(value)
  }

  return (
    <>
      <SingleSelect
        ariaLabel="textRowSelect"
        onChange={handleChangeActiveRow}
        value={checkedActiveRowId}
      >
        {rowOrder.map(rowId => {
          const row = rows[rowId]
          if (!row) throw new Error(`Row not found for id: ${rowId}`)

          return (
            <option key={rowId} value={rowId}>
              {row.text}
            </option>
          )
        })}
      </SingleSelect>

      <TextSubtypes questionId={questionId} rowId={checkedActiveRowId} />
      <TextBoxDisplay questionId={questionId} rowId={checkedActiveRowId} />
      <TextCharacterLimit questionId={questionId} rowId={checkedActiveRowId} />
      <TextInputSize questionId={questionId} rowId={checkedActiveRowId} />
      <TextPrefixSuffix questionId={questionId} rowId={checkedActiveRowId} />
    </>
  )
}
