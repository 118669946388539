import React from 'react'
import {IUid, VideoTypeEnum} from 'src/survey-type-defs'
import {TextInput} from '../../../components/input/TextInput'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {videoActions} from '../store/videoActions'
import {videoSelectors} from '../store/videoSelectors'

interface IProps extends IBaseComponentProps {
  questionId: IUid
  videoType: VideoTypeEnum
}
export const VideoVimeoUrl: React.FC<IProps> = ({questionId, videoType}) => {
  const videoVimeoLink = videoSelectors.useVideoVimeoLink(questionId)
  const updateVideoVimeoLink = videoActions.useUpdateVideoVimeoLink()
  const handleOnVideoVimeoLinkChange = (vimeoLink: string): void => {
    updateVideoVimeoLink(questionId, {
      vimeoLink: vimeoLink,
    })
  }
  const canDisplayVimeoUrl = VideoTypeEnum.VIMEO === videoType
  if (!canDisplayVimeoUrl) {
    return null
  }
  return (
    <div>
      <TextInput
        value={videoVimeoLink}
        placeholder="Enter Vimeo url link"
        onChange={handleOnVideoVimeoLinkChange}
      />
    </div>
  )
}
