import React from 'react'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {VideoAlignEnum} from 'src/survey-type-defs'
import {videoSelectors} from '../store/videoSelectors'
import {videoActions} from '../store/videoActions'
import {getVideoAlignLabel} from '../util/videoUtil'
import {SingleSelect} from '../../../components/dropdown/SingleSelect'
import {SettingSectionWithoutHeader} from '../../../components/questionSettingSection/SettingSectionWithoutHeader'
import {Label} from '../../../components/buttons/label/Label'

export const VideoAlign: React.FC<IBaseComponentProps> = ({questionId}) => {
  const selectedVideoAlignType = videoSelectors.useVideoAlignType(questionId)

  const updateVideoAlignType = videoActions.useUpdateVideoAlignType()
  const handleUpdateVideoAlignType = (value: string): void => {
    const alignType: VideoAlignEnum = +value as VideoAlignEnum
    updateVideoAlignType(questionId, {
      alignType: alignType,
    })
  }
  return (
    <SettingSectionWithoutHeader>
      <Label text="Align" />
      <SingleSelect
        ariaLabel="videAlignSelect"
        onChange={handleUpdateVideoAlignType}
        value={selectedVideoAlignType}
      >
        <option value={VideoAlignEnum.LEFT}>
          {getVideoAlignLabel(VideoAlignEnum.LEFT)}
        </option>
        <option value={VideoAlignEnum.CENTER}>
          {getVideoAlignLabel(VideoAlignEnum.CENTER)}
        </option>
        <option value={VideoAlignEnum.RIGHT}>
          {getVideoAlignLabel(VideoAlignEnum.RIGHT)}
        </option>
      </SingleSelect>
    </SettingSectionWithoutHeader>
  )
}
