import React from 'react'

export const useDebounce = (
  value: string,
  callback: (text: string) => void,
  delay: number,
): void => {
  const [firstLoad, setFirstLoad] = React.useState(true)
  // State and setters for debounced value

  React.useEffect(() => {
    setFirstLoad(false)
  }, [firstLoad])

  React.useEffect(
    () => {
      if (firstLoad) return () => {}

      // Update debounced value after delay
      const handler = setTimeout(() => {
        callback(value)
      }, delay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, delay], // Only re-call effect if value or delay changes
  )
}
