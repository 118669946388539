import React from 'react'

interface IProps {
  title: string
}

export const QuestionSettingHeader: React.FC<IProps> = ({title}) => {
  return (
    <div
      style={{
        marginTop: '10px',
        marginLeft: '6px',
        fontWeight: 'bold',
        fontSize: '12px',
      }}
    >
      {title}
    </div>
  )
}
