import {MatrixSubtypeEnum} from 'src/survey-type-defs'

export const matrixUtil = {
  getSubtypeLabel(subtype: MatrixSubtypeEnum): string {
    switch (subtype) {
      case MatrixSubtypeEnum.CHECKBOX:
        return 'Checkbox'
      case MatrixSubtypeEnum.DROPDOWN:
        return 'Dropdown'
      case MatrixSubtypeEnum.RADIO:
        return 'Radio'
      case MatrixSubtypeEnum.SELECT_LIST:
        return 'Select List'
      case MatrixSubtypeEnum.SLIDER:
        return 'Slider'
      case MatrixSubtypeEnum.TEXT:
        return 'Text'

      default:
        throw new Error(`Invalid subtype: ${subtype}`)
    }
  },
} as const
