import React from 'react'
import {RadioButton} from '../../../components/buttons/RadioButton'
import {IUid, QuestionTipEnum} from 'src/survey-type-defs'
import {QuestionTipHeader} from './QuestionTipHeader'
import {QuestionTipHelpText} from './QuestionTipHelpText'
import {QuestionTipLinkText} from './QuestionTipLinkText'
import {questionTipSelectors} from '../store/questionTipSelectors'
import {questionTipActions} from '../store/questionTipActions'
import {getQuestionTipTypeLabel} from '../util/questionTipUtil'

interface IProps {
  questionId: IUid
}

export const QuestionTipType: React.FC<IProps> = ({questionId}) => {
  const selectedTipType = questionTipSelectors.useQuestionTipType(questionId)

  const updateQuestionTipType = questionTipActions.useUpdateType()
  const handleOnQuestionTipTypeChange = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const tipType = +event.currentTarget.value as QuestionTipEnum
    updateQuestionTipType(questionId, {
      type: tipType,
    })
  }

  return (
    <div
      style={{
        marginTop: '10px',
        display: 'flex',
        flexWrap: 'wrap',
      }}
    >
      <RadioButton
        checked={selectedTipType === QuestionTipEnum.ICON}
        value={QuestionTipEnum.ICON}
        onClick={handleOnQuestionTipTypeChange}
      >
        {getQuestionTipTypeLabel(QuestionTipEnum.ICON)}
      </RadioButton>
      <RadioButton
        checked={selectedTipType === QuestionTipEnum.LINK}
        value={QuestionTipEnum.LINK}
        onClick={handleOnQuestionTipTypeChange}
      >
        {getQuestionTipTypeLabel(QuestionTipEnum.LINK)}
      </RadioButton>
      <QuestionTipLinkText
        questionId={questionId}
        selectedTipType={selectedTipType}
      />
      <QuestionTipHeader questionId={questionId} />
      <QuestionTipHelpText questionId={questionId} />
    </div>
  )
}
