import {IServerUpdate} from './../../socket/types/IServerUpdate'
import {surveyDb} from '../db/surveyDb'

export const mockSurveyEventHandlers = Object.freeze({
  initSurvey: (data: {surveyId: number}, callback: Function) => {
    callback(surveyDb.createSurvey(data.surveyId))
  },
  SERVER_UPDATE: (message: IServerUpdate, ackCallBack: Function) => {
    ackCallBack({id: message.id, type: message.type})
  },
})
