import React, {PropsWithChildren} from 'react'

interface IProps {
  onClick: () => void
}

export const LinkButton: React.FC<PropsWithChildren<IProps>> = ({
  onClick,
  children,
}) => {
  return (
    <button
      style={{
        border: 'none',
        padding: 0,
        textDecoration: 'underline',
        background: 'transparent',
        color: '#03a9f4',
        fontWeight: 'normal',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      {children}
    </button>
  )
}
