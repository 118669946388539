import {IUid} from 'src/survey-type-defs'
import {TextRow} from './TextRow'
import {textSelectors} from '../store/TextSelectors'

interface IProps {
  questionId: IUid
}

export const TextRows: React.FC<IProps> = ({questionId}) => {
  const rowsOrder = textSelectors.useSelectRowOrder(questionId)

  return (
    <div>
      {rowsOrder.map(rowId => (
        <TextRow key={rowId} questionId={questionId} rowId={rowId} />
      ))}
    </div>
  )
}
