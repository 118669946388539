import {Label} from '../buttons/label/Label'
import styles from './ToggleWithLabel.module.css'
interface IProps {
  label: string
  checked: boolean
  name: string
  htmlFor?: string
  onChange: () => void
}

export const ToggleWithLabel: React.FC<IProps> = ({
  label,
  checked,
  name,
  onChange,
  htmlFor,
}) => {
  return (
    <div>
      <label htmlFor={htmlFor} className={styles['toggle-label']}>
        {label}
      </label>
      <input
        aria-label={label}
        className={styles['toggle']}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
    </div>
  )
}
