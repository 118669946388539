import {smileyQuestionConfig} from '../../features/questionTypes/smiley/config/smileyQuestionConfig'
import {IQuestion} from 'src/survey-type-defs'
import {IBaseComponentProps} from './BaseQuestionProps'
import {choiceQuestionConfig} from '../../features/questionTypes/choice/config/choiceQuestionConfig'
import {QuestionTypeEnum} from 'src/survey-type-defs'
import {textQuestionConfig} from '../../features/questionTypes/text/config/textQuestionConfig'
import {matrixQuestionConfig} from '../../features/questionTypes/matrix/config/matrixQuestionConfig'
import {imageQuestionConfig} from '../../features/questionTypes/image/config/imageQuestionConfig'

export interface IQuestionConfig<T extends IQuestion> {
  textEnabled: boolean
  videoEnabled: boolean
  dynamicCommentLabelsEnabled: boolean
  component: React.FC<IBaseComponentProps>
  settingComponents: Readonly<Array<React.FC<IBaseComponentProps>>>
  factory: (blockId: string, orderNum: string, sequenceNum: number) => T
}

const questionConfigByType = Object.freeze({
  [QuestionTypeEnum.CHOICE]: choiceQuestionConfig,
  [QuestionTypeEnum.TEXT]: textQuestionConfig,
  [QuestionTypeEnum.SMILEY]: smileyQuestionConfig,
  [QuestionTypeEnum.MATRIX]: matrixQuestionConfig,
  [QuestionTypeEnum.IMAGE]: imageQuestionConfig,
})

const getCheckedConfig = (
  type: QuestionTypeEnum,
): IQuestionConfig<IQuestion> => {
  if (!questionConfigByType[type]) {
    throw new Error(`Unknown question type: ${type}`)
  }
  return questionConfigByType[type]
}

const isTextEnabled = (type: QuestionTypeEnum): boolean => {
  return getCheckedConfig(type).textEnabled
}

const isDynamicCommentLabelsEnabled = (type: QuestionTypeEnum): boolean => {
  return getCheckedConfig(type).dynamicCommentLabelsEnabled
}

const getQuestionComponent = (
  type: QuestionTypeEnum,
): React.FC<IBaseComponentProps> => {
  return getCheckedConfig(type).component
}

const getQuestionSettingComponents = (
  type: QuestionTypeEnum,
): Readonly<React.FC<IBaseComponentProps>[]> => {
  return getCheckedConfig(type).settingComponents
}

const getFactory = (
  type: QuestionTypeEnum,
): ((blockId: string, orderNum: string, sequenceNum: number) => IQuestion) => {
  return getCheckedConfig(type).factory
}

export const questionConfig = Object.freeze({
  isTextEnabled,
  isDynamicCommentLabelsEnabled,
  getQuestionComponent,
  getFactory,
  getQuestionSettingComponents,
})
