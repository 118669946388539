import {IQuestion, IUid} from 'src/survey-type-defs'
import {IHasVideo} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../store/questions/useQuestionDispatchHook'
import {useUiSliceDispatch} from '../../../store/ui/useUiSliceDispatch'

export const commonQuestionActions = {
  useUpdateTextAction: (): IQuestionAction<string> => {
    return useApplyQuestionPatchAndSyncToServer<IQuestion, string>(
      (_, text): Partial<IQuestion & IHasVideo> => {
        const questionPatch = {text}
        return questionPatch
      },
    )
  },
  useChangeActiveQuestionId: () => {
    return useUiSliceDispatch<IUid>((__, questionId) => {
      const scrollTop = document.documentElement.scrollTop
      return {
        activeQuestionId: questionId,
        activeQuestionScrollTop: scrollTop,
      }
    })
  },
  useUpdateDynamicCommentLabels: (): IQuestionAction<{
    answerId: IUid
    dynamicCommentLabel: string
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion,
      {answerId: IUid; dynamicCommentLabel: string}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const dynamicCommentLabels = originalQuestion.dynamicCommentLabels
      const updatedDynamicCommentLabels = {
        ...dynamicCommentLabels,
        [payload.answerId]: payload.dynamicCommentLabel,
      }

      const questionPatch = {
        dynamicCommentLabels: updatedDynamicCommentLabels,
      }
      return questionPatch
    })
  },
} as const
