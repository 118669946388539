import React from 'react'
import {IBaseComponentProps} from '../../types/questionTypes/BaseQuestionProps'
import {QuestionDisplayEnum} from 'src/survey-type-defs'
import {questionDisplayActions} from './store/questionDisplayActions'
import {questionDisplaySelectors} from './store/questionDisplaySelectors'
import {getQuestionDisplayLabels} from './util/questionDisplayUtil'
import {QuestionSettingSection} from '../../components/questionSettingSection/QuestionSettingSection'
import {SingleSelect} from '../../components/dropdown/SingleSelect'

export const QuestionDisplay: React.FC<IBaseComponentProps> = ({
  questionId,
}) => {
  const selectedQuestionDisplayType =
    questionDisplaySelectors.useSelectQuestionDisplayType(questionId)

  const updateQuestionDisplayType =
    questionDisplayActions.useUpdateAnswerDisplayOrder()
  const handleQuestionDisplayTypeChange = (value: string): void => {
    const questionDisplayType: QuestionDisplayEnum =
      +value as QuestionDisplayEnum
    updateQuestionDisplayType(questionId, {
      type: questionDisplayType,
    })
  }

  return (
    <QuestionSettingSection title="Question Display">
      <SingleSelect
        ariaLabel="questionDisplaySelect"
        onChange={handleQuestionDisplayTypeChange}
        value={selectedQuestionDisplayType}
      >
        <option value={QuestionDisplayEnum.SHOW_QUESTION}>
          {getQuestionDisplayLabels(QuestionDisplayEnum.SHOW_QUESTION)}
        </option>
        <option value={QuestionDisplayEnum.HIDE_QUESTION}>
          {getQuestionDisplayLabels(QuestionDisplayEnum.HIDE_QUESTION)}
        </option>
        <option value={QuestionDisplayEnum.HIDDEN_AFTER_ANSWERING}>
          {getQuestionDisplayLabels(QuestionDisplayEnum.HIDDEN_AFTER_ANSWERING)}
        </option>
      </SingleSelect>
    </QuestionSettingSection>
  )
}
