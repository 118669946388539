import {
  IImageQuestion,
  ImageSizeEnum,
  ImageSubtypeEnum,
} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../../store/questions/useQuestionDispatchHook'

export const imageActions = {
  useUpdateSubtype: (): IQuestionAction<{imageSubType: ImageSubtypeEnum}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IImageQuestion,
      {imageSubType: ImageSubtypeEnum}
    >((_, payload): Partial<IImageQuestion> => {
      const questionPatch = {
        subtype: payload.imageSubType,
      }
      return questionPatch
    })
  },
  useUpdateImageSizeType: (): IQuestionAction<{
    imageSizeType: ImageSizeEnum
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IImageQuestion,
      {imageSizeType: ImageSizeEnum}
    >((originalQuestion, payload): Partial<IImageQuestion> => {
      const imageSize = originalQuestion.imageSize
      const updatedImageSize = {...imageSize, type: payload.imageSizeType}
      const questionPatch = {
        imageSize: updatedImageSize,
      }
      return questionPatch
    })
  },
  useUpdateImageCustomWidth: (): IQuestionAction<{
    customWidth: number
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IImageQuestion,
      {customWidth: number}
    >((originalQuestion, payload): Partial<IImageQuestion> => {
      const imageSize = originalQuestion.imageSize
      const updatedImageSize = {...imageSize, customWidth: payload.customWidth}
      const questionPatch = {
        imageSize: updatedImageSize,
      }
      return questionPatch
    })
  },
  useUpdateImageCustomHeight: (): IQuestionAction<{
    customHeight: number
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IImageQuestion,
      {customHeight: number}
    >((originalQuestion, payload): Partial<IImageQuestion> => {
      const imageSize = originalQuestion.imageSize
      const updatedImageSize = {
        ...imageSize,
        customHeight: payload.customHeight,
      }
      const questionPatch = {
        imageSize: updatedImageSize,
      }
      return questionPatch
    })
  },
}
