import React from 'react'

interface IProps {
  id: string
  value: number
  placeholder?: string
  fontSizePx?: number
  width?: string
  fontWeight?: number
  minValue?: number
  maxValue?: number
  onChange: (value: number) => void
}

export const NumericInput: React.FC<IProps> = ({
  id,
  value,
  placeholder = 'Enter value',
  fontSizePx = 14,
  fontWeight = 300,
  width = 'auto',
  minValue,
  maxValue,
  onChange,
}) => {
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    onChange(+event.target.value)
  }

  return (
    <input
      id={id}
      type="number"
      value={value}
      onChange={handleOnChange}
      placeholder={placeholder}
      min={minValue}
      max={maxValue}
      style={{
        width,
        flexGrow: 1,
        border: 'none',
        borderBottom: '1px solid rgba(0,0,0,.12)',
        padding: '4px 8px',
        margin: '8px',
        fontSize: `${fontSizePx}px`,
        fontWeight: fontWeight,
      }}
    />
  )
}
