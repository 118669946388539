import {
  IUid,
  AnswerDisplayOrderEnum,
  IQuestion,
  IHasAnswerDisplayOrder,
} from 'src/survey-type-defs'
import {useQuestionSelector} from '../../../store/questions/useQuestionSelectorHook'

export const answerDisplayOrderSelectors = {
  useSelectAnswerDisplayOrder: (questionId: IUid): AnswerDisplayOrderEnum => {
    return useQuestionSelector<
      IQuestion & IHasAnswerDisplayOrder,
      AnswerDisplayOrderEnum
    >(questionId, q => q.answerDisplayOrder.type)
  },
  useSelectRandomAnswerCount: (questionId: IUid): number => {
    return useQuestionSelector<IQuestion & IHasAnswerDisplayOrder, number>(
      questionId,
      q => q.answerDisplayOrder.randomAnswerCount,
    )
  },
} as const
