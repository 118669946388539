import {IServerUpdateProcessor} from '../../socket/types/IServerUpdateProcessor'
import {IServerUpdate} from '../../socket/types/IServerUpdate'
import {IUid} from 'src/survey-type-defs'
import {IBlockServerUpdate} from '../../types/serverUpdates/block/QuestionServerUpdate'
import {IUseAppStore} from '../../types/store/UseAppStore'
import {IQuestionUpdatePayload} from '../../types/serverUpdates/QuestionUpdatePayload'
import {loggerUtil} from '../../test/utils/loggerUtil'

class QuestionServerUpdateProcessor implements IServerUpdateProcessor {
  readonly unAckServerUpdates: Record<IUid, IBlockServerUpdate> = {}

  processServerMessages(message: IServerUpdate, appStore: IUseAppStore): void {
    const questionUpdatePayload = message.payload as IQuestionUpdatePayload
    loggerUtil.logOnlyDev(
      'question updates received over socket',
      questionUpdatePayload.update,
    )
    appStore
      .getState()
      .questionsActions.applyServerUpdates(
        questionUpdatePayload.questionId,
        questionUpdatePayload.update,
      )
  }

  hasConflicts(
    unAckMessage: IServerUpdate,
    messageFromServer: IServerUpdate,
  ): boolean {
    console.log('checking for conflicts')

    const unAckPayload = unAckMessage.payload as IQuestionUpdatePayload
    const serverPayload = messageFromServer.payload as IQuestionUpdatePayload

    const isSameQuestion =
      unAckPayload.blockId === serverPayload.blockId &&
      unAckPayload.questionId === serverPayload.questionId

    if (!isSameQuestion) return false

    for (const receivedPayloadProperty in serverPayload.update) {
      if (unAckPayload.update?.hasOwnProperty(receivedPayloadProperty)) {
        // this property is already updated, this is conflicting update
        console.log(
          'Conflicting updates',
          messageFromServer.payload,
          unAckMessage,
          receivedPayloadProperty,
        )
        return true
      }
    }

    return false
  }
}

export const questionServerUpdatesProcessor =
  new QuestionServerUpdateProcessor()
