import {
  IUid,
  QuestionDisplayEnum,
  IQuestion,
  IHasQuestionDisplay,
} from 'src/survey-type-defs'
import {useQuestionSelector} from '../../../store/questions/useQuestionSelectorHook'

export const questionDisplaySelectors = {
  useSelectQuestionDisplayType: (questionId: IUid): QuestionDisplayEnum => {
    return useQuestionSelector<
      IQuestion & IHasQuestionDisplay,
      QuestionDisplayEnum
    >(questionId, q => q.questionDisplay.type)
  },
} as const
