import React, {useState} from 'react'
import {NumericInput} from '../../../components/input/NumericInput'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {videoActions} from '../store/videoActions'
import {videoSelectors} from '../store/videoSelectors'

export const VideoWidth: React.FC<IBaseComponentProps> = ({questionId}) => {
  const videoWidth = videoSelectors.useVideoWidth(questionId)
  const [isValidWidth, setValidWidth] = useState(true)
  const updateVideoWidth = videoActions.useUpdateVideoWidth()
  const handleOnVideoWidthChange = (width: number): void => {
    if (width >= 30 && width <= 100) {
      updateVideoWidth(questionId, {
        width: width,
      })
      setValidWidth(true)
    } else {
      setValidWidth(false)
    }
  }
  return (
    <div style={{marginLeft: '5px', marginTop: '10px'}}>
      <label style={{marginRight: '5px'}}>
        Width
        <NumericInput
          id="video-width"
          value={videoWidth}
          placeholder="Width"
          minValue={30}
          maxValue={100}
          onChange={handleOnVideoWidthChange}
        />
      </label>
      {!isValidWidth && (
        <span aria-label="widthErrorSpan">
          Width should be between 30 and 100%
        </span>
      )}
    </div>
  )
}
