import {AnswerDisplayOrderEnum} from 'src/survey-type-defs'

export const getAnswerDisplayOrderLabel = (
  type: AnswerDisplayOrderEnum,
): string => {
  switch (type) {
    case AnswerDisplayOrderEnum.DEFAULT:
      return 'Default'
    case AnswerDisplayOrderEnum.ASCENDING:
      return 'Ascending'
    case AnswerDisplayOrderEnum.DESCENDING:
      return 'Descending'
    case AnswerDisplayOrderEnum.RANDOM:
      return 'Random'
    case AnswerDisplayOrderEnum.ADVANCED_RANDOMIZATION:
      return 'Advanced Randomization'
    case AnswerDisplayOrderEnum.ALTERNATE_FLIP:
      return 'Alternate Flip'
  }
}
