import React, {PropsWithChildren} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {AppStoreProvider} from './AppStoreProvider'
import {AuthProvider} from './AuthProvider'

export const AppProviders: React.FC<PropsWithChildren> = ({children}) => {
  return (
    <Router>
      <AppStoreProvider>
        <AuthProvider>{children}</AuthProvider>
      </AppStoreProvider>
    </Router>
  )
}
