import {
  IMatrixColumnOrder,
  IMatrixQuestion,
  IMatrixRowOrder,
  IUid,
  MatrixSubtypeEnum,
} from 'src/survey-type-defs'
import {useQuestionSelector} from '../../../../store/questions/useQuestionSelectorHook'

export const matrixSelectors = {
  useSubType: (questionId: IUid): MatrixSubtypeEnum => {
    return useQuestionSelector<IMatrixQuestion, MatrixSubtypeEnum>(
      questionId,
      q => q.subtype,
    )
  },
  useColumnOrder: (questionId: IUid): IMatrixColumnOrder => {
    return useQuestionSelector<IMatrixQuestion, IMatrixColumnOrder>(
      questionId,
      q => q.columnOrder,
    )
  },
  useRowOrder: (questionId: IUid): IMatrixRowOrder => {
    return useQuestionSelector<IMatrixQuestion, IMatrixRowOrder>(
      questionId,
      q => q.rowOrder,
    )
  },
  useColumnText: (questionId: IUid, columnId: IUid): string => {
    return useQuestionSelector<IMatrixQuestion, string>(questionId, q => {
      const column = q.columns[columnId]
      if (!column) throw new Error(`Column not found for id: ${columnId}`)
      return column.text
    })
  },
  useRowText: (questionId: IUid, rowId: IUid): string => {
    return useQuestionSelector<IMatrixQuestion, string>(questionId, q => {
      const row = q.rows[rowId]
      if (!row) throw new Error(`Row not found for id: ${rowId}`)
      return row.text
    })
  },
} as const
