import React from 'react'
import styles from './DropdownMenuItemWithSubmenu.module.css'
import liStyles from './DropdownMenu.module.css'
import {useToggle} from '../../hooks/useToggle'
import {useOnClickOutside} from '../../hooks/useOutsideClick'

interface IProps {
  title: string
  submenu: React.ReactNode
  leftIcon?: React.ReactNode
}

export const DropdownMenuItemWithSubmenu: React.FC<IProps> = ({
  submenu,
  title,
  leftIcon,
}) => {
  const {on, toggle, reset} = useToggle()
  const liRef = React.useRef<HTMLLIElement | null>(null)
  useOnClickOutside(liRef, reset)

  const handleLiClick = submenu ? toggle : undefined
  const handleSubmenuClick: React.MouseEventHandler<HTMLDivElement> = event => {
    event.stopPropagation()
  }

  return (
    <li
      aria-label={title}
      className={`${styles.dropdownMenuItem} ${liStyles.dropdownMenuItem}`}
      onClick={handleLiClick}
      ref={liRef}
    >
      {leftIcon && leftIcon}
      <div className={styles.title}>{title}</div>
      <i className={styles.icon}>&#8964;</i>
      {on && (
        <div className={styles.submenu} onClick={handleSubmenuClick}>
          {submenu}
        </div>
      )}
    </li>
  )
}
