import {rest} from 'msw'
import {BACKEND_URL} from '../../../constants/appConstants'
import {IUser} from '../../../types/User'

export const authServiceHandler = [
  rest.get(`${BACKEND_URL}/api/auth/user`, async (req, res, ctx) => {
    const user = getUser()
    return res(ctx.status(200), ctx.json({...user}))
  }),
]

function getUser(): IUser {
  return {emailAddress: 'member@example.com'}
}
