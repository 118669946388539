import React from 'react'

interface IProps {
  id?: string
  value: string | number
  placeholder?: string
  fontSizePx?: number
  width?: string
  fontWeight?: number
  minValue?: number
  maxValue?: number
  ariaLabel?: string
  maxLength?: number
  minLength?: number
  onChange: (value: string) => void
}

export const TextInput: React.FC<IProps> = ({
  id,
  value,
  placeholder = 'Enter value',
  fontSizePx = 14,
  fontWeight = 300,
  width = 'auto',
  ariaLabel,
  maxLength,
  minLength,
  onChange,
}) => {
  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    onChange(event.target.value)
  }

  return (
    <input
      id={id}
      type="text"
      value={value}
      placeholder={placeholder}
      aria-label={ariaLabel}
      max={maxLength}
      min={minLength}
      onChange={handleOnChange}
      style={{
        width,
        flexGrow: 1,
        border: 'none',
        borderBottom: '1px solid rgba(0,0,0,.12)',
        padding: '4px 8px',
        margin: '8px',
        fontSize: `${fontSizePx}px`,
        fontWeight: fontWeight,
      }}
    />
  )
}
