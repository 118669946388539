import {
  IAnswerOrder,
  IImageQuestion,
  ImageSizeEnum,
  ImageSubtypeEnum,
  IUid,
} from 'src/survey-type-defs'
import {useQuestionSelector} from '../../../../store/questions/useQuestionSelectorHook'

export const imageSelectors = {
  useAnswerOrder: (questionId: IUid): IAnswerOrder => {
    return useQuestionSelector<IImageQuestion, IAnswerOrder>(
      questionId,
      q => q.answerOrder,
    )
  },
  useQuestionSubType: (questionId: IUid): ImageSubtypeEnum => {
    return useQuestionSelector<IImageQuestion, ImageSubtypeEnum>(
      questionId,
      q => q.subtype,
    )
  },
  useImageSizeType: (questionId: IUid): ImageSizeEnum => {
    return useQuestionSelector<IImageQuestion, ImageSizeEnum>(
      questionId,
      q => q.imageSize.type,
    )
  },
  useImageCustomWidth: (questionId: IUid): number => {
    return useQuestionSelector<IImageQuestion, number>(
      questionId,
      q => q.imageSize.customWidth!,
    )
  },
  useImageCustomHeight: (questionId: IUid): number => {
    return useQuestionSelector<IImageQuestion, number>(
      questionId,
      q => q.imageSize.customHeight!,
    )
  },
}
