import React from 'react'
import {IQuestion} from 'src/survey-type-defs'
import {IUid} from 'src/survey-type-defs'
import {useMyAppStore} from '../../providers/AppStoreProvider'
import {useServerUpdateService} from '../../providers/ServerUpdateServiceProvider'
import {fromQuestionUpdates} from '../../socket/types/IServerUpdate'

export type ICreateQuestionPatch<T, P> = (question: T, payload: P) => Partial<T>
export type IQuestionAction<P> = (questionId: IUid, payload: P) => void

export const useApplyQuestionPatchAndSyncToServer = <T extends IQuestion, P>(
  createPatch: ICreateQuestionPatch<T, P>,
): IQuestionAction<P> => {
  const appStore = useMyAppStore()
  const serverUpdateService = useServerUpdateService()
  return React.useCallback(
    (questionId: IUid, payload: P) => {
      const [originalQuestion, questionPatch] = appStore
        .getState()
        .questionsActions.applyUpdate(
          appStore,
          questionId,
          payload,
          createPatch,
        )

      //send question updates to server
      const update = fromQuestionUpdates(originalQuestion, questionPatch)
      serverUpdateService.publishMessage(update)
    },
    [createPatch, appStore, serverUpdateService],
  )
}
