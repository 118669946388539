import {MatrixSubtypeEnum} from 'src/survey-type-defs'

export const matrixColumnHeaderConfig = {
  [MatrixSubtypeEnum.CHECKBOX]: {header: true},
  [MatrixSubtypeEnum.RADIO]: {header: true},
  [MatrixSubtypeEnum.TEXT]: {header: true},
  [MatrixSubtypeEnum.DROPDOWN]: {header: false},
  [MatrixSubtypeEnum.SELECT_LIST]: {header: false},
  [MatrixSubtypeEnum.SLIDER]: {header: false},
} as const
