import {IUid, IQuestion} from 'src/survey-type-defs'
import {useQuestionSelector} from '../../store/questions/useQuestionSelectorHook'
import {IHasAlternateColors} from 'src/survey-type-defs'

export const alternateColorsSelectors = {
  useAlternateColors: (questionId: IUid): boolean => {
    return useQuestionSelector<IQuestion & IHasAlternateColors, boolean>(
      questionId,
      q => q.alternateColors.enabled,
    )
  },
} as const
