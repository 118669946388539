import React from 'react'
import {TextBoxPositionEnum} from 'src/survey-type-defs'
import {Label} from '../../../../components/buttons/label/Label'
import {RadioButton} from '../../../../components/buttons/RadioButton'
import {SingleSelect} from '../../../../components/dropdown/SingleSelect'
import {QuestionSettingSection} from '../../../../components/questionSettingSection/QuestionSettingSection'
import {SettingSectionWithoutHeader} from '../../../../components/questionSettingSection/SettingSectionWithoutHeader'
import {textActions} from '../store/textActions'
import {textSelectors} from '../store/TextSelectors'
import {IBaseRowSettingProps} from '../types/IBaserRowSettingProps'

const LABEL_WIDTHS = [10, 20, 30, 40, 50]

export const TextBoxDisplay: React.FC<IBaseRowSettingProps> = ({
  questionId,
  rowId,
}) => {
  const {position, labelWidth} = textSelectors.useTextBoxDisplay(
    questionId,
    rowId,
  )
  const changeTextBoxPosition = textActions.useChangeTextBoxPosition()
  const changeTextBoxLabelWidth = textActions.useChangeTextBoxLabelWidth()

  const handleChangePosition: React.MouseEventHandler<
    HTMLButtonElement
  > = event => {
    changeTextBoxPosition(questionId, {
      position: event.currentTarget.value as TextBoxPositionEnum,
      rowId,
    })
  }

  const handleChangeLabelWidth = (value: string): void => {
    changeTextBoxLabelWidth(questionId, {
      labelWidth: +value,
      rowId,
    })
  }

  return (
    <QuestionSettingSection title="Text Box Display">
      <div>
        <RadioButton
          checked={TextBoxPositionEnum.BELOW_QUESTION === position}
          value={TextBoxPositionEnum.BELOW_QUESTION}
          onClick={handleChangePosition}
        >
          Below Question
        </RadioButton>
        <RadioButton
          checked={TextBoxPositionEnum.NEXT_TO_QUESTION === position}
          value={TextBoxPositionEnum.NEXT_TO_QUESTION}
          onClick={handleChangePosition}
        >
          Next to Question
        </RadioButton>
      </div>
      <SettingSectionWithoutHeader>
        <Label htmlFor="text-box-width" text="Label Width" />
        <SingleSelect
          id="text-box-width"
          value={labelWidth || ''}
          onChange={handleChangeLabelWidth}
        >
          {LABEL_WIDTHS.map(value => (
            <option key={value} value={value}>
              {value} %
            </option>
          ))}
        </SingleSelect>
      </SettingSectionWithoutHeader>
    </QuestionSettingSection>
  )
}
