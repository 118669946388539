import React, {PropsWithChildren} from 'react'
import styles from './SingleSelect.module.css'

interface IProps {
  id?: string
  onChange: (value: string) => void
  value: string | number
  ariaLabel?: string
}

export const SingleSelect: React.FC<PropsWithChildren<IProps>> = ({
  id,
  onChange,
  value,
  children,
  ariaLabel,
}) => {
  const handleOnChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    onChange(event.target.value)
  }
  return (
    <select
      id={id}
      className={styles['single-select']}
      aria-label={ariaLabel}
      onChange={handleOnChange}
      value={value}
    >
      {children}
    </select>
  )
}
