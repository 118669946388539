import {useApplyQuestionPatchAndSyncToServer} from '../../../../store/questions/useQuestionDispatchHook'
import {IUid} from 'src/survey-type-defs'
import {ISmileyQuestion} from 'src/survey-type-defs'
import {SmileyAnswerOrderEnum} from 'src/survey-type-defs'

export const SmileyActions = {
  updateAnswerTextAction: (
    originalQuestion: ISmileyQuestion,
    payload: {answerId: IUid; text: string},
  ): Partial<ISmileyQuestion> => {
    const {answerId, text} = payload
    const answers = originalQuestion.answers
    const originalAnswer = answers.find(a => a.id === answerId)
    if (!originalAnswer) throw new Error(`Answer not found for id: ${answerId}`)

    const updatedAnswers = answers.map(a => {
      if (a.id === answerId) {
        return {...originalAnswer, text: text}
      }
      return a
    })

    const questionPatch = {
      answers: updatedAnswers,
    }
    return questionPatch
  },
  useChangePointScaleAction: () => {
    return useApplyQuestionPatchAndSyncToServer<ISmileyQuestion, number>(
      (__, newPointScale) => ({
        pointScale: newPointScale,
      }),
    )
  },
  useChangeAnswerOrderAction: () => {
    return useApplyQuestionPatchAndSyncToServer<
      ISmileyQuestion,
      SmileyAnswerOrderEnum
    >((__, newAnswerOrder) => ({
      answerOrder: newAnswerOrder,
    }))
  },
} as const
