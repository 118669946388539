import React from 'react'
import {ToggleWithLabel} from '../../../components/checkbox/ToggleWithLabel'
import {SettingSectionWithoutHeader} from '../../../components/questionSettingSection/SettingSectionWithoutHeader'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {videoActions} from '../store/videoActions'
import {videoSelectors} from '../store/videoSelectors'

export const VideoLoop: React.FC<IBaseComponentProps> = ({questionId}) => {
  const videoLoop = videoSelectors.useVideoLoopEnabled(questionId)

  const toggleVideoLoop = videoActions.useToggleVideoLoop()
  const handleToggleVideoLoop = (): void => {
    toggleVideoLoop(questionId, {
      loop: !videoLoop,
    })
  }
  return (
    <SettingSectionWithoutHeader>
      <ToggleWithLabel
        label="Loop"
        name="loopCheckbox"
        checked={videoLoop || false}
        onChange={handleToggleVideoLoop}
      />
    </SettingSectionWithoutHeader>
  )
}
