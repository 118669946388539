import React from 'react'
import {IBaseComponentProps} from '../../types/questionTypes/BaseQuestionProps'
import {VideoTypeEnum} from 'src/survey-type-defs'
import {videoActions} from './store/videoActions'
import {videoSelectors} from './store/videoSelectors'
import {VideoControls} from './components/VideoControls'
import {getVideoTypeLabel} from './util/videoUtil'
import {QuestionSettingSection} from '../../components/questionSettingSection/QuestionSettingSection'
import {SingleSelect} from '../../components/dropdown/SingleSelect'

export const VideoSetting: React.FC<IBaseComponentProps> = ({questionId}) => {
  const selectedVideoType = videoSelectors.useVideoType(questionId)

  const updateVideoType = videoActions.useUpdateVideoType()
  const handleVideoTypeChange = (value: string): void => {
    const videoType: VideoTypeEnum = +value as VideoTypeEnum
    updateVideoType(questionId, {
      type: videoType,
    })
  }

  return (
    <>
      <QuestionSettingSection title="Video">
        <SingleSelect
          ariaLabel="videSettingsSelect"
          onChange={handleVideoTypeChange}
          value={selectedVideoType}
        >
          <option value={VideoTypeEnum.NONE}>
            {getVideoTypeLabel(VideoTypeEnum.NONE)}
          </option>
          <option value={VideoTypeEnum.YOUTUBE}>
            {getVideoTypeLabel(VideoTypeEnum.YOUTUBE)}
          </option>
          <option value={VideoTypeEnum.VIMEO}>
            {getVideoTypeLabel(VideoTypeEnum.VIMEO)}
          </option>
          <option value={VideoTypeEnum.LIBRARY_VIDEO}>
            {getVideoTypeLabel(VideoTypeEnum.LIBRARY_VIDEO)}
          </option>
        </SingleSelect>
      </QuestionSettingSection>
      <VideoControls questionId={questionId} videoType={selectedVideoType} />
    </>
  )
}
