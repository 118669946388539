import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {choiceActions} from '../store/choiceActions'
import {choiceSelectors} from '../store/choiceSelectors'

interface IProps {
  questionId: IUid
  answerId: IUid
}

export const ChoiceExclusiveAnswerOption: React.FC<IProps> = ({
  questionId,
  answerId,
}) => {
  const isExclusive = choiceSelectors.useIsExclusiveAnswer(questionId, answerId)
  const updateExclusiveAnswers = choiceActions.useUpdateExclusiveAnswers()
  const handleUpdateExclusiveAnswerSelected = (): void => {
    updateExclusiveAnswers(questionId, {
      answerId,
      isExclusive: !isExclusive,
    })
  }

  return (
    <label>
      <input
        type="checkbox"
        name="exclusiveSelected"
        checked={isExclusive || false}
        onChange={handleUpdateExclusiveAnswerSelected}
      />
      Exclusive Option
    </label>
  )
}
