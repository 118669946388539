import {useMyAppStore} from '../../providers/AppStoreProvider'
import React from 'react'
import {IUiStore} from './uiSlice'

type IUiActionHandler<P> = (uiState: IUiStore, payload: P) => Partial<IUiStore>
type IDispatchAction<P> = (payload: P) => void

export const useUiSliceDispatch = <P,>(
  action: IUiActionHandler<P>,
): IDispatchAction<P> => {
  const appStore = useMyAppStore()

  return React.useCallback(
    (payload: P) => {
      appStore.setState(state => {
        const uiPatch = action(state, payload)
        return {...uiPatch}
      })
    },
    [action, appStore],
  )
}
