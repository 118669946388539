import {useMyAppStore} from '../../providers/AppStoreProvider'
import {IQuestion, IUid} from 'src/survey-type-defs'

export const useQuestionSelector = <T, ReturnType>(
  questionId: IUid,
  selector: (question: T & IQuestion) => ReturnType,
  optional = false,
): ReturnType => {
  return useMyAppStore()(state => {
    const originalQuestion = state.questions[questionId] as T & IQuestion
    if (!optional && !originalQuestion)
      throw new Error(`Question not found for id: ${questionId}`)
    return selector(originalQuestion)
  })
}
