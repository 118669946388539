import React from 'react'
import {IUid} from 'src/survey-type-defs'

interface IProps {
  questionId: IUid
}

export const MatrixSliderAnswerList: React.FC<IProps> = ({questionId}) => {
  return <td>MATRIX SLIDER</td>
}
