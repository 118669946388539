import React from 'react'
import {IBaseComponentProps} from '../../../../../types/questionTypes/BaseQuestionProps'
import {SmileyAnswerOrderEnum} from 'src/survey-type-defs'
import {SmileyActions} from '../../store/smileyActions'
import {SmileySelectors} from '../../store/smileySelector'
import {SingleSelect} from '../../../../../components/dropdown/SingleSelect'

export const SmileyAnswerOrderSetting: React.FC<IBaseComponentProps> = ({
  questionId,
}) => {
  const answerOrder = SmileySelectors.useAnswerOrder(questionId)
  const changeAnswerOrder = SmileyActions.useChangeAnswerOrderAction()

  const handleChangeAnswerOrder = (value: string): void => {
    changeAnswerOrder(questionId, value as SmileyAnswerOrderEnum)
  }

  return (
    <div>
      <SingleSelect
        ariaLabel="answer order"
        onChange={handleChangeAnswerOrder}
        value={answerOrder}
      >
        <option value={SmileyAnswerOrderEnum.NEGATIVE_TO_POSITIVE}>
          Negative to Positive
        </option>
        <option value={SmileyAnswerOrderEnum.POSITIVE_TO_NEGATIVE}>
          Positive to Negative
        </option>
      </SingleSelect>
    </div>
  )
}
