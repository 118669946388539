import {VideoAlignEnum, VideoTypeEnum} from 'src/survey-type-defs'

export const getVideoAlignLabel = (type: VideoAlignEnum): string => {
  switch (type) {
    case VideoAlignEnum.LEFT:
      return 'Left'
    case VideoAlignEnum.CENTER:
      return 'Center'
    case VideoAlignEnum.RIGHT:
      return 'Right'
  }
}

export const getVideoTypeLabel = (type: VideoTypeEnum): string => {
  switch (type) {
    case VideoTypeEnum.NONE:
      return 'None'
    case VideoTypeEnum.YOUTUBE:
      return 'YouTube'
    case VideoTypeEnum.VIMEO:
      return 'Vimeo'
    case VideoTypeEnum.LIBRARY_VIDEO:
      return 'Library Video'
  }
}
