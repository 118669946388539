import React from 'react'
import {IBaseComponentProps} from '../../types/questionTypes/BaseQuestionProps'
import {RandomAnswerDisplay} from './components/RandomAnswerDisplay'
import {AnswerDisplayOrderEnum} from 'src/survey-type-defs'
import {answerDisplayOrderActions} from './store/answerDisplayOrderActions'
import {answerDisplayOrderSelectors} from './store/answerDisplayOrderSelectors'
import {getAnswerDisplayOrderLabel} from './util/answerDisplayOrderUtil'
import {QuestionSettingSection} from '../../components/questionSettingSection/QuestionSettingSection'
import {SingleSelect} from '../../components/dropdown/SingleSelect'

export const AnswerDisplayOrder: React.FC<IBaseComponentProps> = ({
  questionId,
}) => {
  const selectedAnswerDisplayOrder =
    answerDisplayOrderSelectors.useSelectAnswerDisplayOrder(questionId)

  const updateAnswerDisplayOrder =
    answerDisplayOrderActions.useUpdateAnswerDisplayOrder()
  const handleAnswerDisplayOrderChange = (value: string): void => {
    const answerDisplayOrder: AnswerDisplayOrderEnum =
      +value as AnswerDisplayOrderEnum
    updateAnswerDisplayOrder(questionId, {
      type: answerDisplayOrder,
    })
  }

  return (
    <>
      <QuestionSettingSection title="Answer Display Order">
        <SingleSelect
          ariaLabel="answerDisplayOrderSelect"
          onChange={handleAnswerDisplayOrderChange}
          value={selectedAnswerDisplayOrder}
        >
          <option value={AnswerDisplayOrderEnum.DEFAULT}>
            {getAnswerDisplayOrderLabel(AnswerDisplayOrderEnum.DEFAULT)}
          </option>
          <option value={AnswerDisplayOrderEnum.ASCENDING}>
            {getAnswerDisplayOrderLabel(AnswerDisplayOrderEnum.ASCENDING)}
          </option>
          <option value={AnswerDisplayOrderEnum.DESCENDING}>
            {getAnswerDisplayOrderLabel(AnswerDisplayOrderEnum.DESCENDING)}
          </option>
          <option value={AnswerDisplayOrderEnum.RANDOM}>
            {getAnswerDisplayOrderLabel(AnswerDisplayOrderEnum.RANDOM)}
          </option>
          <option value={AnswerDisplayOrderEnum.ADVANCED_RANDOMIZATION}>
            {getAnswerDisplayOrderLabel(
              AnswerDisplayOrderEnum.ADVANCED_RANDOMIZATION,
            )}
          </option>
          <option value={AnswerDisplayOrderEnum.ALTERNATE_FLIP}>
            {getAnswerDisplayOrderLabel(AnswerDisplayOrderEnum.ALTERNATE_FLIP)}
          </option>
        </SingleSelect>
      </QuestionSettingSection>
      <RandomAnswerDisplay questionId={questionId} />
    </>
  )
}
