import {QuestionDisplayEnum} from 'src/survey-type-defs'

export const getQuestionDisplayLabels = (type: QuestionDisplayEnum): string => {
  switch (type) {
    case QuestionDisplayEnum.HIDDEN_AFTER_ANSWERING:
      return 'Hidden after answering'
    case QuestionDisplayEnum.HIDE_QUESTION:
      return 'Hide Question'
    case QuestionDisplayEnum.SHOW_QUESTION:
      return 'Show Question'
  }
}
