import {IBaseComponentProps} from '../../../../types/questionTypes/BaseQuestionProps'
import {matrixSelectors} from '../store/matrixSelectors'
import {MatrixColumnHeader} from './MatrixColumnHeader'

export const MatrixColumnHeaderRow: React.FC<IBaseComponentProps> = ({
  questionId,
}) => {
  const columnOrder = matrixSelectors.useColumnOrder(questionId)

  return (
    <thead>
      <tr>
        <td></td>
        {columnOrder.map(columnId => (
          <MatrixColumnHeader
            questionId={questionId}
            columnId={columnId}
            key={columnId}
          />
        ))}
      </tr>
    </thead>
  )
}
