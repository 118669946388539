import {TextSubtypeEnum} from 'src/survey-type-defs'
import {RadioButton} from '../../../../components/buttons/RadioButton'
import {QuestionSettingSection} from '../../../../components/questionSettingSection/QuestionSettingSection'
import {textActions} from '../store/textActions'
import {textSelectors} from '../store/TextSelectors'
import {IBaseRowSettingProps} from '../types/IBaserRowSettingProps'
import {textQuestionUtil} from '../util/textQuestionUtil'

export const TextSubtypes: React.FC<IBaseRowSettingProps> = ({
  questionId,
  rowId,
}) => {
  const subtype = textSelectors.useRowSubtype(questionId, rowId)
  const changeSubtype = textActions.useChangeSubtype()
  const handleSubtypeChange = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const newSubtype = event.currentTarget.value as TextSubtypeEnum
    changeSubtype(questionId, {subtype: newSubtype, rowId})
  }

  return (
    <QuestionSettingSection title="Answer Types">
      {Object.keys(TextSubtypeEnum).map(subtypeKey => {
        const subtypeValue = TextSubtypeEnum[
          subtypeKey as keyof typeof TextSubtypeEnum
        ] as TextSubtypeEnum
        return (
          <RadioButton
            key={subtypeKey}
            checked={subtype === subtypeValue}
            value={subtypeValue}
            onClick={handleSubtypeChange}
          >
            {textQuestionUtil.getSubtypeLabel(subtypeValue)}
          </RadioButton>
        )
      })}
    </QuestionSettingSection>
  )
}
