import {mockSurveyEventHandlers} from '../socket/mockSurveyEventHandlers'

export class SocketMock {
  constructor(eventHandlers: {[key: string]: Function}) {
    this.eventHandlers = eventHandlers
  }
  eventHandlers = {
    connect: (callback: Function) => {
      setTimeout(callback)
    },
  } as {[key: string]: Function}
  eventCallbacks = {} as {[key: string]: Function}
  socketDisconnected = false

  on(event: string, callback: Function): void {
    if (!this.eventCallbacks[event]) {
      if (event === 'connect') {
        callback()
        return
      } else if (event === 'initSurvey') {
        const initSurveyHandler = this.eventHandlers['initSurvey']
        initSurveyHandler({}, callback)
      }
      this.eventCallbacks[event] = callback
    }
  }

  callEventCallback(event: string, data?: unknown): void {
    this.eventCallbacks[event](data)
  }

  off(event: string): void {
    delete this.eventCallbacks[event]
  }
  emit(event: string, data: unknown, callback: Function): SocketMock {
    if (this.eventHandlers[event]) this.eventHandlers[event](data, callback)

    //adding this to get emit on SERVER_UPDATE working
    // if (this.eventCallbacks['RECEIVE_SERVER_UPDATES'])
    //   this.eventCallbacks['RECEIVE_SERVER_UPDATES'](data, callback)

    return this
  }
  disconnect(): void {
    this.socketDisconnected = true
  }
}

export const createIoMock =
  (socket: SocketMock = new SocketMock(mockSurveyEventHandlers)) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (): any => {
    return socket
  }
