import React from 'react'
import {useDebounce} from 'src/hooks/useDebounceHook'

interface IProps {
  text: string
  placeholder?: string
  fontSizePx?: number
  width?: string
  fontWeight?: number
  onChange: (text: string) => void
  onEnterPress?: () => void
}

export const WysiwygTextInput: React.FC<IProps> = ({
  text,
  placeholder = 'Enter text',
  fontSizePx = 14,
  fontWeight = 300,
  width = 'auto',
  onChange,
  onEnterPress,
}) => {
  const [value, setValue] = React.useState(text)
  useDebounce(value, onChange, 300)

  React.useEffect(() => {
    if (value !== text) setValue(text)
  }, [text])

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setValue(event.target.value)
  }

  const handleOnKeyUp: React.KeyboardEventHandler<HTMLInputElement> = event => {
    if (onEnterPress && event.key === 'Enter') {
      onEnterPress()
      event.preventDefault()
      return
    }
  }

  return (
    <input
      type="text"
      value={value}
      onChange={handleOnChange}
      onKeyUp={handleOnKeyUp}
      placeholder={placeholder}
      style={{
        width,
        flexGrow: 1,
        border: 'none',
        borderBottom: '1px solid rgba(0,0,0,.12)',
        padding: '4px 8px',
        margin: '8px',
        fontSize: `${fontSizePx}px`,
        fontWeight: fontWeight,
      }}
    />
  )
}
