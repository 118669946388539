import React from 'react'
import {WysiwygTextInput} from '../../../../components/input/wysiwygTextInput/WysiwygTextInput'
import {IUid} from 'src/survey-type-defs'
import {useAddAnswer} from '../hooks/useAddAnswer'
import {choiceActions} from '../store/choiceActions'
import {choiceSelectors} from '../store/choiceSelectors'

interface IProps {
  questionId: IUid
  answerId: IUid
  sequenceNum: number
}

export const Answer: React.FC<IProps> = ({
  questionId,
  answerId,
  sequenceNum,
}) => {
  const answerText = choiceSelectors.useAnswerText(questionId, answerId)
  const updateAnswerText = choiceActions.useUpdateAnswerText()
  const handleUpdateAnswerText = (text: string): void => {
    updateAnswerText(questionId, {answerId, text})
  }
  const handleAddAnswer = useAddAnswer(questionId, sequenceNum)

  return (
    <WysiwygTextInput
      text={answerText}
      placeholder="Option"
      onChange={handleUpdateAnswerText}
      onEnterPress={handleAddAnswer}
    />
  )
}
