import {TextQuestion} from '../TextQuestion'
import {IQuestionConfig} from '../../../../types/questionTypes/questionConfig'
import {ITextQuestion} from 'src/survey-type-defs'
import {uuid} from '../../../../utils/uuidUtil'
import {IUid} from 'src/survey-type-defs'
import {defaultQuestionConfig} from '../../../../types/questionTypes/common/defaultQuestionConfig'
import {QuestionTypeEnum} from 'src/survey-type-defs'
import {TextRowSetting} from '../setting/TextRowSetting'
import {textQuestionUtil} from '../util/textQuestionUtil'

export const textQuestionConfig: IQuestionConfig<ITextQuestion> = {
  ...defaultQuestionConfig,
  textEnabled: false,
  dynamicCommentLabelsEnabled: false,
  component: TextQuestion,
  settingComponents: [TextRowSetting],
  factory: (blockId: IUid, orderNum: string, sequenceNum: number) =>
    createTextQuestion(blockId, orderNum, sequenceNum),
}

const createTextQuestion = (
  blockId: string,
  orderNum: string,
  sequenceNum: number,
): ITextQuestion => {
  const singleRowText = textQuestionUtil.createRow(sequenceNum)

  return {
    id: uuid(),
    text: ``,
    code: `Q${orderNum}`,
    deleted: false,
    blockId,
    type: QuestionTypeEnum.TEXT,
    orderNum,
    rows: {[singleRowText.id]: singleRowText},
    rowsOrder: [singleRowText.id],
  }
}
