import React from 'react'
import {DropdownMenuItemButton} from '../../../../components/dropdownMenu/DropdownMenuItemButton'
import {textActions} from '../store/textActions'
import {IBaseRowSettingProps} from '../types/IBaserRowSettingProps'

export const RemoveTextRowMenuItem: React.FC<IBaseRowSettingProps> = ({
  questionId,
  rowId,
}) => {
  const deleteRow = textActions.useDeleteRow()
  const handleDeleteRow = (): void => {
    deleteRow(questionId, rowId)
  }
  return <DropdownMenuItemButton title="Remove Row" onClick={handleDeleteRow} />
}
