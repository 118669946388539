import {IQuestion, IQuestionTip, QuestionTipEnum} from 'src/survey-type-defs'
import {IHasQuestionTip} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../store/questions/useQuestionDispatchHook'

const DEFAULT_QUESTION_TIP: IQuestionTip = {
  enabled: true,
  type: QuestionTipEnum.ICON,
  header: '',
  helpText: '',
  linkText: '',
}

export const questionTipActions = {
  useToggleQuestionTip: (): IQuestionAction<{
    enabled: boolean
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasQuestionTip,
      {enabled: boolean}
    >(
      (
        originalQuestion: IQuestion & IHasQuestionTip,
        payload: {enabled: boolean},
      ): Partial<IQuestion & IHasQuestionTip> => {
        const questionTip = originalQuestion.questionTip
        const updatedQuestionTip =
          questionTip == null
            ? DEFAULT_QUESTION_TIP
            : {...questionTip, enabled: payload.enabled}

        const questionPatch = {
          questionTip: updatedQuestionTip,
        }
        return questionPatch
      },
    )
  },
  useUpdateType: (): IQuestionAction<{type: QuestionTipEnum}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasQuestionTip,
      {type: QuestionTipEnum}
    >(
      (
        originalQuestion: IQuestion & IHasQuestionTip,
        payload: {type: QuestionTipEnum},
      ): Partial<IQuestion & IHasQuestionTip> => {
        const questionTip = getQuestionTipOrThrow(originalQuestion)
        const updatedQuestionTip = {...questionTip, type: payload.type}
        const questionPatch = {
          questionTip: updatedQuestionTip,
        }
        return questionPatch
      },
    )
  },
  useUpdateHeader: (): IQuestionAction<{header: string}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasQuestionTip,
      {header: string}
    >(
      (
        originalQuestion: IQuestion & IHasQuestionTip,
        payload: {header: string},
      ): Partial<IQuestion & IHasQuestionTip> => {
        const questionTip = getQuestionTipOrThrow(originalQuestion)
        const updatedQuestionTip = {...questionTip, header: payload.header}
        const questionPatch = {
          questionTip: updatedQuestionTip,
        }
        return questionPatch
      },
    )
  },
  useUpdateHelpText: (): IQuestionAction<{helpText: string}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasQuestionTip,
      {helpText: string}
    >(
      (
        originalQuestion: IQuestion & IHasQuestionTip,
        payload: {helpText: string},
      ): Partial<IQuestion & IHasQuestionTip> => {
        const questionTip = getQuestionTipOrThrow(originalQuestion)
        const updatedQuestionTip = {...questionTip, helpText: payload.helpText}
        const questionPatch = {
          questionTip: updatedQuestionTip,
        }
        return questionPatch
      },
    )
  },
  useUpdateHelpLinkText: (): IQuestionAction<{helpLinkText: string}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasQuestionTip,
      {helpLinkText: string}
    >(
      (
        originalQuestion: IQuestion & IHasQuestionTip,
        payload: {helpLinkText: string},
      ): Partial<IQuestion & IHasQuestionTip> => {
        const questionTip = getQuestionTipOrThrow(originalQuestion)
        const updatedQuestionTip = {
          ...questionTip,
          linkText: payload.helpLinkText,
        }
        const questionPatch = {
          questionTip: updatedQuestionTip,
        }
        return questionPatch
      },
    )
  },
}

const getQuestionTipOrThrow = (
  question: IQuestion & IHasQuestionTip,
): IQuestionTip => {
  const questionTip = question.questionTip
  if (!questionTip)
    throw new Error('QuestionTip cannot be undefined once enabled')

  return questionTip
}
