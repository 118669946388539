import {useQuestionSelector} from '../../../../store/questions/useQuestionSelectorHook'
import {IUid} from 'src/survey-type-defs'
import {ISmileyAnswer} from 'src/survey-type-defs'
import {SmileyAnswerOrderEnum} from 'src/survey-type-defs'

import {ISmileyQuestion} from 'src/survey-type-defs'

export const SmileySelectors = {
  useSelectAnswerOrder: (questionId: IUid): SmileyAnswerOrderEnum => {
    return useQuestionSelector<ISmileyQuestion, SmileyAnswerOrderEnum>(
      questionId,
      q => q.answerOrder,
    )
  },
  useSelectAnswerText: (questionId: IUid, answerId: string): string => {
    return useQuestionSelector<ISmileyQuestion, string>(
      questionId,
      question => {
        const answer = question.answers.find(a => a.id === answerId)
        if (!answer) throw new Error(`Answer not found for id: ${answerId}`)

        return answer.text
      },
    )
  },
  useSelectAnswers: (questionId: IUid): ISmileyAnswer[] => {
    return useQuestionSelector<ISmileyQuestion, ISmileyAnswer[]>(
      questionId,
      q => q.answers,
    )
  },
  usePointScale: (questionId: IUid): number => {
    return useQuestionSelector<ISmileyQuestion, number>(
      questionId,
      q => q.pointScale,
    )
  },
  useAnswerOrder: (questionId: IUid): SmileyAnswerOrderEnum => {
    return useQuestionSelector<ISmileyQuestion, SmileyAnswerOrderEnum>(
      questionId,
      q => q.answerOrder,
    )
  },
} as const
