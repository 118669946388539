import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {choiceActions} from '../../store/choiceActions'
import {choiceSelectors} from '../../store/choiceSelectors'
import {ChoiceAnswerTextPositionEnum} from 'src/survey-type-defs'
import {ChoiceQuestionLayoutEnum} from 'src/survey-type-defs'
import {getTextPositionLabel} from '../../util/choiceUtil'
import {QuestionSettingSection} from '../../../../../components/questionSettingSection/QuestionSettingSection'
import {SingleSelect} from '../../../../../components/dropdown/SingleSelect'

interface IProps {
  questionId: IUid
  choiceQuestionLayoutType: ChoiceQuestionLayoutEnum
}

export const ChoiceAnswerTextPosition: React.FC<IProps> = ({
  questionId,
  choiceQuestionLayoutType,
}) => {
  const answerTextPosition = choiceSelectors.useAnswerTextPosition(questionId)

  const updateAnswerTextPosition = choiceActions.useUpdateAnswerTextPosition()
  const handleAnswerTextPositionChange = (value: string): void => {
    const answerTextPosition: ChoiceAnswerTextPositionEnum =
      +value as ChoiceAnswerTextPositionEnum
    updateAnswerTextPosition(questionId, {
      answerTextPosition: answerTextPosition,
    })
  }
  const canDisplayAnswerTextPosition =
    choiceQuestionLayoutType === ChoiceQuestionLayoutEnum.HORIZONTAL
  return (
    <>
      {canDisplayAnswerTextPosition && (
        <QuestionSettingSection title="Text Position">
          <SingleSelect
            ariaLabel="answerTextPositionSelect"
            onChange={handleAnswerTextPositionChange}
            value={answerTextPosition}
          >
            <option value={ChoiceAnswerTextPositionEnum.RIGHT_DEFAULT}>
              {getTextPositionLabel(ChoiceAnswerTextPositionEnum.RIGHT_DEFAULT)}
            </option>
            <option value={ChoiceAnswerTextPositionEnum.ABOVE}>
              {getTextPositionLabel(ChoiceAnswerTextPositionEnum.ABOVE)}
            </option>
            <option value={ChoiceAnswerTextPositionEnum.BELOW}>
              {getTextPositionLabel(ChoiceAnswerTextPositionEnum.BELOW)}
            </option>
          </SingleSelect>
        </QuestionSettingSection>
      )}
    </>
  )
}
