import styles from './DropdownMenuItemCheckbox.module.css'
import liStyles from './DropdownMenu.module.css'

interface IProps {
  title: string
  checked: boolean
  onChange: (value: boolean) => void
}

export const DropdownMenuItemCheckbox: React.FC<IProps> = ({
  title,
  checked,
  onChange,
}) => {
  const handleChange = (): void => {
    onChange(!checked)
  }

  return (
    <li className={liStyles.dropdownMenuItem}>
      <label className={styles.label}>
        <input type="checkbox" checked={checked} onChange={handleChange} />
        {title}
      </label>
    </li>
  )
}
