import {LinkButton} from '../../../../components/buttons/LinkButton'
import {IUid} from 'src/survey-type-defs'
import {IAnswerOrder} from 'src/survey-type-defs'
import {useAddAnswer} from '../hooks/useAddAnswer'
import {Answer} from './Answer'
import {AnswerDynamicComment} from '../../../dynamicComment/AnswerDynamicComment'
import {ToggleDefaultAnswerMenuItem} from './ToggleDefaultAnswerMenuItem'
import {RemoveAnswerMenuItem} from './RemoveAnswerMenuItem'
import {DropdownMenu} from '../../../../components/dropdownMenu/DropdownMenu'
import {DropdownMenuItemWithSubmenu} from '../../../../components/dropdownMenu/DropdownMenuItemWithSubmenu'

interface IProps {
  questionId: IUid
  answerOrder: IAnswerOrder
}

export const ChoiceRadioAnswerList: React.FC<IProps> = ({
  questionId,
  answerOrder,
}) => {
  const handleAddAnswer = useAddAnswer(questionId, answerOrder.length)

  return (
    <div>
      {answerOrder.map((answerId, index) => (
        <div key={answerId}>
          <input type="radio" name="radioAnswer" />
          <Answer
            questionId={questionId}
            answerId={answerId}
            sequenceNum={index + 1}
          />
          <DropdownMenu ariaLabel="answer option settings">
            <ToggleDefaultAnswerMenuItem
              questionId={questionId}
              answerId={answerId}
            />

            <DropdownMenuItemWithSubmenu
              title="Dynamic Comment"
              submenu={
                <AnswerDynamicComment
                  questionId={questionId}
                  answerId={answerId}
                />
              }
            />
            <RemoveAnswerMenuItem questionId={questionId} answerId={answerId} />
          </DropdownMenu>
        </div>
      ))}
      <div>
        <LinkButton onClick={handleAddAnswer}>Add Option</LinkButton>
      </div>
    </div>
  )
}
