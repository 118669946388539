import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {TextInput} from '../../../components/input/TextInput'
import {questionTipActions} from '../store/questionTipActions'
import {questionTipSelectors} from '../store/questionTipSelectors'

interface IProps {
  questionId: IUid
}
export const QuestionTipHelpText: React.FC<IProps> = ({questionId}) => {
  const selectedTipHelpText =
    questionTipSelectors.useQuestionTipHelpText(questionId)
  const updateQuestionTipHelpText = questionTipActions.useUpdateHelpText()
  const handleOnQuestionTipHelpTextChange = (helpText: string): void => {
    updateQuestionTipHelpText(questionId, {
      helpText: helpText,
    })
  }
  return (
    <TextInput
      value={selectedTipHelpText}
      placeholder="Help Text"
      onChange={handleOnQuestionTipHelpTextChange}
    />
  )
}
