import {LinkButton} from '../../../../components/buttons/LinkButton'
import {IUid} from 'src/survey-type-defs'
import {IAnswerOrder} from 'src/survey-type-defs'
import {useAddAnswer} from '../hooks/useAddAnswer'
import {AnswerDropDownOption} from './AnswerDropDownOption'

interface IProps {
  questionId: IUid
  answerOrder: IAnswerOrder
}

export const ChoiceDropDownAnswerList: React.FC<IProps> = ({
  questionId,
  answerOrder,
}) => {
  const handleAddAnswer = useAddAnswer(questionId, answerOrder.length)
  return (
    <div>
      <select>
        {answerOrder.map(answerId => (
          <AnswerDropDownOption
            key={answerId}
            questionId={questionId}
            answerId={answerId}
          />
        ))}
      </select>

      <div>
        <LinkButton onClick={handleAddAnswer}>Add Option</LinkButton>
      </div>
    </div>
  )
}
