import React from 'react'
import {ToggleWithLabel} from '../../../components/checkbox/ToggleWithLabel'
import {SettingSectionWithoutHeader} from '../../../components/questionSettingSection/SettingSectionWithoutHeader'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {videoActions} from '../store/videoActions'
import {videoSelectors} from '../store/videoSelectors'

export const VideoAutoplay: React.FC<IBaseComponentProps> = ({questionId}) => {
  const autoplay = videoSelectors.useVideoAutoplayEnabled(questionId)

  const toggleVideoAutoplay = videoActions.useToggleVideoAutoplay()
  const handleToggleVideoAutoplay = (): void => {
    toggleVideoAutoplay(questionId, {
      autoPlay: !autoplay,
    })
  }
  return (
    <SettingSectionWithoutHeader>
      <ToggleWithLabel
        label="Autoplay"
        name="autoPlayCheckbox"
        checked={autoplay || false}
        onChange={handleToggleVideoAutoplay}
      />
    </SettingSectionWithoutHeader>
  )
}
