import React, {PropsWithChildren} from 'react'

interface IProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  value: string | number
  checked: boolean
}

export const RadioButton: React.FC<PropsWithChildren<IProps>> = ({
  onClick,
  value,
  children,
  checked,
}) => {
  const customCss = {
    padding: '5px',
    margin: '5px',
    background: 'transparent',
    cursor: 'pointer',
    width: '40%',
  }
  const highlightedLabelColor = checked ? '#1b87e6' : '#848484'
  return (
    <label
      style={
        customCss
          ? {...customCss, border: `2px solid ${highlightedLabelColor}`}
          : {}
      }
    >
      <button
        style={{display: 'none'}}
        role="radio"
        aria-checked={checked}
        value={value}
        onClick={onClick}
      />
      {children}
    </label>
  )
}
