import {StateCreator} from 'zustand'

export interface ISurveyStore {
  id: number
  name: string
}

export const createSurveySlice: StateCreator<ISurveyStore> = (set, get) => ({
  id: 0,
  name: '',
})
