import {
  IQuestion,
  IUid,
  IVideo,
  VideoAlignEnum,
  VideoTypeEnum,
} from 'src/survey-type-defs'
import {IHasVideo} from 'src/survey-type-defs'
import {useQuestionSelector} from '../../../store/questions/useQuestionSelectorHook'

export const videoSelectors = {
  useVideoType: (questionId: IUid): VideoTypeEnum => {
    return useQuestionSelector<IQuestion & IHasVideo, VideoTypeEnum>(
      questionId,
      q => q.video?.type || VideoTypeEnum.NONE,
    )
  },
  useVideoAlignType: (questionId: IUid): VideoAlignEnum => {
    return useQuestionSelector<IQuestion & IHasVideo, VideoAlignEnum>(
      questionId,
      q => getVideoOrThrow(q).alignType,
    )
  },
  useVideoAutoplayEnabled: (questionId: IUid): boolean => {
    return useQuestionSelector<IQuestion & IHasVideo, boolean>(
      questionId,
      q => getVideoOrThrow(q).autoPlay,
    )
  },
  useVideoShowControlsEnabled: (questionId: IUid): boolean => {
    return useQuestionSelector<IQuestion & IHasVideo, boolean>(
      questionId,
      q => getVideoOrThrow(q).showControl!,
    )
  },
  useVideoLoopEnabled: (questionId: IUid): boolean => {
    return useQuestionSelector<IQuestion & IHasVideo, boolean>(
      questionId,
      q => getVideoOrThrow(q).loop,
    )
  },
  useVideoMutedEnabled: (questionId: IUid): boolean => {
    return useQuestionSelector<IQuestion & IHasVideo, boolean>(
      questionId,
      q => getVideoOrThrow(q).muted,
    )
  },
  useVideoWidth: (questionId: IUid): number => {
    return useQuestionSelector<IQuestion & IHasVideo, number>(
      questionId,
      q => getVideoOrThrow(q).width,
    )
  },
  useVideoYoutubeLink: (questionId: IUid): string => {
    return useQuestionSelector<IQuestion & IHasVideo, string>(
      questionId,
      q => getVideoOrThrow(q).youtubeLink!,
    )
  },
  useVideoVimeoLink: (questionId: IUid): string => {
    return useQuestionSelector<IQuestion & IHasVideo, string>(
      questionId,
      q => getVideoOrThrow(q).vimeoLink!,
    )
  },
} as const

const getVideoOrThrow = (question: IQuestion & IHasVideo): IVideo => {
  const video = question.video
  if (!video) throw new Error('video cannot be undefined once enabled')

  return video
}
