import React from 'react'
import {ImageSizeEnum} from 'src/survey-type-defs'
import {SingleSelect} from '../../../../components/dropdown/SingleSelect'
import {QuestionSettingSection} from '../../../../components/questionSettingSection/QuestionSettingSection'
import {IBaseComponentProps} from '../../../../types/questionTypes/BaseQuestionProps'
import {imageActions} from '../store/imageActions'
import {imageSelectors} from '../store/imageSelectors'
import {getImageSizeTypeLabel} from '../util/imageUtil'
import {ImageCustomSize} from './ImageCustomSize'

export const ImageSize: React.FC<IBaseComponentProps> = ({questionId}) => {
  const selectedImageSizeType = imageSelectors.useImageSizeType(questionId)
  const updateImageSizeType = imageActions.useUpdateImageSizeType()
  const handleOnSizeTypeChange = (value: string): void => {
    const imageSizeType: ImageSizeEnum = +value as ImageSizeEnum
    updateImageSizeType(questionId, {imageSizeType: imageSizeType})
  }
  const filteredImageSizeEnum = Object.keys(ImageSizeEnum).filter(
    key => !isNaN(Number(key)),
  )
  return (
    <QuestionSettingSection title="Image Size">
      <SingleSelect
        ariaLabel="imageSizeTypeSelect"
        onChange={handleOnSizeTypeChange}
        value={selectedImageSizeType}
      >
        {Object.keys(filteredImageSizeEnum).map(layoutKey => {
          const layoutValue = +filteredImageSizeEnum[
            layoutKey as keyof typeof filteredImageSizeEnum
          ] as ImageSizeEnum
          return (
            <option key={layoutKey} value={layoutValue}>
              {getImageSizeTypeLabel(layoutValue)}
            </option>
          )
        })}
      </SingleSelect>
      <ImageCustomSize questionId={questionId} type={selectedImageSizeType} />
    </QuestionSettingSection>
  )
}
