import React from 'react'
import {IUid, MatrixSubtypeEnum} from 'src/survey-type-defs'
import {WysiwygTextInput} from '../../../../components/input/wysiwygTextInput/WysiwygTextInput'
import {IBaseComponentProps} from '../../../../types/questionTypes/BaseQuestionProps'
import {matrixActions} from '../store/matrixActions'
import {matrixSelectors} from '../store/matrixSelectors'
import {MatrixCheckboxAnswerList} from './MatrixCheckboxAnswerList'
import {MatrixDropdownAnswerList} from './MatrixDropdownAnswerList'
import {MatrixMultiSelectDropdownAnswerList} from './MatrixMultiSelectDropdownAnswerList'
import {MatrixRadioAnswerList} from './MatrixRadioAnswerList'
import {MatrixSliderAnswerList} from './MatrixSliderAnswerList'
import {MatrixTextAnswerList} from './MatrixTextAnswerList'

interface IProps extends IBaseComponentProps {
  rowId: IUid
  hideColumnValues: boolean
}

export const MatrixRow: React.FC<IProps> = ({
  questionId,
  rowId,
  hideColumnValues,
}) => {
  const rowText = matrixSelectors.useRowText(questionId, rowId)
  const subtype = matrixSelectors.useSubType(questionId)
  const updateRowText = matrixActions.useUpdateRowText()

  const handleUpdateRowText = (newText: string): void => {
    updateRowText(questionId, {rowId, text: newText})
  }

  return (
    <tr>
      <td>
        <WysiwygTextInput text={rowText} onChange={handleUpdateRowText} />
      </td>
      {!hideColumnValues && resolveSubtypeComponent(questionId, subtype)}
    </tr>
  )
}

const resolveSubtypeComponent = (
  questionId: IUid,
  subtype: MatrixSubtypeEnum,
): JSX.Element => {
  switch (subtype) {
    case MatrixSubtypeEnum.RADIO:
      return <MatrixRadioAnswerList questionId={questionId} />

    case MatrixSubtypeEnum.CHECKBOX:
      return <MatrixCheckboxAnswerList questionId={questionId} />

    case MatrixSubtypeEnum.DROPDOWN:
      return <MatrixDropdownAnswerList questionId={questionId} />

    case MatrixSubtypeEnum.SELECT_LIST:
      return <MatrixMultiSelectDropdownAnswerList questionId={questionId} />

    case MatrixSubtypeEnum.TEXT:
      return <MatrixTextAnswerList questionId={questionId} />

    case MatrixSubtypeEnum.SLIDER:
      return <MatrixSliderAnswerList questionId={questionId} />
    default:
      throw new Error(`Invalid matrix subtype: ${subtype}`)
  }
}
