import {useQuestionSelector} from '../../../../store/questions/useQuestionSelectorHook'
import {IUid} from 'src/survey-type-defs'
import {IAnswerOrder} from 'src/survey-type-defs'
import {ChoiceAnswerTextPositionEnum} from 'src/survey-type-defs'
import {ChoiceQuestionLayoutEnum} from 'src/survey-type-defs'
import {ChoiceSubtypeEnum} from 'src/survey-type-defs'
import {ChoiceVerticalColumnDisplayEnum} from 'src/survey-type-defs'
import {IChoiceQuestion} from 'src/survey-type-defs'

export const choiceSelectors = {
  useAnswerOrder: (questionId: IUid): IAnswerOrder => {
    return useQuestionSelector<IChoiceQuestion, IAnswerOrder>(
      questionId,
      q => q.answerOrder,
    )
  },
  useAnswerText: (questionId: IUid, answerId: IUid): string => {
    return useQuestionSelector<IChoiceQuestion, string>(
      questionId,
      q => q.answers[answerId].text,
    )
  },
  useIsAnswerDefault: (questionId: IUid, answerId: IUid): boolean => {
    return useQuestionSelector<IChoiceQuestion, boolean>(
      questionId,
      q => q.defaultAnswers[answerId],
    )
  },
  useIsExclusiveAnswer: (questionId: IUid, answerId: IUid): boolean => {
    return useQuestionSelector<IChoiceQuestion, boolean>(
      questionId,
      q => q.exclusiveAnswers[answerId],
    )
  },
  useQuestionSubType: (questionId: IUid): ChoiceSubtypeEnum => {
    return useQuestionSelector<IChoiceQuestion, ChoiceSubtypeEnum>(
      questionId,
      q => q.subtype,
    )
  },

  useQuestionLayout: (questionId: IUid): ChoiceQuestionLayoutEnum => {
    return useQuestionSelector<IChoiceQuestion, ChoiceQuestionLayoutEnum>(
      questionId,
      q => q.layout.type,
    )
  },
  useVerticalColumnCount: (questionId: IUid): number => {
    return useQuestionSelector<IChoiceQuestion, number>(
      questionId,
      q => q.layout.verticalColumnCount,
    )
  },
  useVerticalColumnDisplayType: (
    questionId: IUid,
  ): ChoiceVerticalColumnDisplayEnum => {
    return useQuestionSelector<
      IChoiceQuestion,
      ChoiceVerticalColumnDisplayEnum
    >(questionId, q => q.layout.verticalColumnDisplayType)
  },
  useAnswerCount: (questionId: IUid): number => {
    return useQuestionSelector<IChoiceQuestion, number>(
      questionId,
      q => q.answerOrder.length,
    )
  },
  useAnswerTextPosition: (questionId: IUid): ChoiceAnswerTextPositionEnum => {
    return useQuestionSelector<IChoiceQuestion, ChoiceAnswerTextPositionEnum>(
      questionId,
      q => q.layout.answerTextPosition,
    )
  },
}
