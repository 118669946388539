import {MatrixSubtypeEnum} from 'src/survey-type-defs'
import {RadioButton} from '../../../../components/buttons/RadioButton'
import {QuestionSettingSection} from '../../../../components/questionSettingSection/QuestionSettingSection'
import {IBaseComponentProps} from '../../../../types/questionTypes/BaseQuestionProps'
import {matrixActions} from '../store/matrixActions'
import {matrixSelectors} from '../store/matrixSelectors'
import {matrixUtil} from '../util/matrixUtil'

export const MatrixSubtypes: React.FC<IBaseComponentProps> = ({questionId}) => {
  const subtype = matrixSelectors.useSubType(questionId)
  const changeMatrixSubtype = matrixActions.useChangeSubtype()
  const handleSubtypeChange = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const newSubtype = event.currentTarget.value as MatrixSubtypeEnum
    changeMatrixSubtype(questionId, newSubtype)
  }
  return (
    <QuestionSettingSection title="Answer Types">
      {Object.keys(MatrixSubtypeEnum).map(subtypeKey => {
        const subtypeValue = MatrixSubtypeEnum[
          subtypeKey as keyof typeof MatrixSubtypeEnum
        ] as MatrixSubtypeEnum
        return (
          <RadioButton
            key={subtypeKey}
            checked={subtype === subtypeValue}
            value={subtypeValue}
            onClick={handleSubtypeChange}
          >
            {matrixUtil.getSubtypeLabel(subtypeValue)}
          </RadioButton>
        )
      })}
    </QuestionSettingSection>
  )
}
