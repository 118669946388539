import * as React from 'react'
import {AxiosPromise, AxiosResponse} from 'axios'
import {BACKEND_URL} from '../constants/appConstants'
import {Spinner} from '../components/spinner/Spinner'
import {IUser} from '../types/User'
import {callApi, getApi, redirectTo} from '../utils/apiUtil'
import {useAsync} from '../hooks/useAsync'

function callUserApi(): AxiosPromise<IUser> {
  return callApi<IUser>(`/auth/user`)
}

function callLogoutApi(): Promise<AxiosResponse> {
  return getApi(`/auth/logout`)
}

async function authenticateUser(): Promise<IUser | null> {
  return Promise.resolve({data: {emailAddress: 'a@a.com'}})
    .then(response => {
      return response.data
    })
    .catch(error => {
      if (error?.response.status === 403) {
        redirectTo(`${BACKEND_URL}/api/auth/oauth`)
      }
      throw error
    })
}

interface IAuthContext {
  user: IUser | null
  logout?: Function
}

const AuthContext = React.createContext<IAuthContext>({
  user: null,
})
AuthContext.displayName = 'AuthContext'

const AuthProvider: React.FC<React.PropsWithChildren> = props => {
  const {status, value: user, error} = useAsync<IUser | null>(authenticateUser)
  const isLoading = status === 'pending' || status === 'idle'
  const isSuccess = status === 'success'
  const isError = status === 'error'

  const logout = React.useCallback(() => {
    callLogoutApi()
  }, [])

  const contextValue = React.useMemo(() => ({user, logout}), [user, logout])

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return <div>Oops an error occurred. {error?.message}</div>
  }

  if (isSuccess) {
    return <AuthContext.Provider value={contextValue} {...props} />
  }

  throw new Error(`Unhandled status: ${status}`)
}

function useAuth(): {user: IUser; logout: Function} {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  const {user, logout} = context
  if (!user || !logout) {
    throw new Error(
      'Member not yet fetched. Consider using "useOptAuth" ' +
        'if you expect member to be not initialized.',
    )
  }

  return {user, logout}
}

function useOptAuth(): IAuthContext {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

function useLogout(): Function | undefined {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useLogout must be used within a AuthProvider`)
  }

  return context.logout
}

export {AuthProvider, useAuth, useOptAuth, useLogout}
