import React from 'react'

import {createAppStore} from '../store/appStore'
import {IUseAppStore} from '../types/store/UseAppStore'

const appStore = createAppStore()

const AppStoreContext = React.createContext<IUseAppStore | null>(appStore)
AppStoreContext.displayName = 'AppStoreContext'

export const AppStoreProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  /* const [appStore, setAppStore] = React.useState<IUseAppStore | null>(null)

  React.useEffect(() => {
    setAppStore(createAppStore())
  }, [])

  if (!appStore) return <FullPageSpinner message="Loading AppStore" /> */

  return (
    <AppStoreContext.Provider value={appStore}>
      {children}
    </AppStoreContext.Provider>
  )
}

export function useMyAppStore(): IUseAppStore {
  let context = React.useContext(AppStoreContext)

  if (context == null) {
    throw new Error(`useMyAppStore must be used within a AppStoreProvider`)
  }

  return context
}

export function useDispatchAppStore(): void {
  let context = React.useContext(AppStoreContext)

  if (context == null) {
    throw new Error(`useMyAppStore must be used within a AppStoreProvider`)
  }
}
