import {useQuestionSelector} from '../../../../store/questions/useQuestionSelectorHook'
import {
  ITextBoxDisplay,
  ITextCharacterLimit,
  ITextInputSize,
  ITextPrefixSuffix,
  ITextQuestionRow,
  IUid,
  TextSubtypeEnum,
} from 'src/survey-type-defs'
import {ITextQuestion} from 'src/survey-type-defs'
import {textQuestionUtil} from '../util/textQuestionUtil'

export const textSelectors = {
  useRowText: (questionId: IUid, rowId: IUid): string => {
    return useQuestionSelector<ITextQuestion, string>(
      questionId,
      q => q.rows[rowId].text,
    )
  },
  useSelectRowOrder: (questionId: IUid): string[] => {
    return useQuestionSelector<ITextQuestion, string[]>(
      questionId,
      q => q.rowsOrder,
    )
  },
  useCanDeleteRow: (questionId: IUid): boolean => {
    return useQuestionSelector<ITextQuestion, boolean>(
      questionId,
      q => q.rowsOrder.length > 1,
    )
  },
  useRows: (questionId: IUid): Record<IUid, ITextQuestionRow> => {
    return useQuestionSelector<ITextQuestion, Record<IUid, ITextQuestionRow>>(
      questionId,
      q => q.rows,
    )
  },
  useRowSubtype: (questionId: IUid, rowId: IUid): TextSubtypeEnum => {
    return useQuestionSelector<ITextQuestion, TextSubtypeEnum>(
      questionId,
      q => textQuestionUtil.getRowOrThrow(q, rowId).subtype,
    )
  },
  usePrefixSuffix: (
    questionId: IUid,
    rowId: IUid,
  ): ITextPrefixSuffix | undefined => {
    return useQuestionSelector<ITextQuestion, ITextPrefixSuffix | undefined>(
      questionId,
      q => textQuestionUtil.getRowOrThrow(q, rowId).prefixSuffix,
    )
  },
  useInputSize: (questionId: IUid, rowId: IUid): ITextInputSize => {
    return useQuestionSelector<ITextQuestion, ITextInputSize>(
      questionId,
      q => textQuestionUtil.getRowOrThrow(q, rowId).inputSize,
    )
  },
  useCharacterLimit: (
    questionId: IUid,
    rowId: IUid,
  ): ITextCharacterLimit | undefined => {
    return useQuestionSelector<ITextQuestion, ITextCharacterLimit | undefined>(
      questionId,
      q => textQuestionUtil.getRowOrThrow(q, rowId).characterLimit,
    )
  },
  useTextBoxDisplay: (questionId: IUid, rowId: IUid): ITextBoxDisplay => {
    return useQuestionSelector<ITextQuestion, ITextBoxDisplay>(
      questionId,
      q => textQuestionUtil.getRowOrThrow(q, rowId).textBoxDisplay,
    )
  },
} as const
