import React from 'react'
import {useToggle} from '../../hooks/useToggle'
import styles from './DropdownMenu.module.css'
import {useOnClickOutside} from '../../hooks/useOutsideClick'

interface IProps extends React.PropsWithChildren {
  ariaLabel: string
  icon?: string
}

export const DropdownMenu: React.FC<IProps> = ({
  children,
  ariaLabel,
  icon = '\u2304',
}) => {
  const {on, toggle, reset} = useToggle()
  const containerDivRef = React.useRef<HTMLDivElement | null>(null)
  useOnClickOutside(containerDivRef, reset)

  return (
    <div className={styles.container} ref={containerDivRef}>
      <button
        className={styles.button + (on ? ' ' + styles.buttonFocus : '')}
        onClick={toggle}
        aria-label={ariaLabel}
      >
        {icon}
      </button>
      {on && <ul className={styles.dropdownMenu}>{children}</ul>}
    </div>
  )
}
