import styles from './DropdownMenuItemButton.module.css'
import liStyles from './DropdownMenu.module.css'

interface IProps {
  title: string
  onClick: () => void
}

export const DropdownMenuItemButton: React.FC<IProps> = ({onClick, title}) => {
  const handleClick = (): void => {
    onClick()
  }

  return (
    <li className={liStyles.dropdownMenuItem}>
      <div role="button" className={styles.container} onClick={handleClick}>
        {title}
      </div>
    </li>
  )
}
