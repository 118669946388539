import {IUid} from 'src/survey-type-defs'
import {uuid} from '../../../../utils/uuidUtil'
import {IAnswerDisplayOrder} from 'src/survey-type-defs'
import {ResolveAnswerDisplayType} from 'src/survey-type-defs'
import {ChoiceQuestionLayoutEnum} from 'src/survey-type-defs'
import {ChoiceSubtypeEnum} from 'src/survey-type-defs'
import {ChoiceVerticalColumnDisplayEnum} from 'src/survey-type-defs'
import {IChoiceQuestion} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../../store/questions/useQuestionDispatchHook'

export const choiceActions = {
  useAddAnswer: (): IQuestionAction<number> => {
    return useApplyQuestionPatchAndSyncToServer<IChoiceQuestion, number>(
      (originalQuestion, positionIndex): Partial<IChoiceQuestion> => {
        const answers = originalQuestion.answers
        const answerOrder = originalQuestion.answerOrder

        const answer = {
          id: uuid(),
          text: `Option ${answerOrder.length + 1}`,
        }

        const updatedAnswers = {...answers, [answer.id]: answer}
        const updatedAnswerOrder = [
          ...answerOrder.slice(0, positionIndex),
          answer.id,
          ...answerOrder.slice(positionIndex),
        ]

        const updatedAnswerDisplayOrder = updateRandomCount(
          originalQuestion,
          answerOrder.length,
          answerOrder.length + 1,
        )
        const questionPatch = {
          answerOrder: updatedAnswerOrder,
          answers: updatedAnswers,
          answerDisplayOrder: updatedAnswerDisplayOrder,
        }
        return questionPatch
      },
    )
  },
  useUpdateAnswerText: (): IQuestionAction<{answerId: IUid; text: string}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {answerId: IUid; text: string}
    >((originalQuestion, payload): Partial<IChoiceQuestion> => {
      const answers = originalQuestion.answers
      const originalAnswer = originalQuestion.answers[payload.answerId]

      const updatedAnswer = {...originalAnswer, text: payload.text}
      const updatedAnswers = {...answers, [updatedAnswer.id]: updatedAnswer}

      const questionPatch = {
        answers: updatedAnswers,
      }
      return questionPatch
    })
  },
  useUpdateSubtype: (): IQuestionAction<{choiceSubType: ChoiceSubtypeEnum}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {choiceSubType: ChoiceSubtypeEnum}
    >((_, payload): Partial<IChoiceQuestion> => {
      const questionPatch = {
        subtype: payload.choiceSubType,
      }
      return questionPatch
    })
  },
  useUpdateQuestionLayout: (): IQuestionAction<{
    layoutType: ChoiceQuestionLayoutEnum
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {layoutType: ChoiceQuestionLayoutEnum}
    >((originalQuestion, payload): Partial<IChoiceQuestion> => {
      const questionLayout = originalQuestion.layout
      const updatedQuestionLayout = {
        ...questionLayout,
        type: payload.layoutType,
      }

      const questionPatch = {
        layout: updatedQuestionLayout,
      }
      return questionPatch
    })
  },
  useUpdateVerticalColumCount: (): IQuestionAction<{
    verticalColumnCount: number
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {verticalColumnCount: number}
    >((originalQuestion, payload): Partial<IChoiceQuestion> => {
      const questionLayout = originalQuestion.layout
      const updatedQuestionLayout = {
        ...questionLayout,
        verticalColumnCount: payload.verticalColumnCount,
      }

      const questionPatch = {
        layout: updatedQuestionLayout,
      }
      return questionPatch
    })
  },
  useUpdateVerticalColumDisplayType: (): IQuestionAction<{
    verticalColumnDisplayType: ChoiceVerticalColumnDisplayEnum
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {verticalColumnDisplayType: ChoiceVerticalColumnDisplayEnum}
    >((originalQuestion, payload): Partial<IChoiceQuestion> => {
      const questionLayout = originalQuestion.layout
      const updatedQuestionLayout = {
        ...questionLayout,
        verticalColumnDisplayType: payload.verticalColumnDisplayType,
      }

      const questionPatch = {
        layout: updatedQuestionLayout,
      }
      return questionPatch
    })
  },
  useUpdateAnswerTextPosition: (): IQuestionAction<{
    answerTextPosition: number
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {answerTextPosition: number}
    >((originalQuestion, payload): Partial<IChoiceQuestion> => {
      const questionLayout = originalQuestion.layout
      const updatedQuestionLayout = {
        ...questionLayout,
        answerTextPosition: payload.answerTextPosition,
      }

      const questionPatch = {
        layout: updatedQuestionLayout,
      }
      return questionPatch
    })
  },
  useUpdateDefaultAnswers: (): IQuestionAction<{
    answerId: IUid
    isDefault: boolean
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {answerId: IUid; isDefault: boolean}
    >((originalQuestion, payload): Partial<IChoiceQuestion> => {
      const defaultAnswers = originalQuestion.defaultAnswers
      const updateDefaultAnswers = {
        ...defaultAnswers,
        [payload.answerId]: payload.isDefault,
      }

      const questionPatch = {
        defaultAnswers: updateDefaultAnswers,
      }
      return questionPatch
    })
  },
  useUpdateExclusiveAnswers: (): IQuestionAction<{
    answerId: IUid
    isExclusive: boolean
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {answerId: IUid; isExclusive: boolean}
    >((originalQuestion, payload): Partial<IChoiceQuestion> => {
      const exclusiveAnswers = originalQuestion.exclusiveAnswers
      const updateExclusiveAnswers = {
        ...exclusiveAnswers,
        [payload.answerId]: payload.isExclusive,
      }

      const questionPatch = {
        exclusiveAnswers: updateExclusiveAnswers,
      }
      return questionPatch
    })
  },
  useDeleteAnswer: (): IQuestionAction<{
    answerId: IUid
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IChoiceQuestion,
      {answerId: IUid}
    >((originalQuestion, payload): Partial<IChoiceQuestion> => {
      const updatedAnswerOrder = originalQuestion.answerOrder.filter(
        answerId => answerId !== payload.answerId,
      )

      const updatedAnswers = {...originalQuestion.answers}
      delete updatedAnswers[payload.answerId]

      const updatedAnswerDisplayOrder = updateRandomCount(
        originalQuestion,
        originalQuestion.answerOrder.length,
        originalQuestion.answerOrder.length - 1,
      )

      const questionPatch = {
        answers: updatedAnswers,
        answerOrder: updatedAnswerOrder,
        answerDisplayOrder: updatedAnswerDisplayOrder,
      }
      return questionPatch
    })
  },
}

const updateRandomCount = (
  question: IChoiceQuestion,
  currentAnswerCount: number,
  newCount: number,
): IAnswerDisplayOrder => {
  if (!question.answerDisplayOrder) {
    return question.answerDisplayOrder!
  }
  if (
    ResolveAnswerDisplayType.isRandomType(question.answerDisplayOrder!.type) &&
    currentAnswerCount === question.answerDisplayOrder!.randomAnswerCount
  ) {
    return {
      ...question.answerDisplayOrder!,
      randomAnswerCount: newCount,
    }
  }
  return question.answerDisplayOrder
}
