import React, {useState} from 'react'
import {ImageSizeEnum, IUid} from 'src/survey-type-defs'
import {NumericInput} from '../../../../components/input/NumericInput'
import {imageActions} from '../store/imageActions'
import {imageSelectors} from '../store/imageSelectors'

interface IProps {
  questionId: IUid
  type: ImageSizeEnum
}
export const ImageCustomSize: React.FC<IProps> = ({questionId, type}) => {
  const imageCustomWidth = imageSelectors.useImageCustomWidth(questionId)
  const imageCustomHeight = imageSelectors.useImageCustomHeight(questionId)
  const updateCustomWidth = imageActions.useUpdateImageCustomWidth()
  const updateCustomHeight = imageActions.useUpdateImageCustomHeight()
  const [isValidWidth, setValidWidth] = useState(true)
  const [isValidHeight, setValidHeight] = useState(true)
  const handleOnCustomWidthChange = (customWidth: number): void => {
    if (customWidth >= 100 && customWidth <= 500) {
      updateCustomWidth(questionId, {
        customWidth: customWidth,
      })
      setValidWidth(true)
    } else {
      setValidWidth(false)
    }
  }
  const handleOnCustomHeightChange = (customHeight: number): void => {
    if (customHeight >= 100 && customHeight <= 500) {
      updateCustomHeight(questionId, {
        customHeight: customHeight,
      })
      setValidHeight(true)
    } else {
      setValidHeight(false)
    }
  }
  const canDisplayImageCustomSize = ImageSizeEnum.CUSTOM === type
  if (!canDisplayImageCustomSize) {
    return null
  }
  return (
    <div>
      <label>
        <NumericInput
          id="image-custom-width"
          value={imageCustomHeight | 0}
          placeholder="Height"
          minValue={100}
          maxValue={500}
          onChange={handleOnCustomHeightChange}
        />
        px
      </label>
      <label>
        <NumericInput
          id="image-custom-height"
          value={imageCustomWidth | 0}
          placeholder="Width"
          minValue={100}
          maxValue={500}
          onChange={handleOnCustomWidthChange}
        />
        px
      </label>
      {!(isValidWidth && isValidHeight) && (
        <span aria-label="customSizeErrorSpan">
          Value should be between 100 and 500
        </span>
      )}
    </div>
  )
}
