import React from 'react'

import {IUid, QuestionTipEnum} from 'src/survey-type-defs'
import {TextInput} from '../../../components/input/TextInput'
import {questionTipActions} from '../store/questionTipActions'
import {questionTipSelectors} from '../store/questionTipSelectors'

interface IProps {
  questionId: IUid
  selectedTipType: QuestionTipEnum
}
export const QuestionTipLinkText: React.FC<IProps> = ({
  questionId,
  selectedTipType,
}) => {
  const selectedTipLinkText =
    questionTipSelectors.useQuestionTipLinkText(questionId)

  const updateQuestionTipLinkText = questionTipActions.useUpdateHelpLinkText()
  const handleOnQuestionTipLinkTextChange = (helpLinkText: string): void => {
    updateQuestionTipLinkText(questionId, {
      helpLinkText: helpLinkText,
    })
  }
  if (selectedTipType !== QuestionTipEnum.LINK) {
    return null
  }

  return (
    <TextInput
      value={selectedTipLinkText}
      placeholder="Link Text"
      onChange={handleOnQuestionTipLinkTextChange}
    />
  )
}
