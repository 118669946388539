import {LinkButton} from '../../../../components/buttons/LinkButton'
import {IUid} from 'src/survey-type-defs'
import {IAnswerOrder} from 'src/survey-type-defs'
import {useAddAnswer} from '../hooks/useAddAnswer'
import {Answer} from './Answer'
import {AnswerDynamicComment} from '../../../dynamicComment/AnswerDynamicComment'
import {ToggleDefaultAnswerMenuItem} from './ToggleDefaultAnswerMenuItem'
import {ChoiceExclusiveAnswerOption} from './ChoiceExclusiveAnswerOption'
import {RemoveAnswerMenuItem} from './RemoveAnswerMenuItem'

interface IProps {
  questionId: IUid
  answerOrder: IAnswerOrder
}

export const ChoiceCheckboxAnswerList: React.FC<IProps> = ({
  questionId,
  answerOrder,
}) => {
  const handleAddAnswer = useAddAnswer(questionId, answerOrder.length)
  return (
    <div>
      {answerOrder.map((answerId, index) => (
        <div key={answerId}>
          <input type="checkbox" aria-label="checkboxAnswer" />
          <Answer
            questionId={questionId}
            answerId={answerId}
            sequenceNum={index + 1}
          />
          <ToggleDefaultAnswerMenuItem
            questionId={questionId}
            answerId={answerId}
          />
          <ChoiceExclusiveAnswerOption
            questionId={questionId}
            answerId={answerId}
          />
          <AnswerDynamicComment questionId={questionId} answerId={answerId} />
          <RemoveAnswerMenuItem questionId={questionId} answerId={answerId} />
        </div>
      ))}
      <div>
        <LinkButton onClick={handleAddAnswer}>Add Option</LinkButton>
      </div>
    </div>
  )
}
