import React from 'react'
import {MatrixSubtypeEnum} from 'src/survey-type-defs'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {MatrixColumnHeaderRow} from './components/MatrixColumnHeaderRow'
import {MatrixRow} from './components/MatrixRow'
import {matrixColumnHeaderConfig} from './config/matrixColumnHeaderConfig'
import {matrixSelectors} from './store/matrixSelectors'

export const MatrixQuestion: React.FC<IBaseComponentProps> = ({questionId}) => {
  const subtype = matrixSelectors.useSubType(questionId)
  const canDisplayColumnHeaderForSubtype = canDisplayColumnHeaderFor(subtype)
  const [editColumnHeaderOnPage, setEditColumnHeaderOnPage] =
    React.useState(false)
  const rowOrder = matrixSelectors.useRowOrder(questionId)

  React.useEffect(() => {
    // Whenever subtype is changed we reset the on-page editing
    setEditColumnHeaderOnPage(false)
  }, [subtype])

  const hideColumnValues =
    !canDisplayColumnHeaderForSubtype && editColumnHeaderOnPage
  // header is hidden and on-page editing is not started
  const showEditColumnHeaderOnPageButton = !canDisplayColumnHeaderForSubtype
  const editOnPageButtonLabel = editColumnHeaderOnPage
    ? 'Done editing'
    : 'Edit on-page'

  return (
    <div style={{maxWidth: '100%', overflow: 'auto'}}>
      <table width="100%">
        {(canDisplayColumnHeaderForSubtype || editColumnHeaderOnPage) && (
          <MatrixColumnHeaderRow questionId={questionId} />
        )}
        <tbody>
          {rowOrder.map(rowId => {
            return (
              <MatrixRow
                questionId={questionId}
                rowId={rowId}
                key={rowId}
                hideColumnValues={hideColumnValues}
              />
            )
          })}
        </tbody>
      </table>
      <div>
        {showEditColumnHeaderOnPageButton && (
          <button
            onClick={(): void =>
              setEditColumnHeaderOnPage(!editColumnHeaderOnPage)
            }
          >
            {editOnPageButtonLabel}
          </button>
        )}
      </div>
    </div>
  )
}

const canDisplayColumnHeaderFor = (subtype: MatrixSubtypeEnum): boolean => {
  const config = matrixColumnHeaderConfig[subtype]
  if (!config)
    throw new Error(`matrixColumnHeaderConfig not set for subtype: ${subtype}`)

  return config.header
}
