import styles from './Label.module.css'
interface IProps {
  text: string
  htmlFor?: string
}

export const Label: React.FC<IProps> = ({text, htmlFor}) => {
  return (
    <label htmlFor={htmlFor} className={styles['toggle-label']}>
      {text}
    </label>
  )
}
