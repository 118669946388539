import React from 'react'
import {TextInput} from '../../../../components/input/TextInput'
import {QuestionSettingSection} from '../../../../components/questionSettingSection/QuestionSettingSection'
import {textActions} from '../store/textActions'
import {textSelectors} from '../store/TextSelectors'
import {IBaseRowSettingProps} from '../types/IBaserRowSettingProps'

export const TextCharacterLimit: React.FC<IBaseRowSettingProps> = ({
  questionId,
  rowId,
}) => {
  const minMax = textSelectors.useCharacterLimit(questionId, rowId)
  const changeMin = textActions.useChangeCharacterLimitMin()
  const changeMax = textActions.useChangeCharacterLimitMax()

  const handleChangeMin = (min: string): void => {
    changeMin(questionId, {min: +min, rowId})
  }

  const handleChangeMax = (max: string): void => {
    changeMax(questionId, {max: +max, rowId})
  }

  return (
    <QuestionSettingSection title="Character Limit (Optional)">
      <div>
        <label htmlFor="text-min">Min</label>
        <TextInput
          id="text-min"
          minLength={0}
          value={minMax?.min || ''}
          onChange={handleChangeMin}
        />
      </div>
      <div>
        <label htmlFor="text-max">Max</label>
        <TextInput
          id="text-max"
          minLength={1}
          value={minMax?.max || ''}
          onChange={handleChangeMax}
        />
      </div>
    </QuestionSettingSection>
  )
}
