import {IQuestion} from 'src/survey-type-defs'
import {StateCreator, StoreApi} from 'zustand'
import {IUid} from 'src/survey-type-defs'
import {IQuestionMap} from './types/IQuestionMap'
import {IUseAppStore} from '../../types/store/UseAppStore'
import {ICreateQuestionPatch} from './useQuestionDispatchHook'

export interface IQuestionsStore {
  questions: IQuestionMap
  questionsActions: {
    addQuestion: (question: IQuestion) => void
    updateQuestionText: (questionId: string, text: string) => void
    updateQuestionOrderNum: (questionId: string, orderNum: string) => void
    toggleDelete: (questionId: string) => void
    applyUpdate: <T extends IQuestion, P>(
      appStore: IUseAppStore,
      questionId: IUid,
      payload: P,
      action: ICreateQuestionPatch<T, P>,
    ) => [IQuestion, Partial<IQuestion>]
    applyServerUpdates: (questionId: string, patch: Partial<IQuestion>) => void
  }
}

export const createQuestionsSlice: StateCreator<IQuestionsStore> = (
  set,
  get,
) => ({
  questions: {},
  questionsActions: {
    addQuestion: (question: IQuestion): void =>
      set(state => {
        const updatedQuestions = {
          ...state.questions,
          [question.id]: question,
        }
        return {
          questions: updatedQuestions,
        }
      }),
    updateQuestionText: (questionId: string, text: string): void => {
      updateQuestionInStore(
        set,
        get(),
        questionId,
        question => (question.text = text),
      )
    },

    updateQuestionOrderNum: (questionId: string, orderNum: string): void => {
      updateQuestionInStore(
        set,
        get(),
        questionId,
        question => (question.orderNum = orderNum),
      )
    },
    toggleDelete: (questionId: string): void => {
      updateQuestionInStore(
        set,
        get(),
        questionId,
        question => (question.deleted = !question.deleted),
      )
    },
    applyUpdate: <T extends IQuestion, P>(
      appStore: IUseAppStore,
      questionId: IUid,
      payload: P,
      action: ICreateQuestionPatch<T, P>,
    ): [IQuestion, Partial<IQuestion>] => {
      const originalQuestions = appStore.getState().questions
      const originalQuestion = originalQuestions[questionId] as T
      if (!originalQuestion)
        throw new Error(`Question not found for id: ${questionId}`)

      const questionPatch = action({...originalQuestion}, payload)

      originalQuestions[questionId] = {
        ...originalQuestion,
        ...questionPatch,
      }
      appStore.setState({questions: originalQuestions})

      return [originalQuestion, questionPatch]
    },
    applyServerUpdates: (
      questionId: string,
      update: Partial<IQuestion>,
    ): void => {
      const originalQuestions = get().questions
      const originalQuestion = originalQuestions[questionId]
      if (!originalQuestion)
        throw new Error(`Question not found for id: ${questionId}`)
      originalQuestions[questionId] = {
        ...originalQuestion,
        ...update,
      }

      set({questions: originalQuestions})
    },
  },
})

const updateQuestionInStore = (
  set: StoreApi<IQuestionsStore>['setState'],
  state: IQuestionsStore,
  questionId: IUid,
  updateQuestion: (question: IQuestion) => void,
): void => {
  const updatedState = applyUpdatesAndCreateNewState(
    state,
    questionId,
    question => {
      updateQuestion(question)
      return question
    },
  )
  set({
    questions: updatedState.questions,
  })
}

const applyUpdatesAndCreateNewState = (
  state: IQuestionsStore,
  questionId: string,
  applyUpdates: (question: IQuestion) => IQuestion,
): {questions: IQuestionMap} => {
  const originalQuestion = state.questions[questionId]
  if (!originalQuestion)
    throw new Error(`Question not found for id: ${questionId}`)

  const updatedQuestion = applyUpdates({...originalQuestion})
  const updatedQuestions = {
    ...state.questions,
    [questionId]: updatedQuestion,
  }

  return {questions: updatedQuestions}
}
