import {defaultQuestionConfig} from '../../../../types/questionTypes/common/defaultQuestionConfig'
import {QuestionTypeEnum} from 'src/survey-type-defs'
import {IQuestionConfig} from '../../../../types/questionTypes/questionConfig'
import {uuid} from '../../../../utils/uuidUtil'
import {ISmileyQuestion} from 'src/survey-type-defs'
import {SmileyQuestion} from '../SmileyQuestion'
import {SmileyScaleEnum} from 'src/survey-type-defs'
import {ISmileyAnswer} from 'src/survey-type-defs'
import {SmileyAnswerOrderEnum} from 'src/survey-type-defs'
import {SmileyPointScaleEnum} from 'src/survey-type-defs'
import {QuestionDisplayEnum} from 'src/survey-type-defs'
import {SmileyPointScaleSetting} from '../setting/components/SmileyPointScaleSetting'
import {SmileyAnswerOrderSetting} from '../setting/components/SmileyAnswerOrderSetting'
import {QuestionTip} from '../../../questionTip/QuestionTip'
import {VideoSetting} from '../../../videoSetting/VideoSetting'

const smileyAnswerScaleLabel = {
  [SmileyScaleEnum.VERY_UNSATISFIED]: 'Very Unsatisfied',
  [SmileyScaleEnum.UNSATISFIED]: 'Unsatisfied',
  [SmileyScaleEnum.NEUTRAL]: 'Neutral',
  [SmileyScaleEnum.SATISFIED]: 'Satisfied',
  [SmileyScaleEnum.VERY_SATISFIED]: 'Very Satisfied',
}

export const smileyQuestionConfig: IQuestionConfig<ISmileyQuestion> = {
  ...defaultQuestionConfig,
  dynamicCommentLabelsEnabled: false,
  component: SmileyQuestion,
  settingComponents: [
    SmileyPointScaleSetting,
    SmileyAnswerOrderSetting,
    QuestionTip,
    VideoSetting,
  ],
  factory: (
    blockId: string,
    orderNum: string,
    sequenceNum: number,
  ): ISmileyQuestion => createSmileyQuestion(blockId, orderNum, sequenceNum),
}

const createSmileyQuestion = (
  blockId: string,
  orderNum: string,
  sequenceNum: number,
): ISmileyQuestion => {
  const answers = []
  for (const scale in SmileyScaleEnum) {
    /* 
      Because iterating enum keys gives numeric values as well.
      Consider below example:

      enum Color { Red }      
      for (const value in Color) { console.log('Value: ' + value);}      
      Value: 0      
      Value: Red      
    */
    if (!isNaN(Number(scale)))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      answers.push(createSmileyAnswer(scale as any as SmileyScaleEnum))
  }

  return {
    id: uuid(),
    text: `Question ${sequenceNum}`,
    code: `Q${orderNum}`,
    deleted: false,
    blockId,
    type: QuestionTypeEnum.SMILEY,
    orderNum,
    answers: answers,
    answerOrder: SmileyAnswerOrderEnum.NEGATIVE_TO_POSITIVE,
    pointScale: SmileyPointScaleEnum.MAX,
    questionDisplay: {type: QuestionDisplayEnum.SHOW_QUESTION},
  }
}

const createSmileyAnswer = (scale: SmileyScaleEnum): ISmileyAnswer => ({
  id: uuid(),
  text: smileyAnswerScaleLabel[scale],
  scale,
})
