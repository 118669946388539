import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {matrixSelectors} from '../store/matrixSelectors'

interface IProps {
  questionId: IUid
}

export const MatrixRadioAnswerList: React.FC<IProps> = ({questionId}) => {
  const columnOrder = matrixSelectors.useColumnOrder(questionId)

  return (
    <>
      {columnOrder.map(columnId => (
        <td key={columnId}>
          <input type="radio" />
        </td>
      ))}
    </>
  )
}
