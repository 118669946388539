import React from 'react'
import {ImageSubtypeEnum} from 'src/survey-type-defs'
import {RadioButton} from '../../../../components/buttons/RadioButton'
import {QuestionSettingSection} from '../../../../components/questionSettingSection/QuestionSettingSection'
import {IBaseComponentProps} from '../../../../types/questionTypes/BaseQuestionProps'
import {imageActions} from '../store/imageActions'
import {imageSelectors} from '../store/imageSelectors'
import {getImageSubtypeLabel} from '../util/imageUtil'

export const ImageSubTypes: React.FC<IBaseComponentProps> = ({questionId}) => {
  const selectedImageSubType = imageSelectors.useQuestionSubType(questionId)
  const updateImageSubType = imageActions.useUpdateSubtype()
  const handleOnSubTypeChange = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const imageSubtype: ImageSubtypeEnum = event.currentTarget
      .value as ImageSubtypeEnum
    updateImageSubType(questionId, {imageSubType: imageSubtype})
  }
  return (
    <>
      <QuestionSettingSection title="Answer Types">
        {Object.keys(ImageSubtypeEnum).map(subtypeKey => {
          const subtypeValue = ImageSubtypeEnum[
            subtypeKey as keyof typeof ImageSubtypeEnum
          ] as ImageSubtypeEnum
          return (
            <RadioButton
              key={subtypeKey}
              checked={selectedImageSubType === subtypeValue}
              value={subtypeValue}
              onClick={handleOnSubTypeChange}
            >
              {getImageSubtypeLabel(subtypeValue)}
            </RadioButton>
          )
        })}
      </QuestionSettingSection>
    </>
  )
}
