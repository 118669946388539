import {StateCreator} from 'zustand'
import {IUid} from 'src/survey-type-defs'

export interface IUiStore {
  activeQuestionId?: IUid
  activeQuestionScrollTop: number
  uiActions: {
    removeActiveQuestionId(): void
  }
}

export const createUiSlice: StateCreator<IUiStore> = (set, get) => ({
  activeQuestionScrollTop: 0,
  uiActions: {
    removeActiveQuestionId: (): void => {
      set(() => ({activeQuestionId: undefined}))
    },
  },
})
