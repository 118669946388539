import {IUid} from 'src/survey-type-defs'
import {IAnswerOrder} from 'src/survey-type-defs'

interface IProps {
  questionId: IUid
  answerOrder: IAnswerOrder
}

export const ImageRadioAnswerList: React.FC<IProps> = ({
  questionId,
  answerOrder,
}) => {
  return <div>Image radio</div>
}
