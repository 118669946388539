import React from 'react'
import {QuestionSettingHeader} from '../questionSettingHeader/QuestionSettingHeader'

interface IProps extends React.PropsWithChildren {
  title: string
}

export const QuestionSettingSection: React.FC<IProps> = ({title, children}) => {
  return (
    <>
      <QuestionSettingHeader title={title} />
      <div
        style={{
          marginTop: '10px',
          display: 'flex',
          flexWrap: 'wrap',
          marginBottom: '15px',
        }}
      >
        {children}
      </div>
    </>
  )
}
