import React from 'react'
import {ToggleWithLabel} from '../../../components/checkbox/ToggleWithLabel'
import {SettingSectionWithoutHeader} from '../../../components/questionSettingSection/SettingSectionWithoutHeader'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {videoActions} from '../store/videoActions'
import {videoSelectors} from '../store/videoSelectors'

export const VideoMuted: React.FC<IBaseComponentProps> = ({questionId}) => {
  const muted = videoSelectors.useVideoMutedEnabled(questionId)

  const toggleVideoMuted = videoActions.useToggleVideoMuted()
  const handleToggleVideoMuted = (): void => {
    toggleVideoMuted(questionId, {
      muted: !muted,
    })
  }
  return (
    <SettingSectionWithoutHeader>
      <ToggleWithLabel
        label="Muted"
        name="mutedCheckbox"
        checked={muted || false}
        onChange={handleToggleVideoMuted}
      />
    </SettingSectionWithoutHeader>
  )
}
