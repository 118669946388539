import React from 'react'
import {ToggleWithLabel} from '../../components/checkbox/ToggleWithLabel'
import {SettingSectionWithoutHeader} from '../../components/questionSettingSection/SettingSectionWithoutHeader'
import {IBaseComponentProps} from '../../types/questionTypes/BaseQuestionProps'
import {QuestionTipType} from './components/QuestionTipType'
import {questionTipActions} from './store/questionTipActions'
import {questionTipSelectors} from './store/questionTipSelectors'

export const QuestionTip: React.FC<IBaseComponentProps> = ({questionId}) => {
  const questionTipEnabled =
    questionTipSelectors.useQuestionTipEnabled(questionId)

  const updateQuestionTipToggle = questionTipActions.useToggleQuestionTip()
  const handleQuestionTipToggle = (): void => {
    updateQuestionTipToggle(questionId, {
      enabled: !questionTipEnabled,
    })
  }

  return (
    <SettingSectionWithoutHeader>
      <ToggleWithLabel
        label="Question Tip"
        name="questionTipCheckbox"
        checked={questionTipEnabled || false}
        onChange={handleQuestionTipToggle}
      />
      {questionTipEnabled && <QuestionTipType questionId={questionId} />}
    </SettingSectionWithoutHeader>
  )
}
