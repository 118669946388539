import {QuestionTipEnum} from 'src/survey-type-defs'

export const getQuestionTipTypeLabel = (type: QuestionTipEnum): string => {
  switch (type) {
    case QuestionTipEnum.ICON:
      return 'Image Icon'
    case QuestionTipEnum.LINK:
      return 'Text Hyperlink'
  }
}
