import React from 'react'
import {RadioButton} from '../../../../../components/buttons/RadioButton'
import {IUid} from 'src/survey-type-defs'
import {choiceActions} from '../../store/choiceActions'
import {choiceSelectors} from '../../store/choiceSelectors'
import {ChoiceVerticalColumnDisplayEnum} from 'src/survey-type-defs'
import {getVerticalColumnDisplayLabel} from '../../util/choiceUtil'

interface IProps {
  questionId: IUid
  verticalColumnCount: number
}

export const ChoiceVerticalColumnDisplayType: React.FC<IProps> = ({
  questionId,
  verticalColumnCount,
}) => {
  const verticalColumnDisplayType =
    choiceSelectors.useVerticalColumnDisplayType(questionId)
  const updateVerticalColumnDisplayType =
    choiceActions.useUpdateVerticalColumDisplayType()

  const handleVerticalColumnDisplayType = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const verticalColumnDisplayType: ChoiceVerticalColumnDisplayEnum = +event
      .currentTarget.value as ChoiceVerticalColumnDisplayEnum
    updateVerticalColumnDisplayType(questionId, {
      verticalColumnDisplayType: verticalColumnDisplayType,
    })
  }
  const canDisplayVerticalColumnDisplayType = verticalColumnCount > 1
  const filteredChoiceVerticalColumnDisplayEnum = Object.keys(
    ChoiceVerticalColumnDisplayEnum,
  ).filter(key => !isNaN(Number(key)))
  return (
    <>
      {canDisplayVerticalColumnDisplayType && (
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          {Object.keys(filteredChoiceVerticalColumnDisplayEnum).map(
            verticalDisplayEnumKey => {
              const verticalColumnDisplayEnumValue =
                +filteredChoiceVerticalColumnDisplayEnum[
                  verticalDisplayEnumKey as keyof typeof filteredChoiceVerticalColumnDisplayEnum
                ] as ChoiceVerticalColumnDisplayEnum
              return (
                <RadioButton
                  key={verticalDisplayEnumKey}
                  checked={
                    verticalColumnDisplayType === verticalColumnDisplayEnumValue
                  }
                  value={verticalColumnDisplayEnumValue}
                  onClick={handleVerticalColumnDisplayType}
                >
                  {getVerticalColumnDisplayLabel(
                    verticalColumnDisplayEnumValue,
                  )}
                </RadioButton>
              )
            },
          )}
        </div>
      )}
    </>
  )
}
