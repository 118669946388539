import React from 'react'
import {Label} from '../../../../components/buttons/label/Label'
import {SingleSelect} from '../../../../components/dropdown/SingleSelect'
import {TextInput} from '../../../../components/input/TextInput'
import {QuestionSettingSection} from '../../../../components/questionSettingSection/QuestionSettingSection'
import {textActions} from '../store/textActions'
import {textSelectors} from '../store/TextSelectors'
import {IBaseRowSettingProps} from '../types/IBaserRowSettingProps'

const MIN_HEIGHT = 1
const MAX_HEIGHT = 20

const WIDTHS = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]

export const TextInputSize: React.FC<IBaseRowSettingProps> = ({
  questionId,
  rowId,
}) => {
  const {height, width} = textSelectors.useInputSize(questionId, rowId)
  const changeInputSize = textActions.useChangeInputSize()

  const handleChangeHeight = (height: string): void => {
    changeInputSize(questionId, {height: +height, width, rowId})
  }

  const handleChangeWidth = (value: string): void => {
    changeInputSize(questionId, {width: +value, height, rowId})
  }

  return (
    <QuestionSettingSection title="Text Input Size Setting">
      <div>
        <Label htmlFor="text-input-width" text="Cell Height (Rows)" />
        <TextInput
          id="text-prefix"
          minLength={MIN_HEIGHT}
          maxLength={MAX_HEIGHT}
          value={height || ''}
          onChange={handleChangeHeight}
        />
      </div>
      <div>
        <Label htmlFor="text-input-height" text="Cell Width" />
        <SingleSelect
          id="text-input-height"
          onChange={handleChangeWidth}
          value={width || ''}
        >
          {WIDTHS.map(value => (
            <option key={value} value={value}>
              {value} %
            </option>
          ))}
        </SingleSelect>
      </div>
    </QuestionSettingSection>
  )
}
