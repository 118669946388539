import React from 'react'
import {RadioButton} from '../../../../../components/buttons/RadioButton'
import {IBaseComponentProps} from '../../../../../types/questionTypes/BaseQuestionProps'
import {choiceActions} from '../../store/choiceActions'
import {choiceSelectors} from '../../store/choiceSelectors'
import {ChoiceSubtypeEnum} from 'src/survey-type-defs'
import {getSubtypeLabel} from '../../util/choiceUtil'
import {QuestionSettingSection} from '../../../../../components/questionSettingSection/QuestionSettingSection'

export const ChoiceSubTypes: React.FC<IBaseComponentProps> = ({questionId}) => {
  const selectedChoiceSubType = choiceSelectors.useQuestionSubType(questionId)
  const updateChoiceSubType = choiceActions.useUpdateSubtype()
  const handleOnSubTypeChange = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const choiceSubtype: ChoiceSubtypeEnum = event.currentTarget
      .value as ChoiceSubtypeEnum
    updateChoiceSubType(questionId, {choiceSubType: choiceSubtype})
  }
  return (
    <QuestionSettingSection title="Answer Types">
      {Object.keys(ChoiceSubtypeEnum).map(subtypeKey => {
        const subtypeValue = ChoiceSubtypeEnum[
          subtypeKey as keyof typeof ChoiceSubtypeEnum
        ] as ChoiceSubtypeEnum
        return (
          <RadioButton
            key={subtypeKey}
            checked={selectedChoiceSubType === subtypeValue}
            value={subtypeValue}
            onClick={handleOnSubTypeChange}
          >
            {getSubtypeLabel(subtypeValue)}
          </RadioButton>
        )
      })}
    </QuestionSettingSection>
  )
}
