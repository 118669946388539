import {IQuestionMap} from './questions/types/IQuestionMap'
import {IBlocksMap} from './blocks/types/IBlocksMap'
import {IBlockOrder, IQuestionOrder} from 'src/survey-type-defs'
import {
  fromAddQuestionPayload,
  fromDeleteQuestionPayload,
  fromReorderQuestionPayload,
  IServerUpdate,
} from './../socket/types/IServerUpdate'
import {IDeleteQuestionServerPayload} from './../socket/types/IDeleteQuestionServerPayload'
import {IReorderQuestionServerPayload} from './../socket/types/IReorderQuestionServerPayload'
import {IAddQuestionServerPayload} from './../socket/types/IAddQuestionServerPayload'
import {create} from 'zustand'
import {ISurvey} from '../types/Survey'
import {generateFractionalIndexBetween} from '../utils/fractionalUtil'
import {createQuestionsSlice, IQuestionsStore} from './questions/questionsSlice'
import {createBlocksSlice, IBlocksStore} from './blocks/blocksSlice'
import {createSurveySlice, ISurveyStore} from './survey/surveySlice'
import {IUseAppStore} from '../types/store/UseAppStore'
import {questionConfig} from '../types/questionTypes/questionConfig'
import {createUiSlice, IUiStore} from './ui/uiSlice'
import {
  IAddQuestionPayload,
  IDeleteQuestionPayload,
  IReorderQuestionPayload,
} from './shared/useSharedDispatchHook'
import {loggerUtil} from '../test/utils/loggerUtil'

export interface ISharedStore {
  actions: {
    shared: {
      initSurvey: (survey: ISurvey) => void
      //TODO: need to move these to update block flow
      addQuestionToBlock: (payload: IAddQuestionPayload) => IServerUpdate
      reorderQuestionInBlock: (
        payload: IReorderQuestionPayload,
      ) => IServerUpdate
      deleteQuestion: (payload: IDeleteQuestionPayload) => IServerUpdate

      addQuestionServerUpdate: (payload: IAddQuestionServerPayload) => void
      reorderQuestionServerUpdate: (
        payload: IReorderQuestionServerPayload,
      ) => void
      deleteQuestionServerUpdate: (
        payload: IDeleteQuestionServerPayload,
      ) => void
    }
  }
}

//TODO: move shared stuff to survey slice
export const createAppStore = (): IUseAppStore => {
  return create<
    ISharedStore & ISurveyStore & IQuestionsStore & IBlocksStore & IUiStore
  >((set, get, ...others) => ({
    ...createUiSlice(set, get, ...others),
    ...createSurveySlice(set, get, ...others),
    ...createBlocksSlice(set, get, ...others),
    ...createQuestionsSlice(set, get, ...others),
    actions: {
      shared: {
        initSurvey: (survey: ISurvey): void => {
          loggerUtil.logOnlyDev(survey)
          set({
            id: survey.id,
            name: survey.name,
            blockOrder: computeBlockOrder(survey.blocks),
            blocks: fetchUpdatedBlocksWithQuestionOrder(
              survey.blocks,
              survey.questions,
            ),
            questions: survey.questions,
          })
        },
        addQuestionToBlock: (payload: IAddQuestionPayload): IServerUpdate => {
          const {addAtIndex, blockId, type} = payload
          const block = get().blocks[blockId]
          const sequenceNum = block.questionOrder.length + 1
          const orderNum = generateOrderNumForInsertionAt(
            addAtIndex,
            block.questionOrder,
          )

          const newQuestion = questionConfig.getFactory(type)(
            blockId,
            orderNum,
            sequenceNum,
          )

          get().questionsActions.addQuestion(newQuestion)
          get().blocksActions.addQuestion(newQuestion)

          return fromAddQuestionPayload(blockId, newQuestion)
        },
        reorderQuestionInBlock: (
          payload: IReorderQuestionPayload,
        ): IServerUpdate => {
          const {questionId, blockId, positionIndex} = payload
          const block = get().blocks[blockId]

          const orderNum = generateOrderNumForInsertionAt(
            positionIndex,
            block.questionOrder,
          )

          get().questionsActions.updateQuestionOrderNum(questionId, orderNum)
          get().blocksActions.updateQuestionOrderNumInBlock(
            blockId,
            questionId,
            orderNum,
          )
          return fromReorderQuestionPayload(blockId, questionId, orderNum)
        },
        deleteQuestion: (payload: IDeleteQuestionPayload): IServerUpdate => {
          const {blockId, questionId} = payload
          const block = get().blocks[blockId]

          const updatedQuestionOrder = block.questionOrder.filter(
            q => q.id !== questionId,
          )
          const updatedBlocks = {
            ...get().blocks,
            [block.id]: {...block, questionOrder: updatedQuestionOrder},
          }

          set({
            blocks: updatedBlocks,
          })
          get().questionsActions.toggleDelete(questionId)
          get().uiActions.removeActiveQuestionId()

          return fromDeleteQuestionPayload(blockId, questionId)
        },

        addQuestionServerUpdate: (payload: IAddQuestionServerPayload): void => {
          get().questionsActions.addQuestion(payload.update)
          get().blocksActions.addQuestion(payload.update)
        },
        reorderQuestionServerUpdate: ({
          questionId,
          blockId,
          orderNum,
        }: IReorderQuestionServerPayload): void => {
          get().questionsActions.updateQuestionOrderNum(questionId, orderNum)
          get().blocksActions.updateQuestionOrderNumInBlock(
            blockId,
            questionId,
            orderNum,
          )
        },
        deleteQuestionServerUpdate: ({
          blockId,
          questionId,
        }: IDeleteQuestionServerPayload): void => {
          get().actions.shared.deleteQuestion({blockId, questionId})
        },
      },
    },
  }))
}

function generateOrderNumForInsertionAt(
  addAtIndex: number,
  orderArray: {orderNum: string}[],
): string {
  // Pass an empty string for any missing positions
  const prevPos = addAtIndex > 0 ? orderArray[addAtIndex - 1].orderNum : ''
  const nextPos =
    addAtIndex < orderArray.length ? orderArray[addAtIndex].orderNum : ''
  return generateFractionalIndexBetween(prevPos, nextPos)
}

function computeBlockOrder(blocks: IBlocksMap): IBlockOrder[] {
  const blockOrder: IBlockOrder[] = []
  Object.keys(blocks).forEach(blockId => {
    if (blocks[blockId].deleted) {
      return
    }
    blockOrder.push({id: blockId, orderNum: blocks[blockId].orderNum})
  })
  blockOrder.sort(orderNumSorter)
  return blockOrder
}

function fetchUpdatedBlocksWithQuestionOrder(
  blocks: IBlocksMap,
  questions: IQuestionMap,
): IBlocksMap {
  const questionsByBlocks = new Map<string, IQuestionOrder[]>()

  //initialize questionByBlocks map for all the blocks
  Object.keys(blocks).forEach(blockId => {
    questionsByBlocks.set(blockId, [])
  })

  //populate the map with respective question order data
  Object.keys(questions).forEach(questionId => {
    if (questions[questionId].deleted) {
      return
    }
    const currentActiveQuestion = questions[questionId]
    questionsByBlocks.get(currentActiveQuestion.blockId)?.push({
      id: currentActiveQuestion.id,
      orderNum: currentActiveQuestion.orderNum,
    })
  })

  //sort questions In block
  questionsByBlocks.forEach((questionOrderArray, blockId) => {
    questionsByBlocks.get(blockId)?.sort(orderNumSorter)
  })

  //update Block object with questionOrder array
  let updatedBlocks: IBlocksMap = {}
  Object.keys(blocks).forEach(blockId => {
    const updatedBlock = {
      ...blocks[blockId],
      questionOrder: questionsByBlocks.get(blockId)!,
    }
    updatedBlocks = {...updatedBlocks, [blockId]: updatedBlock}
  })

  return updatedBlocks
}

interface IOrderNumType {
  orderNum: string
}

const orderNumSorter = (a: IOrderNumType, z: IOrderNumType): number => {
  return a.orderNum.localeCompare(z.orderNum)
}
