import {IUid} from 'src/survey-type-defs'
import {matrixSelectors} from '../store/matrixSelectors'

interface IProps {
  questionId: IUid
  columnId: IUid
}

export const MatrixDropdownOption: React.FC<IProps> = ({
  questionId,
  columnId,
}) => {
  const columnText = matrixSelectors.useColumnText(questionId, columnId)

  return <option value={columnText}>{columnText}</option>
}
