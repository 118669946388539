import React from 'react'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {SmileyAnswerList} from './components/SmileyAnswerList'

export const SmileyQuestion: React.FC<IBaseComponentProps> = ({questionId}) => {
  return (
    <div>
      <SmileyAnswerList questionId={questionId} />
    </div>
  )
}
