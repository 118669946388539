import React from 'react'
import {Outlet} from 'react-router'
import {Socket} from 'socket.io-client'
import {ServerUpdateServiceProvider} from '../../providers/ServerUpdateServiceProvider'
import {createIoMock} from '../mocks/socketIoMock'

interface ISocketContext {
  socket: Socket | null
}

export const MockSocketContext = React.createContext<ISocketContext>({
  socket: null,
})
MockSocketContext.displayName = 'MockSocketContext'

export const MockSocketProvider: React.FC<React.PropsWithChildren> = () => {
  const [socket] = React.useState(createIoMock()())
  const contextValue = React.useMemo(() => ({socket}), [socket])

  React.useEffect(() => {
    return () => {}
  })

  return (
    <MockSocketContext.Provider value={contextValue}>
      <ServerUpdateServiceProvider>
        <Outlet />
      </ServerUpdateServiceProvider>
    </MockSocketContext.Provider>
  )
}
