import {
  IUid,
  IQuestion,
  QuestionTipEnum,
  IQuestionTip,
} from 'src/survey-type-defs'
import {IHasQuestionTip} from 'src/survey-type-defs'
import {useQuestionSelector} from '../../../store/questions/useQuestionSelectorHook'

export const questionTipSelectors = {
  useQuestionTipEnabled: (questionId: IUid): boolean => {
    return useQuestionSelector<IQuestion & IHasQuestionTip, boolean>(
      questionId,
      q => !!q.questionTip?.enabled,
    )
  },
  useQuestionTipType: (questionId: IUid): QuestionTipEnum => {
    return useQuestionSelector<IQuestion & IHasQuestionTip, QuestionTipEnum>(
      questionId,
      q => getQuestionTipOrThrow(q).type,
    )
  },
  useQuestionTipHeader: (questionId: IUid): string => {
    return useQuestionSelector<IQuestion & IHasQuestionTip, string>(
      questionId,
      q => getQuestionTipOrThrow(q).header,
    )
  },
  useQuestionTipHelpText: (questionId: IUid): string => {
    return useQuestionSelector<IQuestion & IHasQuestionTip, string>(
      questionId,
      q => getQuestionTipOrThrow(q).helpText,
    )
  },
  useQuestionTipLinkText: (questionId: IUid): string => {
    return useQuestionSelector<IQuestion & IHasQuestionTip, string>(
      questionId,
      q => getQuestionTipOrThrow(q).linkText!,
    )
  },
} as const

const getQuestionTipOrThrow = (
  question: IQuestion & IHasQuestionTip,
): IQuestionTip => {
  const questionTip = question.questionTip
  if (!questionTip)
    throw new Error('QuestionTip cannot be undefined once enabled')

  return questionTip
}
