import React from 'react'
import {Outlet} from 'react-router'
import {io, Socket} from 'socket.io-client'
import {FullPageSpinner} from '../components/spinner/FullPageSpinner'
import {BACKEND_URL, IS_DEV_ENV} from '../constants/appConstants'

//import {questionServerUpdateFlow} from '../store/questions/QuestionUpdateFlow'
import {MockSocketContext} from '../test/socket/MockSocketProvider'
import {IAuthError} from '../types/api/IAuthError'
import {useMyAppStore} from './AppStoreProvider'
import {ServerUpdateServiceProvider} from './ServerUpdateServiceProvider'

interface ISocketContext {
  socket: Socket | null
}
const SocketContext = React.createContext<ISocketContext>({
  socket: null,
})
SocketContext.displayName = 'SocketContext'

export const SocketProvider: React.FC<React.PropsWithChildren> = () => {
  const [loading, error, socket, authError] = useSocketInternal(BACKEND_URL)

  const contextValue = React.useMemo(() => ({socket}), [socket])

  if (authError) return <div>Authentication required</div>
  if (error) throw error
  if (loading) return <FullPageSpinner message="Loading socket" />

  return (
    <SocketContext.Provider value={contextValue}>
      <ServerUpdateServiceProvider>
        <Outlet />
      </ServerUpdateServiceProvider>
    </SocketContext.Provider>
  )
}

const getSurveyIdFromUrl = (): number | undefined => {
  const path = window.location.pathname
  if (!path || !path.includes('/')) return undefined

  const surveyIdString = path.replace('/surveys/', '').split('/')[0]
  return +surveyIdString
}

const useSocketInternal = (
  url: string,
): [boolean, Error | null, Socket | null, IAuthError | null] => {
  const [surveyId] = React.useState(getSurveyIdFromUrl())
  if (!surveyId) throw new Error('surveyId is undefined')

  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<Error | null>(null)
  const [authError, setAuthError] = React.useState<IAuthError | null>(null)
  const [socket, setSocket] = React.useState<Socket | null>(null)
  const appStore = useMyAppStore()

  React.useEffect(() => {
    let socket = io(`${BACKEND_URL}/survey-builder`, {
      transports: ['websocket'],
      path: '/ws/s',
      withCredentials: true,
      query: {
        surveyId,
      },
    })
    setSocket(socket)

    return () => {}
  }, [appStore, surveyId, url])

  //TODO: socket event unsubscribing

  React.useEffect(() => {
    if (!socket) return
    socket.on('connect', () => {
      /* We don't want to send events that were sent during offline state
      Read more here: https://socket.io/docs/v4/client-offline-behavior/ */
      socket.sendBuffer = []
      setLoading(false)
      setError(null)

      socket.emit('events', 'message from client', console.log)
    })

    socket.on('disconnect', reason => {
      console.log('socket disconnected: ', reason)
      setLoading(true)
    })

    socket.on('connect_error', error => {
      setLoading(false)

      const authError = error as unknown as {data?: IAuthError}
      if (authError?.data?.code === 403) {
        setAuthError(authError.data)
        return
      }

      setError(error)
    })

    socket.on('close', () => {
      setLoading(true)
      socket.connect()
    })

    socket.on('server-event', console.log)
  }, [socket])

  return [loading, error, socket, authError]
}

function useSocketProd(): Socket {
  let context = React.useContext(SocketContext)

  if (context === undefined) {
    throw new Error(`useSocket must be used within a SocketProvider`)
  }

  return context.socket!
}
function useSocketDev(): Socket {
  let context = React.useContext(MockSocketContext)

  if (context === undefined) {
    throw new Error(`useSocket must be used within a MockSocketProvider`)
  }

  return context.socket!
}

export const useSocket = IS_DEV_ENV ? useSocketDev : useSocketProd
