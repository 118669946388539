import {
  IQuestion,
  IVideo,
  VideoAlignEnum,
  VideoTypeEnum,
} from 'src/survey-type-defs'
import {IHasVideo} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../store/questions/useQuestionDispatchHook'

const DEFAULT_VIDEO: IVideo = {
  type: VideoTypeEnum.NONE,
  youtubeLink: '',
  vimeoLink: '',
  libraryVideoLink: '',
  alignType: VideoAlignEnum.LEFT,
  showControl: true,
  autoPlay: false,
  loop: false,
  muted: false,
  width: 50,
}

export const videoActions = {
  useUpdateVideoType: (): IQuestionAction<{
    type: VideoTypeEnum
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {type: VideoTypeEnum}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = originalQuestion.video
      const updatedVideo =
        video == null
          ? {...DEFAULT_VIDEO, type: payload.type}
          : {...video, type: payload.type}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },
  useUpdateVideoAlignType: (): IQuestionAction<{
    alignType: VideoAlignEnum
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {alignType: VideoAlignEnum}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = getVideoOrThrow(originalQuestion)
      const updatedVideo = {...video, alignType: payload.alignType}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },

  useToggleVideoAutoplay: (): IQuestionAction<{
    autoPlay: boolean
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {autoPlay: boolean}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = getVideoOrThrow(originalQuestion)
      const updatedVideo = {...video, autoPlay: payload.autoPlay}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },
  useToggleVideoShowControl: (): IQuestionAction<{
    showControl: boolean
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {showControl: boolean}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = getVideoOrThrow(originalQuestion)
      const updatedVideo = {...video, showControl: payload.showControl}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },
  useToggleVideoLoop: (): IQuestionAction<{
    loop: boolean
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {loop: boolean}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = getVideoOrThrow(originalQuestion)
      const updatedVideo = {...video, loop: payload.loop}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },
  useToggleVideoMuted: (): IQuestionAction<{
    muted: boolean
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {muted: boolean}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = getVideoOrThrow(originalQuestion)
      const updatedVideo = {...video, muted: payload.muted}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },
  useUpdateVideoWidth: (): IQuestionAction<{
    width: number
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {width: number}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = getVideoOrThrow(originalQuestion)
      const updatedVideo = {...video, width: payload.width}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },
  useUpdateVideoYoutubeLink: (): IQuestionAction<{
    youtubeLink: string
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {youtubeLink: string}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = getVideoOrThrow(originalQuestion)
      const updatedVideo = {...video, youtubeLink: payload.youtubeLink}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },
  useUpdateVideoVimeoLink: (): IQuestionAction<{
    vimeoLink: string
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasVideo,
      {vimeoLink: string}
    >((originalQuestion, payload): Partial<IQuestion & IHasVideo> => {
      const video = getVideoOrThrow(originalQuestion)
      const updatedVideo = {...video, vimeoLink: payload.vimeoLink}

      const questionPatch = {
        video: updatedVideo,
      }
      return questionPatch
    })
  },
} as const

const getVideoOrThrow = (question: IQuestion & IHasVideo): IVideo => {
  const video = question.video
  if (!video) throw new Error('video cannot be undefined once enabled')

  return video
}
