import {
  ITextInputSize,
  ITextQuestion,
  ITextQuestionRow,
  IUid,
  TextBoxPositionEnum,
  TextSubtypeEnum,
} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../../store/questions/useQuestionDispatchHook'
import {textQuestionUtil} from '../util/textQuestionUtil'

interface IRowId {
  rowId: IUid
}

export const textActions = {
  useAddRow: (): IQuestionAction<void> => {
    return useApplyQuestionPatchAndSyncToServer<ITextQuestion, void>(
      originalQuestion => {
        const rowsOrder = originalQuestion.rowsOrder
        const newRowSequenceNum = rowsOrder.length + 1
        const firstRowId = rowsOrder[0]
        const firstRowSubtype = textQuestionUtil.getRowOrThrow(
          originalQuestion,
          firstRowId,
        ).subtype
        // We use the subtype of first row for the new row
        const newRow = textQuestionUtil.createRow(
          newRowSequenceNum,
          firstRowSubtype,
        )

        const updatedRows = {...originalQuestion.rows, [newRow.id]: newRow}
        const updatedRowsOrder = [...rowsOrder, newRow.id]

        const questionPatch = {rows: updatedRows, rowsOrder: updatedRowsOrder}
        return questionPatch
      },
    )
  },
  useDeleteRow: (): IQuestionAction<IUid> => {
    return useApplyQuestionPatchAndSyncToServer<ITextQuestion, IUid>(
      (originalQuestion, deletedRowId) => {
        const rowsOrder = originalQuestion.rowsOrder

        const updatedRowsOrder = rowsOrder.filter(
          rowId => rowId !== deletedRowId,
        )
        const updatedRows = {...originalQuestion.rows}
        delete updatedRows[deletedRowId]

        const questionPatch = {rows: updatedRows, rowsOrder: updatedRowsOrder}
        return questionPatch
      },
    )
  },
  useChangeRowText: (): IQuestionAction<
    {
      text: string
    } & IRowId
  > => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      {
        text: string
      } & IRowId
    >((originalQuestion, {rowId, text}) => {
      const questionPatch = createQuestionPatch(originalQuestion, rowId, () => {
        const rowPatch = {text}
        return rowPatch
      })
      return questionPatch
    })
  },
  useChangeSubtype: (): IQuestionAction<
    {
      subtype: TextSubtypeEnum
    } & IRowId
  > => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      {
        subtype: TextSubtypeEnum
      } & IRowId
    >((originalQuestion, {rowId, subtype}) => {
      const questionPatch = createQuestionPatch(originalQuestion, rowId, () => {
        const rowPatch = {subtype}
        return rowPatch
      })
      return questionPatch
    })
  },
  useChangePrefix: (): IQuestionAction<
    {
      prefix: string
    } & IRowId
  > => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      {prefix?: string} & IRowId
    >((originalQuestion, {prefix, rowId}) => {
      const questionPatch = createQuestionPatch(
        originalQuestion,
        rowId,
        row => {
          const prefixSuffix = row.prefixSuffix
          const updatedPrefixSuffix = {...prefixSuffix, prefix}
          const rowPatch = {prefixSuffix: updatedPrefixSuffix}
          return rowPatch
        },
      )
      return questionPatch
    })
  },
  useChangeSuffix: (): IQuestionAction<
    {
      suffix: string
    } & IRowId
  > => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      {suffix: string} & IRowId
    >((originalQuestion, {suffix, rowId}) => {
      const questionPatch = createQuestionPatch(
        originalQuestion,
        rowId,
        row => {
          const prefixSuffix = row.prefixSuffix
          const updatedPrefixSuffix = {...prefixSuffix, suffix}
          const rowPatch = {prefixSuffix: updatedPrefixSuffix}
          return rowPatch
        },
      )
      return questionPatch
    })
  },
  useChangeInputSize: (): IQuestionAction<ITextInputSize & IRowId> => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      ITextInputSize & IRowId
    >((originalQuestion, {rowId, width, height}) => {
      const questionPatch = createQuestionPatch(
        originalQuestion,
        rowId,
        row => {
          const inputSize = row.inputSize
          const updatedInputSize = {...inputSize, width, height}
          const rowPatch = {inputSize: updatedInputSize}
          return rowPatch
        },
      )
      return questionPatch
    })
  },
  useChangeCharacterLimitMin: (): IQuestionAction<{min: number} & IRowId> => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      {min: number} & IRowId
    >((originalQuestion, {min, rowId}) => {
      const questionPatch = createQuestionPatch(
        originalQuestion,
        rowId,
        row => {
          const characterLimit = row.characterLimit
          const updatedCharacterLimit = {...characterLimit, min: min}
          const rowPatch = {characterLimit: updatedCharacterLimit}
          return rowPatch
        },
      )
      return questionPatch
    })
  },
  useChangeCharacterLimitMax: (): IQuestionAction<{max: number} & IRowId> => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      {max: number} & IRowId
    >((originalQuestion, {max, rowId}) => {
      const questionPatch = createQuestionPatch(
        originalQuestion,
        rowId,
        row => {
          const characterLimit = row.characterLimit
          const updatedCharacterLimit = {...characterLimit, max: max}
          const rowPatch = {characterLimit: updatedCharacterLimit}
          return rowPatch
        },
      )
      return questionPatch
    })
  },
  useChangeTextBoxPosition: (): IQuestionAction<
    {position: TextBoxPositionEnum} & IRowId
  > => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      {position: TextBoxPositionEnum} & IRowId
    >((originalQuestion, {position, rowId}) => {
      const questionPatch = createQuestionPatch(
        originalQuestion,
        rowId,
        row => {
          const textBoxDisplay = row.textBoxDisplay
          const updatedTextBoxDisplay = {...textBoxDisplay, position}
          const rowPatch = {textBoxDisplay: updatedTextBoxDisplay}
          return rowPatch
        },
      )
      return questionPatch
    })
  },
  useChangeTextBoxLabelWidth: (): IQuestionAction<
    {labelWidth: number} & IRowId
  > => {
    return useApplyQuestionPatchAndSyncToServer<
      ITextQuestion,
      {labelWidth: number} & IRowId
    >((originalQuestion, {labelWidth, rowId}) => {
      const questionPatch = createQuestionPatch(
        originalQuestion,
        rowId,
        row => {
          const textBoxDisplay = row.textBoxDisplay
          const updatedTextBoxDisplay = {...textBoxDisplay, labelWidth}
          const rowPatch = {textBoxDisplay: updatedTextBoxDisplay}
          return rowPatch
        },
      )
      return questionPatch
    })
  },
} as const

const createQuestionPatch = (
  q: ITextQuestion,
  rowId: IUid,
  createRowPatch: (row: ITextQuestionRow) => Partial<ITextQuestionRow>,
): Partial<ITextQuestion> => {
  const row = textQuestionUtil.getRowOrThrow(q, rowId)
  const rowPatch = createRowPatch(row)
  const updatedRow = {...row, ...rowPatch}
  const updatedRows = {...q.rows, [rowId]: updatedRow}

  const questionPatch = {rows: updatedRows}
  return questionPatch
}
