import {IServerUpdateProcessor} from '../../socket/types/IServerUpdateProcessor'
import {IServerUpdate} from '../../socket/types/IServerUpdate'
import {IUid} from 'src/survey-type-defs'
import {IUseAppStore} from '../../types/store/UseAppStore'
import {loggerUtil} from '../../test/utils/loggerUtil'
import {IBlocksPatch} from './useBlocksDispatchHook'

class BlockSliceServerUpdateProcessor implements IServerUpdateProcessor {
  readonly unAckServerUpdates: Record<IUid, IBlocksPatch> = {}

  processServerMessages(message: IServerUpdate, appStore: IUseAppStore): void {
    loggerUtil.logOnlyDev('block updates received over socket', message)
    //useApplyServerBlockPath(message.payload.blockId, message.payload.update)
    const surveyUpdatePayload = message.payload as IBlocksPatch
    appStore.getState().updateBlockStore(surveyUpdatePayload)
  }

  hasConflicts(
    unAckMessage: IServerUpdate,
    messageFromServer: IServerUpdate,
  ): boolean {
    loggerUtil.logOnlyDev('checking for conflicts')

    //TODO: check how to handle conflicts while making changes to block slice

    return false
  }
}

export const blockSliceServerUpdateProcessor =
  new BlockSliceServerUpdateProcessor()
