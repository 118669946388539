import {
  IHasQuestionDisplay,
  IQuestion,
  QuestionDisplayEnum,
} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../store/questions/useQuestionDispatchHook'

export const questionDisplayActions = {
  useUpdateAnswerDisplayOrder: (): IQuestionAction<{
    type: QuestionDisplayEnum
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasQuestionDisplay,
      {type: QuestionDisplayEnum}
    >((_, payload): Partial<IQuestion & IHasQuestionDisplay> => {
      const questionPatch = {
        questionDisplay: {type: payload.type},
      }
      return questionPatch
    })
  },
} as const
