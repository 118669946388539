import {questionOrderSchema} from './IQuestionOrder'
import {uidSchema} from '../../uid/IUid'
import {Static, Type} from '@sinclair/typebox'

//TODO: need to remove questionOrder (schema should be as required in the backend )
export const blockSchema = Type.Object({
  id: uidSchema,
  title: Type.String(),
  orderNum: Type.String(),
  deleted: Type.Boolean(),
  questionOrder: Type.Array(questionOrderSchema),
})

export type IBlock = Static<typeof blockSchema>
