import React from 'react'
import {WysiwygTextInput} from '../../../../components/input/wysiwygTextInput/WysiwygTextInput'
import {IUid} from 'src/survey-type-defs'
import {SmileyActions} from '../store/smileyActions'
import {SmileySelectors} from '../store/smileySelector'
import {useApplyQuestionPatchAndSyncToServer} from '../../../../store/questions/useQuestionDispatchHook'

interface IProps {
  questionId: IUid
  answerId: IUid
}

export const SmileyAnswer: React.FC<IProps> = ({questionId, answerId}) => {
  const answerText = SmileySelectors.useSelectAnswerText(questionId, answerId)
  const updateAnswerText = useApplyQuestionPatchAndSyncToServer(
    SmileyActions.updateAnswerTextAction,
  )

  const handleUpdateAnswerText = (text: string): void => {
    updateAnswerText(questionId, {answerId, text})
  }

  return (
    <div>
      <WysiwygTextInput
        text={answerText}
        placeholder="Option"
        onChange={handleUpdateAnswerText}
      />
    </div>
  )
}
