import React from 'react'
import {choiceActions} from '../store/choiceActions'

export const useAddAnswer = (
  questionId: string,
  positionIndex: number,
): (() => void) => {
  const addAnswer = choiceActions.useAddAnswer()
  const handleAddAnswer = React.useCallback((): void => {
    addAnswer(questionId, positionIndex)
  }, [addAnswer, questionId, positionIndex])

  return handleAddAnswer
}
