import React from 'react'

interface IToggleValue {
  on: boolean
  toggle: () => void
  reset: () => void
}

export const useToggle = (defaultValue = false): IToggleValue => {
  const [on, setOn] = React.useState(defaultValue)

  const toggle = React.useCallback(() => setOn(s => !s), [])
  const reset = React.useCallback(() => setOn(false), [])

  return {on, toggle, reset}
}
