import {WysiwygTextInput} from '../../../../components/input/wysiwygTextInput/WysiwygTextInput'
import {IUid, TextSubtypeEnum} from 'src/survey-type-defs'
import {textSelectors} from '../store/TextSelectors'
import {textActions} from '../store/textActions'
import {DropdownMenu} from '../../../../components/dropdownMenu/DropdownMenu'
import {RemoveTextRowMenuItem} from './RemoveTextRowMenuItem'

interface IProps {
  questionId: IUid
  rowId: IUid
}

export const TextRow: React.FC<IProps> = ({questionId, rowId}) => {
  const text = textSelectors.useRowText(questionId, rowId)
  const subtype = textSelectors.useRowSubtype(questionId, rowId)
  const canDeleteRow = textSelectors.useCanDeleteRow(questionId)
  const changeText = textActions.useChangeRowText()

  const handleChangeText = (text: string): void => {
    changeText(questionId, {text, rowId})
  }

  return (
    <div>
      <WysiwygTextInput
        text={text}
        placeholder="Question Text"
        fontSizePx={18}
        fontWeight={400}
        onChange={handleChangeText}
      />
      {canDeleteRow && (
        <DropdownMenu ariaLabel="row setting">
          <RemoveTextRowMenuItem questionId={questionId} rowId={rowId} />
        </DropdownMenu>
      )}
      {resolveRowDisplayBySubtype(subtype)}
    </div>
  )
}

const resolveRowDisplayBySubtype = (subtype: TextSubtypeEnum): JSX.Element => {
  switch (subtype) {
    case TextSubtypeEnum.MULTIPLE_ROW:
      return <div>Multiple Row Answer Text</div>
    case TextSubtypeEnum.SINGLE_ROW:
      return <div>Single Row Answer Text</div>
    case TextSubtypeEnum.NUMERIC:
      return <div>Numeric Input</div>
    case TextSubtypeEnum.EMAIL_ADDRESS:
      return <div>Email Address</div>

    default:
      throw new Error(`Invalid subtype: ${subtype}`)
  }
}
