import React, {PropsWithChildren} from 'react'

export const SettingSectionWithoutHeader: React.FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <>
      <div style={{marginLeft: '5px', marginTop: '10px'}}>{children}</div>
    </>
  )
}
