import {IQuestion, IHasAlternateColors} from 'src/survey-type-defs'
import {useApplyQuestionPatchAndSyncToServer} from '../../store/questions/useQuestionDispatchHook'

export const alternateColorsActions = {
  useUpdateAlternateColors: () => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasAlternateColors,
      boolean
    >((_, enabled) => {
      const questionPatch = {
        alternateColors: {enabled},
      }
      return questionPatch
    })
  },
} as const
