import {v4 as uuid} from 'uuid'
import {IBlock} from 'src/survey-type-defs'
import {IQuestion} from 'src/survey-type-defs'
import {ISurvey} from '../../types/Survey'
import {IUid} from 'src/survey-type-defs'
import {questionConfig} from '../../types/questionTypes/questionConfig'
import {QuestionTypeEnum} from 'src/survey-type-defs'

const createSurvey = (id: number = 1): ISurvey => {
  const data = {sequenceNum: 0, blockSequenceNum: 0, blockOrderNum: 0}
  const newSurvey: ISurvey = {
    id,
    name: `Test Survey ${id}`,
    blockOrder: [],
    blocks: {},
    questions: {},
  }
  for (let i = 0; i < 20; i++) {
    const [block, questions] = createBlock(undefined, data)

    newSurvey.blockOrder.push({id: block.id, orderNum: block.orderNum})
    newSurvey.blocks[block.id] = block

    questions.forEach(q => (newSurvey.questions[q.id] = q))
  }

  console.log(newSurvey)

  return newSurvey
}

const questionTypes = [
  QuestionTypeEnum.CHOICE,
  QuestionTypeEnum.IMAGE,
  QuestionTypeEnum.MATRIX,
  QuestionTypeEnum.SMILEY,
  QuestionTypeEnum.TEXT,
]

const createBlock = (
  questionFactory?: (
    blockId: IUid,
    orderNum: string,
    sequenceNum: number,
  ) => IQuestion,
  data = {sequenceNum: 0, blockSequenceNum: 0, blockOrderNum: 0},
): [IBlock, IQuestion[]] => {
  const blockId = uuid()
  const defaultQuestions = []
  let orderNum = 0
  for (let i = 0; i < 100; i++) {
    data.sequenceNum++
    orderNum += 2
    const formattedOrderNum = String(orderNum)
    const randomQuestionTypeIndex = Math.floor(Math.random() * 5)
    const newQuestion = questionConfig.getFactory(
      questionTypes[randomQuestionTypeIndex],
    )(blockId, formattedOrderNum, data.sequenceNum)
    defaultQuestions.push(newQuestion)
  }

  const questions = questionFactory
    ? [questionFactory(blockId, '1', 1)]
    : defaultQuestions

  const questionOrder = questions.map(q => ({id: q.id, orderNum: q.orderNum}))

  data.blockSequenceNum++
  data.blockOrderNum += 2
  const formattedBlockOrderNum = String(data.blockOrderNum)
  const block = {
    id: blockId,
    title: `Block ${data.blockSequenceNum}`,
    orderNum: formattedBlockOrderNum,
    questionOrder,
    deleted: false,
  }
  return [block, questions]
}

const createSurveyWithQuestion = (
  id: number = 1,
  questionFactory: (
    blockId: IUid,
    orderNum: string,
    sequenceNum: number,
  ) => IQuestion,
): ISurvey => {
  const [block, questions] = createBlock(questionFactory)

  const newSurvey: ISurvey = {
    id,
    name: `Test Survey ${id}`,
    blockOrder: [{id: block.id, orderNum: block.orderNum}],
    blocks: {[block.id]: block},
    questions: {},
  }

  questions.forEach(q => (newSurvey.questions[q.id] = q))

  return newSurvey
}

export const surveyDb = {
  createSurvey,
  createSurveyWithQuestion,
}
