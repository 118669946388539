import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {DropdownMenuItemButton} from '../../../../components/dropdownMenu/DropdownMenuItemButton'
import {choiceActions} from '../store/choiceActions'

interface IProps {
  questionId: IUid
  answerId: IUid
}

export const RemoveAnswerMenuItem: React.FC<IProps> = ({
  questionId,
  answerId,
}) => {
  const deleteAnswer = choiceActions.useDeleteAnswer()
  const handleDeleteAnswer = (): void => {
    deleteAnswer(questionId, {answerId})
  }
  return (
    <DropdownMenuItemButton
      title="Remove Answer"
      onClick={handleDeleteAnswer}
    />
  )
}
