import React from 'react'
import {Socket} from 'socket.io-client'
import {IServerUpdate} from '../socket/types/IServerUpdate'
import {IServerUpdateProcessor} from '../socket/types/IServerUpdateProcessor'

import {IServerUpdateType} from '../socket/types/IServerUpdateType'
import {blockServerUpdatesProcessor} from '../store/blocks/BlockServerUpdateProcessor'
import {blockSliceServerUpdateProcessor} from '../store/blocks/BlockSliceServerUpdateProcessor'
import {questionServerUpdatesProcessor} from '../store/questions/QuestionServerUpdateProcessor'
import {sharedSliceServerUpdateProcessor} from '../store/shared/SharedSliceServerUpdateProcessor'
import {IServerUpdateAck} from '../types/serverUpdates/ServerUpdateAck'
import {IUseAppStore} from '../types/store/UseAppStore'
import {IUid} from 'src/survey-type-defs'
import {useMyAppStore} from './AppStoreProvider'
import {useSocket} from './SocketProvider'
import {loggerUtil} from '../test/utils/loggerUtil'

interface IServerUpdateServiceContext {
  serverUpdateService: ServerUpdateService | null
}

//TODO: ############### need to handle array of messages ########
//TODO: ########checkout how to stop listening to events when socket is disconnected or when the component is un-mounted, etc
class ServerUpdateService {
  readonly unAckServerUpdates: Map<IUid, IServerUpdate> = new Map()
  processors: Map<IServerUpdateType, IServerUpdateProcessor> = new Map()
  socket: Socket
  appStore: IUseAppStore

  constructor(socket: Socket, myAppStore: IUseAppStore) {
    this.socket = socket
    this.appStore = myAppStore
    this.init()
  }

  init(): void {
    // register serverUpdate processors by update type
    this.registerProcessor('BLOCK_UPDATE', blockServerUpdatesProcessor)
    this.registerProcessor('QUESTION_UPDATE', questionServerUpdatesProcessor)
    this.registerProcessor(
      'BLOCK_SLICE_UPDATE',
      blockSliceServerUpdateProcessor,
    )
    this.registerProcessor(
      'ADD_QUESTION_TO_BLOCK',
      sharedSliceServerUpdateProcessor,
    )
    this.registerProcessor(
      'REORDER_QUESTION_IN_BLOCK',
      sharedSliceServerUpdateProcessor,
    )
    this.registerProcessor('DELETE_QUESTION', sharedSliceServerUpdateProcessor)
  }
  registerProcessor(
    serverUpdateType: IServerUpdateType,
    processor: IServerUpdateProcessor,
  ): void {
    this.processors.set(serverUpdateType, processor)
  }

  publishMessage(message: IServerUpdate): void {
    this.unAckServerUpdates.set(message.id, message)
    //TODO: IServerUpdatePayload is what we are going to get? - no need for IServerPayload specific types... check once
    loggerUtil.logOnlyDev('unackUpdates size', this.unAckServerUpdates.size)
    setTimeout(() => {
      this.socket.emit(
        'SERVER_UPDATE',
        message,
        (serverAck: IServerUpdateAck<IServerUpdate>): void => {
          const {error, update} = serverAck
          if (error) {
            console.log(error)
            //TODO: handle error
            alert(error)
            return
          }
          loggerUtil.logOnlyDev(`received ack for ${message.id}`)

          if (!this.processors.get(message.type)) {
            //TODO: handle error
            console.error(`No subscribers for message type ${message.type}`)
            return
          }

          if (update) {
            this.applyServerUpdates(update)
          }
          this.unAckServerUpdates.delete(serverAck.id)
        },
      )
    }, 0)
  }

  onMessage(message: IServerUpdate): void {
    loggerUtil.logOnlyDev('updates received  - ServerUpdateService')
    if (!this.processors.get(message.type)) {
      //TODO: handle error
      console.error(`No subscribers for message type ${message.type}`)
      return
    }

    this.applyServerUpdates(message)
  }

  private applyServerUpdates(message: IServerUpdate): void {
    const messageProcessor = this.processors.get(message.type)!

    //TODO: need to optimize this??
    // check for conflicts
    let isConflictingUpdate: boolean = false
    this.unAckServerUpdates.forEach(function (unAckMessage, unAckMessageId) {
      loggerUtil.logOnlyDev('has conflicts??', unAckMessageId)
      if (messageProcessor.hasConflicts(unAckMessage, message)) {
        isConflictingUpdate = true
        return
      }
    })
    if (!isConflictingUpdate) {
      loggerUtil.logOnlyDev('processing')
      messageProcessor.processServerMessages(message, this.appStore)
    }
  }
}

export const ServerUpdateServiceContext =
  React.createContext<IServerUpdateServiceContext>({
    serverUpdateService: null,
  })

ServerUpdateServiceContext.displayName = 'ServerUpdateServiceContext'

export const ServerUpdateServiceProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const socket = useSocket()
  const appStore = useMyAppStore()

  const [serverUpdateServiceInternal] = React.useState(
    createServerUpdateService(socket, appStore)(),
  )

  React.useEffect((): (() => void) => {
    socket.on('RECEIVE_SERVER_UPDATES', (data: IServerUpdate) => {
      serverUpdateServiceInternal.onMessage(data)
    })
    return () => socket.off('RECEIVE_SERVER_UPDATES')
  }, [serverUpdateServiceInternal, socket])

  const context = React.useMemo(
    () => ({
      serverUpdateService: serverUpdateServiceInternal!,
    }),
    [serverUpdateServiceInternal],
  )

  return (
    <ServerUpdateServiceContext.Provider value={context}>
      {children}
    </ServerUpdateServiceContext.Provider>
  )
}

const createServerUpdateService =
  (socket: Socket, appStore: IUseAppStore) => (): ServerUpdateService => {
    return new ServerUpdateService(socket, appStore)
  }

function useServerUpdateContext(): ServerUpdateService {
  let context = React.useContext(ServerUpdateServiceContext)
  if (!context) {
    throw new Error(`serverUpdateService context is not available`)
  }
  return context.serverUpdateService!
}

export const useServerUpdateService = useServerUpdateContext
