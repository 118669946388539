import {
  ITextInputSize,
  ITextQuestion,
  ITextQuestionRow,
  IUid,
  TextBoxPositionEnum,
  TextSubtypeEnum,
} from 'src/survey-type-defs'
import {uuid} from '../../../../utils/uuidUtil'
import {short_description} from 'casual-browserify'

export const textQuestionUtil = {
  getSubtypeLabel(subtype: TextSubtypeEnum): string {
    switch (subtype) {
      case TextSubtypeEnum.EMAIL_ADDRESS:
        return 'Email Address'
      case TextSubtypeEnum.MULTIPLE_ROW:
        return 'Multiple Row Text'
      case TextSubtypeEnum.NUMERIC:
        return 'Numeric Data'
      case TextSubtypeEnum.SINGLE_ROW:
        return 'Single Row Text'

      default:
        throw new Error(`Invalid subtype: ${subtype}`)
    }
  },
  getRowOrThrow(question: ITextQuestion, rowId: IUid): ITextQuestionRow {
    const row = question.rows[rowId]
    if (!row) throw new Error(`Row not found for id: ${rowId}`)

    return row
  },
  createRow(
    sequenceNum: number,
    subtype = TextSubtypeEnum.MULTIPLE_ROW,
  ): ITextQuestionRow {
    return {
      id: uuid(),
      text: `${sequenceNum} ${short_description}`,
      inputSize: createInputSizeBySubtype(subtype),
      subtype,
      textBoxDisplay: {position: TextBoxPositionEnum.BELOW_QUESTION},
    }
  },
} as const

const createInputSizeBySubtype = (subtype: TextSubtypeEnum): ITextInputSize => {
  if (subtype === TextSubtypeEnum.MULTIPLE_ROW) {
    return {height: 4, width: 100}
  }

  return {width: 100}
}
