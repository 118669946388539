import React from 'react'
import {VideoTypeEnum} from 'src/survey-type-defs'
import {ToggleWithLabel} from '../../../components/checkbox/ToggleWithLabel'
import {SettingSectionWithoutHeader} from '../../../components/questionSettingSection/SettingSectionWithoutHeader'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {videoActions} from '../store/videoActions'
import {videoSelectors} from '../store/videoSelectors'

interface IProps extends IBaseComponentProps {
  videoType: VideoTypeEnum
}

export const VideoShowControls: React.FC<IProps> = ({
  questionId,
  videoType,
}) => {
  const showControl = videoSelectors.useVideoShowControlsEnabled(questionId)

  const toggleShowControl = videoActions.useToggleVideoShowControl()
  const handleShowControlToggle = (): void => {
    toggleShowControl(questionId, {
      showControl: !showControl,
    })
  }
  const canDisplayShowControls =
    VideoTypeEnum.YOUTUBE === videoType ||
    VideoTypeEnum.LIBRARY_VIDEO === videoType
  if (!canDisplayShowControls) {
    return null
  }
  return (
    <SettingSectionWithoutHeader>
      <ToggleWithLabel
        label="Show Controls"
        name="showControlCheckbox"
        checked={showControl || false}
        onChange={handleShowControlToggle}
      />
    </SettingSectionWithoutHeader>
  )
}
