import {
  AnswerDisplayOrderEnum,
  IHasAnswerDisplayOrder,
  IQuestion,
} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../store/questions/useQuestionDispatchHook'

export const answerDisplayOrderActions = {
  useUpdateAnswerDisplayOrder: (): IQuestionAction<{
    type: AnswerDisplayOrderEnum
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasAnswerDisplayOrder,
      {type: AnswerDisplayOrderEnum}
    >(
      (
        originalQuestion,
        payload,
      ): Partial<IQuestion & IHasAnswerDisplayOrder> => {
        const updatedAnswerDisplayOrder = {
          ...originalQuestion.answerDisplayOrder!,
          type: payload.type,
        }
        const questionPatch = {
          answerDisplayOrder: updatedAnswerDisplayOrder,
        }
        return questionPatch
      },
    )
  },
  useUpdateRandomAnswerCount: (): IQuestionAction<{
    randomAnswerCount: number
  }> => {
    return useApplyQuestionPatchAndSyncToServer<
      IQuestion & IHasAnswerDisplayOrder,
      {randomAnswerCount: number}
    >(
      (
        originalQuestion,
        payload,
      ): Partial<IQuestion & IHasAnswerDisplayOrder> => {
        const updatedAnswerDisplayOrder = {
          ...originalQuestion.answerDisplayOrder!,
          randomAnswerCount: payload.randomAnswerCount,
        }
        const questionPatch = {
          answerDisplayOrder: updatedAnswerDisplayOrder,
        }
        return questionPatch
      },
    )
  },
} as const
