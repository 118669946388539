import React from 'react'
import {IUid, VideoTypeEnum} from 'src/survey-type-defs'
import {TextInput} from '../../../components/input/TextInput'
import {videoActions} from '../store/videoActions'
import {videoSelectors} from '../store/videoSelectors'

interface IProps {
  questionId: IUid
  videoType: VideoTypeEnum
}
export const VideoYoutubeUrl: React.FC<IProps> = ({questionId, videoType}) => {
  const videoYoutubeLink = videoSelectors.useVideoYoutubeLink(questionId)
  const updateYoutubeLink = videoActions.useUpdateVideoYoutubeLink()
  const handleOnVideoYoutubeLinkChange = (youtubeLink: string): void => {
    updateYoutubeLink(questionId, {
      youtubeLink: youtubeLink,
    })
  }
  const canDisplayYoutubeUrl = VideoTypeEnum.YOUTUBE === videoType
  if (!canDisplayYoutubeUrl) {
    return null
  }
  return (
    <div>
      <TextInput
        value={videoYoutubeLink}
        placeholder="Enter Youtube url link"
        onChange={handleOnVideoYoutubeLinkChange}
      />
    </div>
  )
}
