import React from 'react'
import {IBaseComponentProps} from '../../../types/questionTypes/BaseQuestionProps'
import {AddTextRowButton} from './components/AddTextRowButton'
import {TextRows} from './components/TextRows'

export const TextQuestion: React.FC<IBaseComponentProps> = ({questionId}) => {
  return (
    <div>
      <TextRows questionId={questionId} />
      <AddTextRowButton questionId={questionId} />
    </div>
  )
}
