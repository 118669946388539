import axios, {AxiosPromise, AxiosRequestConfig} from 'axios'
import {BACKEND_URL} from '../constants/appConstants'

export const redirectTo = (url: string): void => {
  window.location.replace(url)
}
export function callApi<T>(
  endpoint: string,
  config?: AxiosRequestConfig,
): AxiosPromise<T> {
  return axios({
    url: `${BACKEND_URL}/api${endpoint}`,
    withCredentials: true,
    ...config,
  })
}

export async function getApi<T>(
  endpoint: string,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {method: 'GET', ...config})
  return response.data
}

export async function postApi<T>(
  endpoint: string,
  data: unknown,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {method: 'POST', data, ...config})
  return response.data
}

export async function putApi<T>(
  endpoint: string,
  data: unknown,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {method: 'PUT', data, ...config})
  return response.data
}

export async function patchApi<T>(
  endpoint: string,
  data: unknown,
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {
    method: 'PATCH',
    data,
    ...config,
  })

  return response.data
}

export async function deleteApi<T>(
  endpoint: string,
  data: unknown = {},
  config?: AxiosRequestConfig,
): Promise<T> {
  const response = await callApi<T>(endpoint, {
    method: 'DELETE',
    data,
    ...config,
  })

  return response.data
}
