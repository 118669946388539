import {IBaseComponentProps} from '../../types/questionTypes/BaseQuestionProps'
import {alternateColorsSelectors} from './alternateColorsSelectors'
import {alternateColorsActions} from './alternateColorsActions'
import {ToggleWithLabel} from '../../components/checkbox/ToggleWithLabel'
import {SettingSectionWithoutHeader} from '../../components/questionSettingSection/SettingSectionWithoutHeader'

export const AlternateColors = ({
  questionId,
}: IBaseComponentProps): JSX.Element | null => {
  const alternateColorsEnabled =
    alternateColorsSelectors.useAlternateColors(questionId)

  const updateAlternateColorsSelected =
    alternateColorsActions.useUpdateAlternateColors()
  const handleUpdateAlternateColors = (): void => {
    updateAlternateColorsSelected(questionId, !alternateColorsEnabled)
  }

  return (
    <SettingSectionWithoutHeader>
      <ToggleWithLabel
        label="Alternate Colors"
        name="alternateColorsCheckbox"
        checked={alternateColorsEnabled}
        onChange={handleUpdateAlternateColors}
      />
    </SettingSectionWithoutHeader>
  )
}
