import {
  IMatrixColumn,
  IMatrixQuestion,
  IMatrixRow,
  IUid,
  MatrixSubtypeEnum,
} from 'src/survey-type-defs'
import {
  IQuestionAction,
  useApplyQuestionPatchAndSyncToServer,
} from '../../../../store/questions/useQuestionDispatchHook'

export const matrixActions = {
  useChangeSubtype: (): IQuestionAction<MatrixSubtypeEnum> => {
    return useApplyQuestionPatchAndSyncToServer<
      IMatrixQuestion,
      MatrixSubtypeEnum
    >((__, newSubtype) => ({
      subtype: newSubtype,
    }))
  },
  useUpdateRowText: (): IQuestionAction<{rowId: IUid; text: string}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IMatrixQuestion,
      {rowId: IUid; text: string}
    >((originalQuestion, {rowId, text}) => {
      const originalRows = originalQuestion.rows
      const originalRow = getRowOrThrow(originalQuestion, rowId)
      const updatedRow = {...originalRow, text}

      const questionPatch = {
        rows: {...originalRows, [rowId]: {...updatedRow}},
      }

      return questionPatch
    })
  },
  useUpdateColumnText: (): IQuestionAction<{columnId: IUid; text: string}> => {
    return useApplyQuestionPatchAndSyncToServer<
      IMatrixQuestion,
      {columnId: IUid; text: string}
    >((originalQuestion, {columnId, text}) => {
      const originalColumns = originalQuestion.columns
      const originalColumn = getColumnOrThrow(originalQuestion, columnId)
      const updatedColumn = {...originalColumn, text}

      const questionPatch = {
        columns: {...originalColumns, [columnId]: {...updatedColumn}},
      }

      return questionPatch
    })
  },
} as const

const getRowOrThrow = (question: IMatrixQuestion, rowId: IUid): IMatrixRow => {
  const row = question.rows[rowId]
  if (!row) throw new Error(`Row not found for id: ${rowId}`)

  return row
}
const getColumnOrThrow = (
  question: IMatrixQuestion,
  columnId: IUid,
): IMatrixColumn => {
  const column = question.columns[columnId]
  if (!column) throw new Error(`Row not found for id: ${columnId}`)

  return column
}
