import React from 'react'
import {IUid} from 'src/survey-type-defs'
import {DropdownMenuItemCheckbox} from '../../../../components/dropdownMenu/DropdownMenuItemCheckbox'
import {choiceActions} from '../store/choiceActions'
import {choiceSelectors} from '../store/choiceSelectors'

interface IProps {
  questionId: IUid
  answerId: IUid
}

export const ToggleDefaultAnswerMenuItem: React.FC<IProps> = ({
  questionId,
  answerId,
}) => {
  const isDefault = choiceSelectors.useIsAnswerDefault(questionId, answerId)
  const updateDefaultAnswers = choiceActions.useUpdateDefaultAnswers()

  const handleUpdateDefaultAnswer = (_: boolean): void => {
    updateDefaultAnswers(questionId, {answerId, isDefault: !isDefault})
  }
  return (
    <DropdownMenuItemCheckbox
      title="Default Selected"
      checked={isDefault || false}
      onChange={handleUpdateDefaultAnswer}
    />
  )
}
