import React from 'react'
import {RadioButton} from '../../../../../components/buttons/RadioButton'
import {IBaseComponentProps} from '../../../../../types/questionTypes/BaseQuestionProps'
import {choiceActions} from '../../store/choiceActions'
import {choiceSelectors} from '../../store/choiceSelectors'
import {ChoiceQuestionLayoutEnum} from 'src/survey-type-defs'
import {ChoiceSubtypeEnum} from 'src/survey-type-defs'
import {ChoiceAnswerTextPosition} from './ChoiceAnswerTextPosition'
import {ChoiceVerticalLayout} from './ChoiceVerticalLayout'
import {getLayoutLabel} from '../../util/choiceUtil'
import {QuestionSettingSection} from '../../../../../components/questionSettingSection/QuestionSettingSection'

export const ChoiceLayout: React.FC<IBaseComponentProps> = ({questionId}) => {
  const questionLayoutType = choiceSelectors.useQuestionLayout(questionId)
  const updateQuestionLayoutType = choiceActions.useUpdateQuestionLayout()
  const handleUpdateQuestionLayout = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    const layoutType = +event.currentTarget.value as ChoiceQuestionLayoutEnum
    updateQuestionLayoutType(questionId, {
      layoutType: layoutType,
    })
  }
  const subtype = choiceSelectors.useQuestionSubType(questionId)
  const canDisplayLayout =
    subtype === ChoiceSubtypeEnum.RADIO ||
    subtype === ChoiceSubtypeEnum.CHECKBOX
  const filteredChoiceLayoutEnum = Object.keys(ChoiceQuestionLayoutEnum).filter(
    key => !isNaN(Number(key)),
  )
  return (
    <>
      {canDisplayLayout && (
        <>
          <QuestionSettingSection title="Question Layout">
            {Object.keys(filteredChoiceLayoutEnum).map(layoutKey => {
              const layoutValue = +filteredChoiceLayoutEnum[
                layoutKey as keyof typeof filteredChoiceLayoutEnum
              ] as ChoiceQuestionLayoutEnum
              return (
                <RadioButton
                  key={layoutKey}
                  checked={questionLayoutType === layoutValue}
                  value={layoutValue}
                  onClick={handleUpdateQuestionLayout}
                >
                  {getLayoutLabel(layoutValue)}
                </RadioButton>
              )
            })}
          </QuestionSettingSection>
          <ChoiceVerticalLayout
            questionId={questionId}
            choiceQuestionLayoutType={questionLayoutType}
          />
          <ChoiceAnswerTextPosition
            questionId={questionId}
            choiceQuestionLayoutType={questionLayoutType}
          />
        </>
      )}
    </>
  )
}
