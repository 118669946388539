import React from 'react'
import {TextInput} from '../../../../components/input/TextInput'
import {QuestionSettingSection} from '../../../../components/questionSettingSection/QuestionSettingSection'
import {textActions} from '../store/textActions'
import {textSelectors} from '../store/TextSelectors'
import {IBaseRowSettingProps} from '../types/IBaserRowSettingProps'

const MAX_LENGTH = 1024

export const TextPrefixSuffix: React.FC<IBaseRowSettingProps> = ({
  questionId,
  rowId,
}) => {
  const prefixSuffix = textSelectors.usePrefixSuffix(questionId, rowId)
  const changePrefix = textActions.useChangePrefix()
  const changeSuffix = textActions.useChangeSuffix()

  const handleChangePrefix = (prefix: string): void => {
    changePrefix(questionId, {
      prefix: prefix,
      rowId,
    })
  }

  const handleChangeSuffix = (suffix: string): void => {
    changeSuffix(questionId, {suffix: suffix, rowId})
  }

  return (
    <QuestionSettingSection title="Add Prefix/Suffix To Text Box">
      <div>
        <label htmlFor="text-prefix">Prefix</label>
        <TextInput
          id="text-prefix"
          maxLength={MAX_LENGTH}
          placeholder="TextBox Prefix"
          value={prefixSuffix?.prefix || ''}
          onChange={handleChangePrefix}
        />
      </div>
      <div>
        <label htmlFor="text-suffix">Suffix</label>
        <TextInput
          id="text-suffix"
          maxLength={MAX_LENGTH}
          placeholder="TextBox Suffix"
          value={prefixSuffix?.suffix || ''}
          onChange={handleChangeSuffix}
        />
      </div>
    </QuestionSettingSection>
  )
}
