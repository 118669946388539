import {
  dynamicCommentSchema,
  IDynamicComment,
} from '../dynamicComments/IDynamicComment'
import {IUid, uidSchema} from '../../uid/IUid'
import {QuestionTypeEnum} from '../questionTypes/QuestionTypeEnum'
import {Static, Type} from '@sinclair/typebox'

export const questionSchema = Type.Object({
  id: uidSchema,
  blockId: uidSchema,
  text: Type.String(),
  code: Type.String(),
  orderNum: Type.String(),
  type: Type.Enum(QuestionTypeEnum),
  deleted: Type.Boolean(),
  dynamicCommentLabels: Type.Optional(dynamicCommentSchema),
})

export type IQuestion = Static<typeof questionSchema>
