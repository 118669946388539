import React from 'react'
import {IUid, VideoTypeEnum} from 'src/survey-type-defs'
import {VideoAlign} from './VideoAlign'
import {VideoAutoplay} from './VideoAutoplay'
import {VideoLoop} from './VideoLoop'
import {VideoMuted} from './VideoMuted'
import {VideoShowControls} from './VideoShowControls'
import {VideoVimeoUrl} from './VideoVimeoUrl'
import {VideoWidth} from './VideoWidth'
import {VideoYoutubeUrl} from './VideoYoutubeUrl'

interface IProps {
  questionId: IUid
  videoType: VideoTypeEnum
}
export const VideoControls: React.FC<IProps> = ({questionId, videoType}) => {
  if (videoType === VideoTypeEnum.NONE) {
    return null
  }
  return (
    <>
      <VideoVimeoUrl questionId={questionId} videoType={videoType} />
      <VideoYoutubeUrl questionId={questionId} videoType={videoType} />
      <VideoAlign questionId={questionId} />
      <VideoAutoplay questionId={questionId} />
      <VideoShowControls questionId={questionId} videoType={videoType} />
      <VideoLoop questionId={questionId} />
      <VideoMuted questionId={questionId} />
      <VideoWidth questionId={questionId} />
    </>
  )
}
