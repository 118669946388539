import {StateCreator} from 'zustand'
import {IBlock} from 'src/survey-type-defs'
import {IQuestion} from 'src/survey-type-defs'
import {IBlockOrder} from 'src/survey-type-defs'
import {IBlocksMap} from './types/IBlocksMap'
import {IUid} from 'src/survey-type-defs'
import {ICreateBlockPatch} from './useBlockDispatchHook'
import {loggerUtil} from '../../test/utils/loggerUtil'
import {IBlocksPatch} from './useBlocksDispatchHook'

export interface IBlocksStore {
  blocks: IBlocksMap
  blockOrder: IBlockOrder[]
  blocksActions: {
    addQuestion: (newQuestion: IQuestion) => void
    updateQuestionOrderNumInBlock: (
      blockId: string,
      questionId: string,
      questionOrderNum: string,
    ) => void
    applyUpdate: <P>(
      blockId: IUid,
      payload: P,
      action: ICreateBlockPatch<P>,
    ) => [IBlock, Partial<IBlock>]
    applyServerUpdates: (blockId: string, patch: Partial<IBlock>) => void
  }
  updateBlockStore: (patch: IBlocksPatch) => IBlocksPatch
}

export const createBlocksSlice: StateCreator<IBlocksStore> = (set, get) => ({
  blocks: {},
  blockOrder: [],
  blocksActions: {
    addQuestion: (newQuestion: IQuestion): void =>
      set(state => {
        const {
          blockId,
          id: questionId,
          orderNum: questionOrderNum,
        } = newQuestion
        const block = state.blocks[blockId]

        const newQuestionOrder = {
          id: questionId,
          orderNum: questionOrderNum,
        }
        const updatedQuestionOrder = [...block.questionOrder, newQuestionOrder]
        updatedQuestionOrder.sort(orderNumSorter)

        const updatedBlock: IBlock = {
          ...block,
          questionOrder: updatedQuestionOrder,
        }
        return {
          blocks: {...state.blocks, [block.id]: updatedBlock},
        }
      }),
    updateQuestionOrderNumInBlock: (
      blockId: string,
      questionId: string,
      questionOrderNum: string,
    ): void => {
      const block = get().blocks[blockId]
      const updatedQuestionOrder = block.questionOrder.map(o => {
        if (o.id === questionId) {
          return {...o, orderNum: questionOrderNum}
        }
        return o
      })
      updatedQuestionOrder.sort(orderNumSorter)

      const updatedBlock: IBlock = {
        ...block,
        questionOrder: updatedQuestionOrder,
      }

      set(state => ({
        blocks: {...state.blocks, [block.id]: updatedBlock},
      }))
    },
    applyUpdate: <P>(
      blockId: IUid,
      payload: P,
      action: ICreateBlockPatch<P>,
    ): [IBlock, Partial<IBlock>] => {
      const originalBlocks = get().blocks

      const originalBlock = originalBlocks[blockId]
      if (!originalBlock) throw new Error(`Block not found for id: ${blockId}`)

      const blockPatch = action({...originalBlock}, payload)
      const updatedBlocks = {
        ...originalBlocks,
        [blockId]: {...originalBlock, ...blockPatch},
      }

      set({blocks: updatedBlocks})

      return [originalBlock, blockPatch]
    },
    applyServerUpdates: (blockId: string, update: Partial<IBlock>): void => {
      const originalBlock = get().blocks[blockId]
      if (!originalBlock) {
        console.log(`block ${blockId} not found`)
        return
      }
      const updatedBlocks = {
        ...get().blocks,
        [blockId]: {...originalBlock, ...update},
      }
      loggerUtil.logOnlyDev('updating patch ', originalBlock, update)
      set({
        blocks: updatedBlocks,
      })
    },
  },
  updateBlockStore: (patch: IBlocksPatch): IBlocksPatch => {
    //This would take care of both add and delete block
    // step - 1: we are removing the order element of all the blocks that are part of the 'patch' (pristineBlockOrderElements)
    // step - 2: we are adding the blockOrder patch and creating the new updatedBlockOrderElements
    //  (in case of add block, a new element  will be added and in case of delete, empty array is sent from the action so there will not be any impact)
    // step - 3: we are sorting the elements
    const pristineBlockOrderElements = get().blockOrder.filter(
      o => !patch.blocksPatch[o.id],
    )
    const updatedBlockOrderElements = [
      ...pristineBlockOrderElements,
      ...patch.blockOrderPatch,
    ]

    updatedBlockOrderElements.sort(orderNumSorter)
    //TODO: here we are completely replacing the block order array - what if the client has added a new block and receives a delete block request from server
    set({
      blockOrder: updatedBlockOrderElements,
      blocks: {
        ...get().blocks,
        ...patch.blocksPatch,
      },
    })
    return patch
  },
})
interface IOrderNumType {
  orderNum: string
}

const orderNumSorter = (a: IOrderNumType, z: IOrderNumType): number => {
  return a.orderNum.localeCompare(z.orderNum)
}
