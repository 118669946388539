import {
  ChoiceAnswerTextPositionEnum,
  ChoiceQuestionLayoutEnum,
  ChoiceSubtypeEnum,
  ChoiceVerticalColumnDisplayEnum,
} from 'src/survey-type-defs'

export const getSubtypeLabel = (type: ChoiceSubtypeEnum): string => {
  switch (type) {
    case ChoiceSubtypeEnum.RADIO:
      return 'Radio'
    case ChoiceSubtypeEnum.CHECKBOX:
      return 'Checkbox'
    case ChoiceSubtypeEnum.DROPDOWN:
      return 'Dropdown'
    case ChoiceSubtypeEnum.SELECT_LIST:
      return 'Select List'
  }
}

export const getTextPositionLabel = (
  type: ChoiceAnswerTextPositionEnum,
): string => {
  switch (type) {
    case ChoiceAnswerTextPositionEnum.RIGHT_DEFAULT:
      return 'Right(Default)'
    case ChoiceAnswerTextPositionEnum.ABOVE:
      return 'Above'
    case ChoiceAnswerTextPositionEnum.BELOW:
      return 'Below'
  }
}

export const getLayoutLabel = (type: ChoiceQuestionLayoutEnum): string => {
  switch (type) {
    case ChoiceQuestionLayoutEnum.HORIZONTAL:
      return 'Horizontal'
    case ChoiceQuestionLayoutEnum.VERTICAL:
      return 'Vertical'
  }
}

export const getVerticalColumnDisplayLabel = (
  type: ChoiceVerticalColumnDisplayEnum,
): string => {
  switch (type) {
    case ChoiceVerticalColumnDisplayEnum.ACROSS:
      return 'Across'
    case ChoiceVerticalColumnDisplayEnum.TOP_DOWN:
      return 'Top Down'
  }
}
